import React, { useState } from "react";
import "./Login.css";
import Logo from "../../assets/appImages/logo.svg";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { PasswordRules } from "./PasswordRules";
import { UseResetPasswordForm } from "./requestForm/UseResetPasswordForm";
import { FormError } from "../common/Input";
import { IconPassword } from "../shared/Icons";
import { MdNumbers } from "react-icons/md";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
    passwordHandleChange,
  } = UseResetPasswordForm();
  const props = {
    haveMinCharacter,
    haveMinNumber,
    haveMinLowercase,
    haveMinSpecialCharacter,
    haveMinUppercase,
  };
  return (
    <div className="container-auth-login-component">
      <div className="form-login-container-auth w-100">
        <div className="content-auth-component mt-4">
          <div className="container-logo-app-login pb-4 mx-auto text-center">
            <img src={Logo} alt="Logo" className="img-logo-app-login" />
          </div>
          <h1 className="title-login-page pb-5">
            Réinitialisation mot de passe
          </h1>
          <div className="container-form-login">
            <form id="auth-form" onSubmit={onSubmit}>
              <div className="row auth-form-row">
                <div className="col-md-12 auth-input-col mb-3">
                  <label className="form-label form-label-auth-login">
                    Code
                  </label>
                  <div className="form-login-content mb-4 position-relative">
                    <span className="content-icon-input-group-login">
                      <MdNumbers color="#c4c4c4" />
                    </span>
                    <input
                      type="text"
                      className="form-control form-control-login"
                      id="code-or-username"
                      aria-label="Username"
                      placeholder="Code"
                      {...register("code")}
                    />
                    <FormError error={errors?.code?.message} />
                  </div>
                </div>
                <div className="col-md-12 auth-input-col mb-2">
                  <label className="form-label form-label-auth-login">
                    Nouveau mot de passe
                  </label>
                  <div className="form-login-content mb-4 position-relative">
                    <span className="content-icon-input-group-login">
                      <IconPassword />
                    </span>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control form-control-login"
                      id="new_password"
                      placeholder="Mot de passe"
                      aria-label="Password"
                      aria-placeholder="***********"
                      name="new_password"
                      onChange={passwordHandleChange}
                    />
                    <span
                      className="show-hide-password"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                    </span>
                    <FormError error={errors?.new_password?.message} />
                  </div>
                </div>
                <PasswordRules {...props} />
                <div className="col-md-12 auth-input-col mb-2">
                  <div className="auth-form-group position-relative">
                    <label className="form-label form-label-auth-login">
                      Confirmer mot de passe
                    </label>
                    <div className="form-login-content mb-4 position-relative">
                      <span className="content-icon-input-group-login">
                        <IconPassword />
                      </span>
                      <input
                        type="password"
                        className="form-control form-control-login"
                        id="new_password_confirm"
                        placeholder="Mot de passe"
                        aria-label="Password"
                        aria-placeholder="***********"
                        {...register("new_password_confirm")}
                      />
                      <FormError
                        error={errors?.new_password_confirm?.message}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 content-btn-submit-login pt-4">
                  <button
                    type="submit"
                    className="btn btn-theme w-100"
                    disabled={isLoading}
                  >
                    {!isLoading ? (
                      `Envoyer`
                    ) : (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>Envoie...</span>
                      </>
                    )}
                  </button>
                </div>
                <div className="col-md-12 mt-3 flex-r">
                  <span className="mt-2">
                    <NavLink
                      className="nav-link forget-password-link"
                      to="/"
                      data-tooltip-content="Gestionnaires"
                    >
                      Se connecter
                    </NavLink>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
