import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Voucher, VoucherFormData } from "./vouchers.type";

export const VoucherApi = createApi({
  reducerPath: "voucherApi",
  tagTypes: [
    "voucher",
    "voucherList",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListVoucher: builder.query<PaginationResults<Voucher>, TypeQuery>({
      providesTags: ["voucherList"],
      query: (query) => QueryUrl("vouchers/", query),
    }),
    addOrEditVoucher: builder.mutation<Voucher, VoucherFormData>({
      invalidatesTags: ["voucherList"],
      query: ({ slug, ...data }) => {
        if (slug) {
          return {
            url: `vouchers/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `vouchers/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteVoucher: builder.mutation<Voucher, string>({
      query: (slug) => ({
        url: `vouchers/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["voucherList"],
    }),
  }),
});

export const {useAddOrEditVoucherMutation,useDeleteVoucherMutation,useGetListVoucherQuery} = VoucherApi