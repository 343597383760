import React, { useState } from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import "./CommandAdmin.css";
import CommandsTable from "../Tables/CommandsTable";
import { IconSearch } from "../../shared/Icons";

function CommandAdmin() {
  const [query,setQuery] = useState("")
  return (
    <WrapperComponent>
      <div className="container-title-dash d-flex align-items-center gap-4">
        <div className="custom-sm-top">
          <h2 className="custom-sm-top-title mb-0">Commandes</h2>
        </div>
      </div>
      <div className="search-user-box position-relative mt-4">
        <span className="icon-span-search-svg">
          <IconSearch />
        </span>
        <input
          type="search"
          name="search_command"
          id="__search_command"
          onChange={(e) => setQuery(e?.target?.value)}
          placeholder="Recherchez..."
        />
      </div>

      <div className="custom-table mt-5">
        <CommandsTable q={query} />
      </div>
    </WrapperComponent>
  );
}

export default CommandAdmin;
