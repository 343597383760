/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {
  BtnAnnuler,
  BtnCloseModalV2,
  BtnSubmit,
} from "../../../common/Button";
import { FormError, Input } from "../../../common/Input";
import PhoneInput from "react-phone-input-2";
import UseEditUserInfoForm from "../form/UseEditUserInfoForm";
import { closeModal } from "../../../../utils/Utils";
import GoogleInput from "../../../common/GoogleInput";

function ModifierInfosPersoModal() {
  const {
    register,
    errors,
    isLoading,
    onSubmit,
    phone,
    handleChangePhone,
    address,
    onChangeAddress,
  } = UseEditUserInfoForm("changeInfosPerso");
  return (
    <div className="modal-dialog modal-md">
      <div className="modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
            Modifier
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModalV2 onClick={() => closeModal("changeInfosPerso")} />
          </div>
        </div>
        <div className="modal-body px-4">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Prénom"
                    id="prenom"
                    placeholder="Prénom"
                    {...register("prenom")}
                    error={errors?.prenom}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Nom"
                    id="nom"
                    placeholder="Nom"
                    {...register("nom")}
                    error={errors?.nom}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    type="email"
                    label="Email"
                    id="email"
                    placeholder="Email"
                    {...register("email")}
                    error={errors?.email}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <div className="mb-3 text-start">
                    <label
                      htmlFor="phone"
                      className="form-label form-label-modal-custom"
                      aria-labelledby="phone"
                    >
                      Numéro de téléphone
                      <span className="text-danger" style={{ fontSize: 15 }}>
                        *
                      </span>
                    </label>
                    <PhoneInput
                      defaultMask={".. ... .. .."}
                      containerClass="form-control form-control-modal-custom mb-3 p-0"
                      inputClass="form-control form-control-modal-custom-input-tel custom-padding"
                      country={"sn"}
                      placeholder=""
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      countryCodeEditable={false}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      onChange={(phone, country: any) => {
                        handleChangePhone(phone, country);
                      }}
                      inputStyle={{
                        width: "100%",
                        paddingBottom: "10px",
                        borderRadius: "50px",
                        paddingLeft: "40px",
                        height: "40px",
                        border: "none",
                      }}
                    />
                    <FormError error={errors?.phone?.message} />
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="mb-3 text-start">
                    <label
                      htmlFor="addree"
                      className="form-label form-label-modal-custom"
                      aria-labelledby="addree"
                    >
                      Adresse
                      <span className="text-danger" style={{ fontSize: 24 }}>
                        *
                      </span>
                    </label>
                    <GoogleInput
                      value={address}
                      onChange={onChangeAddress}
                      className={`form-control form-control-modal-custom`}
                    />
                    <FormError error={errors?.adress?.message} />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="mb-3 flex-r gap-3">
                    <BtnAnnuler
                      label={"Annuler"}
                      isLoading={isLoading}
                      onClick={() => closeModal("changeInfosPerso")}
                    />
                    <BtnSubmit label="Modifier" isLoading={isLoading} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifierInfosPersoModal;
