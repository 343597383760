import React from "react";
import { MdOutlineAddBox } from "react-icons/md";
import ListAdministrateurTable from "../Tables/ListAdministrateurTable";
import AddOrUpdateAdminModal from "../Modals/AddOrUpdateAdminModal";
import { showModal } from "../../../../utils/Utils";

function Administrateur() {
  return (
    <>
      <div className="container-title-dash d-flex align-items-center gap-4">
        <div className="custom-sm-top justify-content-end">
          <button
            className="btn custom-add-item-btn"
            onClick={() => showModal("addOrUpdateAdmin")}
          >
            <span className="add-icon pe-2">
              <MdOutlineAddBox />
            </span>
            <span>Ajouter un administrateur</span>
          </button>
        </div>
      </div>

      <div className="custom-table mt-5">
        <ListAdministrateurTable />
      </div>

      <AddOrUpdateAdminModal modalId="addOrUpdateAdmin" />
    </>
  );
}

export default Administrateur;
