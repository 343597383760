/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { UserFormData } from "../../../../utils/api/user/user.type";
import { useAppSelector } from "../../../../redux/hook";
import {
  useAddOrEditUserMutation,
} from "../../../../utils/api/user/user.api";
import { Color } from "../../../../utils/theme";
import {
  cleannerError,
  closeModal,
  validatePhone,
} from "../../../../utils/Utils";
import { Data } from "../../../common/GoogleInput";

function UseEditUserInfoForm(modalId: string) {
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string>();
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Le nom"),
    prenom: yup.string().required().label("Le prénom"),
    phone: validatePhone(yup, code).required(),
    email: yup.string().email().required().label("L'email"),
    adress: yup.string().required().label("L'adresse").nullable(),
  });
  const { user } = useAppSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [addOrUpdateUser, { isLoading, isSuccess, error, isError }] =
    useAddOrEditUserMutation();
  const [address, setAddress] = useState("");

  const onChangeAddress = (val: Data) => {
    setAddress(val?.rue);
    setValue(`adress`, val?.rue);
    setValue(`latitude`, parseFloat(val?.latitude).toFixed(6));
    setValue(`longitude`, parseFloat(val?.longitude).toFixed(6));
  };

  const handleChangePhone = (item: any, country: any) => {
    setPhone(item);
    setCode(country?.countryCode);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("phone", item);
    } else {
      setValue("phone", "");
    }
  };

  useEffect(() => {
    if (isSuccess) {
    //   if (data?.id) {
    //     dispatch(onSetUser(data));
    //   }

      Swal.fire({
        icon: "success",
        title: "Votre profil a été modifié avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        closeModal(modalId);
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet email exite déjà!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Erreur de statut ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (user?.id) {
      // console.log("user", user);
      let fields: (keyof UserFormData)[] = [
        "nom",
        "prenom",
        "phone",
        "email",
        "adress",
      ];

      if (user?.phone) {
        setPhone(user.phone);
      }

      if (user?.adress) {
        setAddress(user.adress);
      }

      for (let field of fields) {
        register(field);

        setValue(field, user[field]);
      }
    }
  }, [user]);

  const onSubmit = async (data: UserFormData) => {
    const fd = new FormData();

    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    await addOrUpdateUser({
      slug: user?.slug,
      data: fd,
    });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangePhone,
    onChangeAddress,
    Controller,
    control,
    phone,
    address
  };
}

export default UseEditUserInfoForm;
