import React, { useState } from "react";
import "./Login.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import Logo from "../../assets/appImages/logo.svg";
import UseLoginForm from "./requestForm/UseLoginForm";
import { FormError } from "../common/Input";
import { IconMail, IconPassword } from "../shared/Icons";

const Login = () => {
  const { register, errors, isLoading, onSubmit } = UseLoginForm();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="container-auth-login-component">
      <div className="form-login-container-auth w-100">
        <div className="content-auth-component mt-4">
          <div className="container-logo-app-login pb-4 mx-auto text-center">
            <img src={Logo} alt="Logo" className="img-logo-app-login" />
          </div>
          <h1 className="title-login-page pb-5">Connectez-vous</h1>
          <div className="container-form-login">
            <form onSubmit={onSubmit}>
              <div className="form-login-content mb-4 position-relative">
                <span className="content-icon-input-group-login">
                  <IconMail/>
                </span>
                <input
                  type="email"
                  className="form-control form-control-login"
                  placeholder="Identifiant"
                  {...register("email")}
                />
                <FormError error={errors?.email?.message} />
              </div>
              <div className="form-login-content mb-4 position-relative">
                <span className="content-icon-input-group-login">
                  <IconPassword />
                </span>
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control form-control-login"
                  placeholder="Mot de passe"
                  {...register("password")}
                />
                <span
                  className="show-hide-password"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                </span>
                <FormError error={errors?.password?.message} />
              </div>
              <div className="container-login-form-info mb-4">
                <div className="content-forget-password">
                  <Link
                    to={"/mot-de-passe-oublie"}
                    className="forget-password-link"
                  >
                    Mot de passe oublié ?
                  </Link>
                </div>
              </div>
              <div className="content-btn-submit-login pt-4">
                <button
                  type="submit"
                  className="btn btn-theme w-100"
                  disabled={isLoading}
                >
                  {!isLoading ? (
                    `SE CONNECTER`
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>Connexion...</span>
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
