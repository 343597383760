import React from "react";
import ModifierInfosPersoModal from "../Modals/ModifierInfosPersoModal";
import { getName, useUser } from "../../../../utils/Utils";

function InfosPersonnellesTab() {
  const user = useUser()
  return (
    <div className="masoul-dash-admin-page-content-container mb-3">
      <div className="masoul-dash-admin-page-container">
        <div className="page-content-container mt-4">
          <div className="parent-table-container">
            <div>
              <div className="masoul-dash-admin-section-edit-profil-container">
                <div className="masoul-dash-admin-section-edit-profil-item">
                  <div className="masoul-dash-admin-section-edit-profil-prenom">
                    <div className="masoul-dash-admin-section-edit-profil-prenom-item-deux">
                      Nom
                    </div>
                  </div>
                  <div className="masoul-dash-admin-section-edit-profil-btn-container">
                    <div className="masoul-dash-admin-section-edit-profil-btn-item-deux">
                      {getName(user)}
                    </div>
                  </div>
                </div>
                <hr className="masoul-custom-form-divider" />
                <div className="masoul-dash-admin-section-edit-profil-item">
                  <div className="masoul-dash-admin-section-edit-profil-prenom">
                    <div className="masoul-dash-admin-section-edit-profil-prenom-item-deux">
                      Adresse
                    </div>
                  </div>
                  <div className="masoul-dash-admin-section-edit-profil-btn-container">
                    <div className="masoul-dash-admin-section-edit-profil-btn-item-deux">
                      {user?.adress}
                    </div>
                  </div>
                </div>
                <hr className="masoul-custom-form-divider" />
                <div className="masoul-dash-admin-section-edit-profil-item">
                  <div className="masoul-dash-admin-section-edit-profil-prenom">
                    <div className="masoul-dash-admin-section-edit-profil-prenom-item-deux">
                      Mail
                    </div>
                  </div>
                  <div className="masoul-dash-admin-section-edit-profil-btn-container">
                    <div className="masoul-dash-admin-section-edit-profil-btn-item-deux">
                      {user?.email}
                    </div>
                  </div>
                </div>
                <hr className="masoul-custom-form-divider" />
                <div className="masoul-dash-admin-section-edit-profil-item">
                  <div className="masoul-dash-admin-section-edit-profil-prenom">
                    <div className="masoul-dash-admin-section-edit-profil-prenom-item-deux">
                      Téléphone
                    </div>
                  </div>
                  <div className="masoul-dash-admin-section-edit-profil-btn-container">
                    <div className="masoul-dash-admin-section-edit-profil-btn-item-deux">
                      {user?.phone}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="changeInfosPerso"
                aria-labelledby="changeInfosPersoLabel"
                aria-hidden="true"
              >
                <ModifierInfosPersoModal />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfosPersonnellesTab;
