import React, { useState } from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import { MdOutlineAddBox } from "react-icons/md";
import "./ListUsers.css";
import ListUsersTable from "../Tables/ListUsersTable";
import AddUserModal from "./modals/AddUserModal";
import { IconSearch } from "../../shared/Icons";

function ListUsers() {
  const [query, setQuery] = useState("");
  const [modalAddUser, setModalAddUser] = React.useState(false);
  function openModalAddUser() {
    setModalAddUser(true);
  }
  return (
    <WrapperComponent>
      <div className="container-title-dash d-flex align-items-center gap-4">
        <div className="custom-sm-top">
          <h2 className="custom-sm-top-title mb-0">Utilisateurs</h2>
          <button
            className="btn custom-add-item-btn"
            onClick={() => openModalAddUser()}
          >
            <span className="add-icon pe-2">
              <MdOutlineAddBox />
            </span>
            <span>Ajouter un utilisateur</span>
          </button>
          <AddUserModal
            modalAddUser={modalAddUser}
            setModalAddUser={setModalAddUser}
          />
        </div>
      </div>
      <div className="search-user-box position-relative mt-4">
        <span className="icon-span-search-svg">
          <IconSearch />
        </span>
        <input
          type="search"
          name="search_user"
          onChange={(e) => setQuery(e?.target?.value)}
          id="search_user"
          placeholder="Recherchez"
        />
      </div>

      <div className="custom-table mt-5">
        <ListUsersTable q={query} />
      </div>
    </WrapperComponent>
  );
}

export default ListUsers;
