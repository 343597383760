import React from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { IoLogOutOutline } from "react-icons/io5";
import { RiRefund2Fill } from "react-icons/ri";

const SidebarMobile = () => {
  return (
    <div>
      <div className="container-nav">
        <input type="checkbox" id="check" />
        <label htmlFor="check">
          <AiOutlineMenuUnfold />
          <i className="fas fa-times" id="cancel"></i>
        </label>
        <div className="sidebar" id="scrollbar-nav-mobile">
          <div className="content-nav-mobile">
            <ul className="nav nav-items-custom flex-column">
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/dashboard"
                >
                  <span className="icon-container-nav-link-vertival">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.41375 1.75049H14.5854C17.5612 1.75049 19.2412 3.43836 19.25 6.41424V14.5867C19.25 17.5617 17.5612 19.2505 14.5854 19.2505H6.41375C3.43787 19.2505 1.75 17.5617 1.75 14.5867V6.41424C1.75 3.43836 3.43787 1.75049 6.41375 1.75049ZM10.5429 15.6279C10.9201 15.6279 11.2342 15.3479 11.2692 14.9717V6.05543C11.3042 5.78418 11.1738 5.51206 10.9376 5.36418C10.6917 5.21543 10.3942 5.21543 10.1588 5.36418C9.9217 5.51206 9.79132 5.78418 9.8167 6.05543V14.9717C9.86132 15.3479 10.1754 15.6279 10.5429 15.6279ZM14.5686 15.628C14.9361 15.628 15.2503 15.348 15.2949 14.9718V12.1018C15.3203 11.8209 15.1899 11.5593 14.9528 11.4105C14.7174 11.2618 14.4199 11.2618 14.1749 11.4105C13.9378 11.5593 13.8074 11.8209 13.8424 12.1018V14.9718C13.8774 15.348 14.1915 15.628 14.5686 15.628ZM7.19157 14.9718C7.15657 15.348 6.84244 15.628 6.46532 15.628C6.08907 15.628 5.77407 15.348 5.73994 14.9718V8.92552C5.71369 8.6534 5.84407 8.38302 6.08119 8.23427C6.31657 8.08552 6.61494 8.08552 6.85119 8.23427C7.08657 8.38302 7.21869 8.6534 7.19157 8.92552V14.9718Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span className="item-nav-menu drop-header">Dashboard</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/utilisateurs"
                >
                  <span className="icon-container-nav-link-vertival">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.3125 10.9845C10.7228 10.9845 12.6548 9.02742 12.6548 6.58599C12.6548 4.14455 10.7228 2.1875 8.3125 2.1875C5.90225 2.1875 3.9702 4.14455 3.9702 6.58599C3.9702 9.02742 5.90225 10.9845 8.3125 10.9845ZM8.3125 13.1383C4.77245 13.1383 1.75 13.7043 1.75 15.9656C1.75 18.2261 4.75405 18.8125 8.3125 18.8125C11.8517 18.8125 14.875 18.2465 14.875 15.9852C14.875 13.7238 11.8709 13.1383 8.3125 13.1383ZM17.4107 8.38938H18.4634C18.8967 8.38938 19.25 8.74764 19.25 9.18704C19.25 9.62644 18.8967 9.98471 18.4634 9.98471H17.4107V11.0148C17.4107 11.4542 17.0583 11.8125 16.6241 11.8125C16.1908 11.8125 15.8375 11.4542 15.8375 11.0148V9.98471H14.7866C14.3524 9.98471 14 9.62644 14 9.18704C14 8.74764 14.3524 8.38938 14.7866 8.38938H15.8375V7.36016C15.8375 6.92077 16.1908 6.5625 16.6241 6.5625C17.0583 6.5625 17.4107 6.92077 17.4107 7.36016V8.38938Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span className="item-nav-menu drop-header">
                    Utilisateurs
                  </span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/vendeurs"
                >
                  <span className="icon-container-nav-link-vertival">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.3125 10.9845C10.7228 10.9845 12.6548 9.02742 12.6548 6.58599C12.6548 4.14455 10.7228 2.1875 8.3125 2.1875C5.90225 2.1875 3.9702 4.14455 3.9702 6.58599C3.9702 9.02742 5.90225 10.9845 8.3125 10.9845ZM8.3125 13.1383C4.77245 13.1383 1.75 13.7043 1.75 15.9656C1.75 18.2261 4.75405 18.8125 8.3125 18.8125C11.8517 18.8125 14.875 18.2465 14.875 15.9852C14.875 13.7238 11.8709 13.1383 8.3125 13.1383ZM17.4107 8.38938H18.4634C18.8967 8.38938 19.25 8.74764 19.25 9.18704C19.25 9.62644 18.8967 9.98471 18.4634 9.98471H17.4107V11.0148C17.4107 11.4542 17.0583 11.8125 16.6241 11.8125C16.1908 11.8125 15.8375 11.4542 15.8375 11.0148V9.98471H14.7866C14.3524 9.98471 14 9.62644 14 9.18704C14 8.74764 14.3524 8.38938 14.7866 8.38938H15.8375V7.36016C15.8375 6.92077 16.1908 6.5625 16.6241 6.5625C17.0583 6.5625 17.4107 6.92077 17.4107 7.36016V8.38938Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span className="item-nav-menu drop-header">Vendeurs</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/commandes"
                >
                  <span className="icon-container-nav-link-vertival">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.7562 4.09675H12.9439C15.4111 4.09675 17.4135 6.15888 17.4135 8.68842V13.7475C17.4135 16.277 15.4111 18.33 12.9439 18.33H4.46958C2.00237 18.33 0 16.277 0 13.7475V8.68842C0 6.15888 2.00237 4.09675 4.46958 4.09675H4.65731C4.67518 2.99695 5.09533 1.97047 5.85515 1.20061C6.62392 0.42159 7.60723 0.027495 8.71569 0C10.9326 0 12.7294 1.833 12.7562 4.09675ZM6.79325 2.18115C6.29266 2.69439 6.01554 3.3726 5.99766 4.09663H11.4148C11.388 2.59357 10.1901 1.37463 8.71517 1.37463C8.02685 1.37463 7.31172 1.65874 6.79325 2.18115ZM12.103 7.62496C12.4785 7.62496 12.7735 7.31335 12.7735 6.93759V5.87445C12.7735 5.49868 12.4785 5.18707 12.103 5.18707C11.7365 5.18707 11.4326 5.49868 11.4326 5.87445V6.93759C11.4326 7.31335 11.7365 7.62496 12.103 7.62496ZM5.89888 6.9338C5.89888 7.30957 5.60389 7.62118 5.22844 7.62118C4.86193 7.62118 4.558 7.30957 4.558 6.9338V5.87066C4.558 5.4949 4.86193 5.18329 5.22844 5.18329C5.60389 5.18329 5.89888 5.4949 5.89888 5.87066V6.9338Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span className="item-nav-menu drop-header">Commandes</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/réclamations"
                >
                  <span className="icon-container-nav-link-vertival">
                    <RiRefund2Fill />
                  </span>
                  <span className="item-nav-menu drop-header">Réclamations</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/offres"
                >
                  <span className="icon-container-nav-link-vertival">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.50806 2.49219H7.1906C8.30965 2.49219 9.20648 3.40489 9.20648 4.52473V7.23028C9.20648 8.35726 8.30965 9.26203 7.1906 9.26203H4.50806C3.39695 9.26203 2.49219 8.35726 2.49219 7.23028V4.52473C2.49219 3.40489 3.39695 2.49219 4.50806 2.49219ZM4.50806 11.5951H7.1906C8.30965 11.5951 9.20648 12.5007 9.20648 13.6277V16.3332C9.20648 17.4523 8.30965 18.365 7.1906 18.365H4.50806C3.39695 18.365 2.49219 17.4523 2.49219 16.3332V13.6277C2.49219 12.5007 3.39695 11.5951 4.50806 11.5951ZM16.3493 2.49219H13.6668C12.5477 2.49219 11.6509 3.40489 11.6509 4.52473V7.23028C11.6509 8.35726 12.5477 9.26203 13.6668 9.26203H16.3493C17.4604 9.26203 18.3652 8.35726 18.3652 7.23028V4.52473C18.3652 3.40489 17.4604 2.49219 16.3493 2.49219ZM13.6668 11.5951H16.3493C17.4604 11.5951 18.3652 12.5007 18.3652 13.6277V16.3332C18.3652 17.4523 17.4604 18.365 16.3493 18.365H13.6668C12.5477 18.365 11.6509 17.4523 11.6509 16.3332V13.6277C11.6509 12.5007 12.5477 11.5951 13.6668 11.5951Z"
                        fill="#FCFCFC"
                      />
                    </svg>
                  </span>
                  <span className="item-nav-menu drop-header">Offres</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/projets"
                >
                  <span className="icon-container-nav-link-vertival">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.81003 14.3413H12.5229C12.8781 14.3413 13.1726 14.0438 13.1726 13.685C13.1726 13.3263 12.8781 13.0375 12.5229 13.0375H7.81003C7.45483 13.0375 7.16027 13.3263 7.16027 13.685C7.16027 14.0438 7.45483 14.3413 7.81003 14.3413ZM10.7382 8.6625H7.81003C7.45483 8.6625 7.16027 8.96 7.16027 9.31875C7.16027 9.6775 7.45483 9.96625 7.81003 9.96625H10.7382C11.0934 9.96625 11.388 9.6775 11.388 9.31875C11.388 8.96 11.0934 8.6625 10.7382 8.6625ZM16.9208 7.89741C17.1244 7.89506 17.3462 7.8925 17.5476 7.8925C17.7642 7.8925 17.9375 8.0675 17.9375 8.28625V15.3212C17.9375 17.4913 16.1962 19.25 14.0476 19.25H7.15161C4.89913 19.25 3.0625 17.4037 3.0625 15.1287V5.69625C3.0625 3.52625 4.8125 1.75 6.96968 1.75H11.5959C11.8212 1.75 11.9944 1.93375 11.9944 2.1525V4.97C11.9944 6.57125 13.3026 7.88375 14.888 7.8925C15.2583 7.8925 15.5848 7.89527 15.8705 7.89769C16.0928 7.89957 16.2904 7.90125 16.4647 7.90125C16.588 7.90125 16.7479 7.89941 16.9208 7.89741ZM17.1597 6.62025C16.4476 6.62287 15.6081 6.62025 15.0043 6.61412C14.0461 6.61412 13.2569 5.817 13.2569 4.84925V2.54275C13.2569 2.16562 13.71 1.97837 13.969 2.2505C14.4378 2.74287 15.0821 3.41967 15.7235 4.09339C16.3632 4.76541 17 5.43436 17.4569 5.91412C17.7098 6.17925 17.5244 6.61937 17.1597 6.62025Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span className="item-nav-menu drop-header">Projets</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/vouchers"
                >
                  <span className="icon-container-nav-link-vertival">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="22"
                      viewBox="0 0 24 22"
                      fill="none"
                    >
                      <path
                        d="M20.5184 6.1875C19.977 6.7045 19.5447 6.875 18.7185 6.875C17.0662 6.875 15.8386 5.70281 15.8386 4.125C15.8386 3.33609 16.0172 2.92325 16.5586 2.40625L14.3987 0.34375L0.359375 13.75L2.51927 15.8125C3.06068 15.2955 3.49302 15.125 4.31918 15.125C5.9715 15.125 7.19905 16.2972 7.19905 17.875C7.19905 18.6639 7.02049 19.0767 6.47908 19.5937L8.63898 21.6562L22.6783 8.25L20.5184 6.1875Z"
                        fill="white"
                      />
                      <path
                        d="M9.35938 5.15625L10.7993 6.53125"
                        stroke="#521A2A"
                        strokeWidth="2.53333"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M16.1992 11.6875L17.6392 13.0625"
                        stroke="#521A2A"
                        strokeWidth="2.53333"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M11.5195 7.21875L12.9595 8.59375"
                        stroke="#521A2A"
                        strokeWidth="2.53333"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M14.0391 9.625L15.479 11"
                        stroke="#521A2A"
                        strokeWidth="2.53333"
                        strokeMiterlimit="10"
                      />
                    </svg>
                  </span>
                  <span className="item-nav-menu drop-header">Vouchers</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/parametres"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="19"
                    viewBox="0 0 17 19"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.8521 10.8825C16.165 11.0487 16.4065 11.3112 16.5764 11.5737C16.9072 12.1163 16.8804 12.7812 16.5585 13.3675L15.9325 14.4175C15.6017 14.9775 14.9847 15.3275 14.3498 15.3275C14.0368 15.3275 13.6881 15.24 13.4019 15.065C13.1694 14.9163 12.9012 14.8637 12.615 14.8637C11.7297 14.8637 10.9875 15.59 10.9607 16.4563C10.9607 17.4625 10.138 18.25 9.10969 18.25H7.89356C6.85627 18.25 6.0336 17.4625 6.0336 16.4563C6.01571 15.59 5.27352 14.8637 4.38825 14.8637C4.09316 14.8637 3.82489 14.9163 3.60134 15.065C3.31519 15.24 2.9575 15.3275 2.65347 15.3275C2.00964 15.3275 1.39263 14.9775 1.06177 14.4175L0.444767 13.3675C0.113909 12.7987 0.0960244 12.1163 0.426883 11.5737C0.569957 11.3112 0.838221 11.0487 1.14225 10.8825C1.39263 10.76 1.55359 10.5587 1.70561 10.3225C2.15271 9.57 1.88445 8.58125 1.12437 8.135C0.239098 7.63625 -0.0470497 6.525 0.462652 5.65875L1.06177 4.62625C1.58042 3.76 2.68924 3.45375 3.58345 3.96125C4.36142 4.38125 5.37188 4.10125 5.82793 3.3575C5.971 3.1125 6.05148 2.85 6.0336 2.5875C6.01571 2.24625 6.11408 1.9225 6.28398 1.66C6.61483 1.1175 7.21396 0.7675 7.86673 0.75H9.12757C9.78929 0.75 10.3884 1.1175 10.7193 1.66C10.8802 1.9225 10.9875 2.24625 10.9607 2.5875C10.9428 2.85 11.0233 3.1125 11.1664 3.3575C11.6224 4.10125 12.6329 4.38125 13.4198 3.96125C14.3051 3.45375 15.4228 3.76 15.9325 4.62625L16.5317 5.65875C17.0503 6.525 16.7641 7.63625 15.8699 8.135C15.1099 8.58125 14.8416 9.57 15.2976 10.3225C15.4407 10.5587 15.6017 10.76 15.8521 10.8825ZM5.971 9.50875C5.971 10.8825 7.10665 11.9763 8.51057 11.9763C9.91448 11.9763 11.0233 10.8825 11.0233 9.50875C11.0233 8.135 9.91448 7.0325 8.51057 7.0325C7.10665 7.0325 5.971 8.135 5.971 9.50875Z"
                      fill="white"
                    />
                  </svg>
                  <span className="item-nav-menu drop-header">Paramètres</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom pt-5">
                <button className="btn nav-link nav-link-vertival-custom-admin sidebar-logout-btn">
                  <IoLogOutOutline />
                  <span className="item-nav-menu drop-header">Déconnexion</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMobile;
