import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { ConfigPoint, ConfigPointFormData } from "./config.type";

export const ConfigApi = createApi({
  reducerPath: "configApi",
  tagTypes: ["config", "configList", "configPoint"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getConfigPoint: builder.query<ConfigPoint[], void>({
      providesTags: ["configPoint"],
      query: () => "config_points/",
    }),
    addOrEditConfigPoint: builder.mutation<ConfigPoint, ConfigPointFormData>({
      invalidatesTags: ["configPoint"],
      query: ({ slug, ...data }) => {
        if (slug) {
          return {
            url: `config_points/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `config_points/`,
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const { useAddOrEditConfigPointMutation, useGetConfigPointQuery } = ConfigApi;
