import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../../common/TableSkeleton";
import { CustomPagination } from "../../../common/CustomPagination";
import { useGetListAdminUserQuery } from "../../../../utils/api/user/user.api";
import { IUser } from "../../../../utils/api/user/user.type";
import { getAvatar, getName, showModal } from "../../../../utils/Utils";
import { MdModeEditOutline } from "react-icons/md";
import { BtnDelete } from "../../Tables/SellersAdminTable";
import AddOrUpdateAdminModal from "../Modals/AddOrUpdateAdminModal";

const EditBtn = ({ row }: { row: IUser }) => {
  return (
    <>
      <button
        className="btn btn-action-icon-edit with-tooltip"
        data-tooltip-content="Modifier"
        onClick={() => showModal(`editAdminModal${row?.slug}`)}
      >
        <MdModeEditOutline />
      </button>
      <AddOrUpdateAdminModal
        modalId={`editAdminModal${row?.slug}`}
        item={row}
      />
    </>
  );
};

function ListAdministrateurTable() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const {data = {results:[],count:0},isLoading,refetch} = useGetListAdminUserQuery({page,limit});

  useEffect(() => {
    if (!isLoading) {
      refetch()
    }
  }, [])

  const actionFormatter = (cell: string,row:IUser) => {
    return (
      <div className="table-actions-btn-container-commandes d-flex gap-3">
        {/* <div className="container-btn-action-icon-modal">
            <button className="btn masoul-btn-action-table">
                <FaTrash />
            </button>
          </div>
          <div className="container-btn-action-icon-modal">
            <button className="btn masoul-btn-action-table">
                <RiEditBoxFill />
            </button>
          </div> */}
        <div className="container-btn-action-icon-modal">
          <EditBtn row={row} />
        </div>
        <div className="container-btn-action-icon-modal">
          <BtnDelete slug={cell} />
        </div>
      </div>
    );
  };
  const nameFormatter = (cell: string,row:IUser) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <div className="content-img-profil-user-table">
          <img
            src={getAvatar(row?.avatar)}
            alt={cell}
            className="img-profil-user-table"
          />
        </div>
        <p className="name-profil-user-table mb-0">{getName(row)}</p>
      </div>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: `Prénom & Nom`,
      formatter: nameFormatter,
      // headerStyle: () => {
      //   return { width: "200px", whiteSpace: "normal" };
      // },
    },
    {
        dataField: "phone",
        text: "Téléphone",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: () => {
        return { width: "100px", whiteSpace: "normal" };
      },
    },
  ];

  

  return (
    <>
      {!!isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ListAdministrateurTable;