/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginFormData } from "../../../utils/api/auth/auth.type";
import { useAppDispatch } from "../../../redux/hook";
import { useLoginUserMutation } from "../../../utils/api/auth/auth.api";
import { onSetUserToken } from "../../../redux/slice/User.slice";
import { cleannerError } from "../../../utils/Utils";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";
import { isAdmin } from "../../../routes/routerUtils";

function UseLoginForm() {
  const validationSchema = yup.object().shape({
    email: yup.string().email().required().nullable().label("L'email"),
    password: yup.string().required().nullable().label("Le mot de passe"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    reset,
  } = useForm<LoginFormData>({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loginUser, { isLoading, isSuccess, error, isError, data }] =
    useLoginUserMutation();

  useEffect(() => {
    if (isSuccess) {
      if (data?.token) {
        console.log(data);
        if (isAdmin(data?.data)) {
          dispatch(onSetUserToken({ user: data?.data, token: data?.token }));
        } else {
          Swal.fire({
            icon: "error",
            iconColor: Color.success,
            confirmButtonColor: Color.success,
            title: "Connexion non authorisée!",
            timer: 2500,
          }).then(() => {
            reset();
          });
        }
      }
    }
    const err = error as any;

    if (isError) {
      // console.log("error", err);
      setError("email", {
        message: err?.data?.message
          ? err?.data?.message
          : err?.status < 500
          ? "Email ou mot de passe incorrect"
          : `Une erreur de statut ${err?.status} est survenue.`,
      });

      setTimeout(() => {
        cleannerError(errors, clearErrors);
      }, 3000);
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: LoginFormData) => {
    // console.log("data login", data);
    await loginUser(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
  };
}

export default UseLoginForm;
