import React from "react";
import { IUser } from "../../../../../utils/api/user/user.type";

function PersonalInformation({ item }: { item: IUser }) {
  return (
    <div className="custom_step__content">
      <div className="row user_infos__row btm_border">
        <div className="col-md-6 py-4 info__wrapper">
          <p className="m-0 sm_title">Nom</p>
        </div>
        <div className="col-md-6 py-4 info__wrapper">
          <p className="m-0 sm_value">{item?.nom}</p>
        </div>
      </div>
      <div className="row user_infos__row btm_border">
        <div className="col-md-6 py-4 info__wrapper">
          <p className="m-0 sm_title">Prénom</p>
        </div>
        <div className="col-md-6 py-4 info__wrapper">
          <p className="m-0 sm_value">{item?.prenom}</p>
        </div>
      </div>
      <div className="row user_infos__row btm_border">
        <div className="col-md-6 py-4 info__wrapper">
          <p className="m-0 sm_title">Téléphone</p>
        </div>
        <div className="col-md-6 py-4 info__wrapper">
          <p className="m-0 sm_value">{item?.phone}</p>
        </div>
      </div>
      <div className="row user_infos__row btm_border">
        <div className="col-md-6 py-4 info__wrapper">
          <p className="m-0 sm_title">Adresse mail</p>
        </div>
        <div className="col-md-6 py-4 info__wrapper">
          <p className="m-0 sm_value">{item?.email}</p>
        </div>
      </div>
    </div>
  );
}

export default PersonalInformation;
