import React from "react";
import "./Login.css";
import Logo from "../../assets/appImages/logo.svg";
import { NavLink } from "react-router-dom";
import { UseForgetPasswordForm } from "./requestForm/UseForgetPasswordForm";
import { FormError } from "../common/Input";
import { IconMail } from "../shared/Icons";

const ForgetPassword = () => {
  const { register, errors, isLoading, onSubmit } = UseForgetPasswordForm();
  return (
    <div className="container-auth-login-component">
      <div className="form-login-container-auth w-100">
        <div className="content-auth-component mt-4">
          <div className="container-logo-app-login pb-4 mx-auto text-center">
            <img src={Logo} alt="Logo" className="img-logo-app-login" />
          </div>
          <h1 className="title-login-page pb-5">
            Réinitialisation mot de passe
          </h1>
          <div className="container-form-login">
            <form id="auth-form" onSubmit={onSubmit}>
              <div className="row auth-form-row">
                <div className="col-md-12 auth-input-col mb-3">
                  <div className="form-login-content mb-4 position-relative">
                    <span className="content-icon-input-group-login">
                      <IconMail />
                    </span>
                    <input
                      type="email"
                      className="form-control form-control-login"
                      placeholder="Adresse email"
                      {...register("email")}
                    />
                    <FormError error={errors?.email?.message} />
                  </div>
                </div>

                <div className="col-md-12 content-btn-submit-login pt-4">
                  <button
                    type="submit"
                    className="btn btn-theme w-100"
                    disabled={isLoading}
                  >
                    {!isLoading ? (
                      `Envoyer`
                    ) : (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>Envoie...</span>
                      </>
                    )}
                  </button>
                </div>
                <div className="col-md-12 mt-3 text-right">
                  <span className="mt-2">
                    <NavLink
                      className="btn forget-password-link"
                      to="/"
                      data-tooltip-content="Gestionnaires"
                    >
                      Se connecter
                    </NavLink>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
