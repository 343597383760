import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../common/TableSkeleton";
import { NavLink } from "react-router-dom";
import "./Table.css";
import { CustomPagination } from "../../common/CustomPagination";
import { AiFillEye } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import { Switch } from "./ListUsersTable";
import { useGetProductListQuery } from "../../../utils/api/product/product.api";
import { IUser } from "../../../utils/api/user/user.type";
import { formatCurrency, getImage, getName } from "../../../utils/Utils";
import { Product } from "../../../utils/api/product/product.type";
import {
  UseDeleteProduct,
  UseToggleProduct,
} from "../ProductAdmin/form/UseCrudProductForm";

export function ToggleProduct({ item }: { item: Product }) {
  const onToggle = UseToggleProduct(item);
  return (
    <Switch
      active={item?.status === "published" ? true : false}
      onPress={() => onToggle()}
    />
  );
}

const BtnDeleteProduct = ({ slug }: { slug: string }) => {
  const onDelete = UseDeleteProduct(slug);
  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={() => onDelete()}
    >
      <FaTrash />
    </button>
  );
};

function ProductsTable({ q }: { q: string }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);

  const {
    data = { results: [], count: 0 },
    isLoading,
    refetch,
  } = useGetProductListQuery({ page, limit, q });

  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
  }, []);

  const actionFormatter = (cell: string, row: Product) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/offres/detail/${cell}`}
              className="btn btn-action-icon-see with-tooltip"
              data-tooltip-content="Voir"
              state={row}
            >
              <AiFillEye color="#158A91" />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/offres/modifier/${cell}`}
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              state={row}
            >
              <MdModeEditOutline color="##476DB6" />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <BtnDeleteProduct slug={cell} />
          </div>
        </div>
      </>
    );
  };
  const productNameFormatter = (cell: string, row: Product) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <div className="command_product_image__wrapper">
          <img
            src={getImage(
              row?.images?.length ? row?.images[0]?.picture : row?.couverture
            )}
            alt={cell}
            className="product_image"
          />
        </div>
        <p className="product_name mb-0">{cell}</p>
      </div>
    );
  };


  const columns = [
    {
      dataField: "nom",
      text: `Offre`,
      formatter: productNameFormatter,
      // headerStyle: () => {
      //   return { width: "240px", whiteSpace: "normal" };
      // },
    },
    {
      dataField: "categorie.nom",
      text: "Catégorie",
    },
    {
      dataField: "vendeur",
      text: "Vendeur",
      formatter: (cell: IUser) => getName(cell),
    },
    {
      dataField: "prix",
      text: "Prix",
      formatter: (cell: string) => formatCurrency(cell),
    },
    {
      dataField: "status",
      text: "Activer",
      formatter: (cell: string, row: Product) => <ToggleProduct item={row} />,
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: () => {
        return { width: "100px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            rowClasses="custom-table-row-class"
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ProductsTable;
