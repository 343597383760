import React, { ReactNode } from "react";


function WrapperComponent({children, customClass}: {children: ReactNode, customClass?: string}) {
    return (
        <>
            <div className={`dashbord-admin-component ${customClass ? customClass : null}`}>
                <div className="dash-admin-page-content-container mb-3">
                    <div className={`content-graph-admin ${customClass ? customClass : null}`}>
                        { children }
                    </div>
                </div>
            </div>
        </>
    )
}

export default WrapperComponent;