/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import TableSkeleton from "../../../common/TableSkeleton";
import { AlertInfo } from "../../../common/Alert";
import { CustomPagination } from "../../../common/CustomPagination";
import BootstrapTable from "react-bootstrap-table-next";
import { RiEdit2Fill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { useGetProjectListQuery } from "../../../../utils/api/project/project.api";
import { Project } from "../../../../utils/api/project/project.type";
import { formatCurrency, getImage } from "../../../../utils/Utils";
import { UseDeleteProject, UseToggleProject } from "../form/UseCrudProjectForm";
import { Switch } from "../../Tables/ListUsersTable";

export function ToggleProject({ item }: { item: Project }) {
  const onToggle = UseToggleProject(item);
  return <Switch active={item?.is_active} onPress={() => onToggle()} />;
}

const BtnDelete = ({ slug }) => {
  const onDelete = UseDeleteProject(slug);
  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
      onClick={onDelete}
    >
      <FaTrash />
    </button>
  );
};

function ProjectsAdminTable({ q }: { q: string }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const {
    data = { results: [], count: 0 },
    isLoading,
    refetch,
  } = useGetProjectListQuery({ page, limit, q });

  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
  }, []);
  const actionFormatter = (cell: string, row: any) => {
    return (
      <div className="table-actions-btn-container-commandes d-flex gap-3">
        <div className="container-btn-action-icon-modal">
          <NavLink
            to={`/admin/projets/details/${cell}`}
            className="btn btn-action-icon-see with-tooltip"
            data-tooltip-content="Voir"
            state={row}
          >
            <AiFillEye color="#158A91" />
          </NavLink>
        </div>
        <div className="container-btn-action-icon-modal">
          <NavLink
            className="btn btn-action-icon-edit with-tooltip"
            data-tooltip-content="Modifier"
            to={`/admin/projets/modifier/${cell}`}
            state={row}
          >
            <RiEdit2Fill color="#476DB6" />
          </NavLink>
        </div>
        <div className="container-btn-action-icon-modal">
          <BtnDelete slug={cell} />
        </div>
      </div>
    );
  };
  const nameFormatter = (cell: string, row: Project) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <div className="content-img-profil-user-table">
          <img
            src={getImage(row?.file)}
            alt={cell}
            className="img-project-table"
          />
        </div>
        <p className="nom-projet-admin-table mb-0">{cell}</p>
      </div>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: `Nom du projet`,
      formatter: nameFormatter,
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "categorie.nom",
      text: "Catégorie",
    },
    {
      dataField: "montant",
      text: "Montant ",
      formatter: (cell: string) => formatCurrency(cell),
    },
    {
      dataField: "is_active",
      text: "Activer",
      formatter: (cell: string, row: Project) => <ToggleProject item={row} />,
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: () => {
        return { width: "100px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            rowClasses="custom-table-row-class"
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ProjectsAdminTable;
