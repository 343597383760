/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { Dispatch, SetStateAction } from "react";
import Modal from "react-modal";
import { CloseModalBtnV2 } from "../../../shared/CloseModalBtn";
import { FormError, Input } from "../../../common/Input";
import UseCrudUserForm from "../form/UseCrudUserForm";
import { IUser } from "../../../../utils/api/user/user.type";
import PhoneInput from "react-phone-input-2";
import { getAvatar } from "../../../../utils/Utils";
import { BsCameraFill } from "react-icons/bs";

const AddUserModal = ({
  modalAddUser,
  setModalAddUser,
  item
}: {
  modalAddUser: boolean;
  setModalAddUser: Dispatch<SetStateAction<boolean>>;
  item?: IUser;
}) => {
  function closeModal() {
    setModalAddUser(false);
  }
  const {
    register,
    errors,
    isLoading,
    handleClose,
    phone,
    handleChangePhone,
    onSubmit,
    avatar,
    handleChangeAvatar,
  } = UseCrudUserForm(closeModal, item);

  return (
    <Modal
      isOpen={modalAddUser}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-header pe-2 py-1">
        <h5 className="modal-title" id="CustomAddModalLabel">
          {!item ? "Ajouter" : "Modifier"} un utilisateur
        </h5>
        <CloseModalBtnV2 onClick={() => handleClose()} />
      </div>
      <div>
        <div className="row mt-4">
          <form onSubmit={onSubmit}>
            <div className="row row-add-user my-3">
              <div className="col-md-12 mb-3">
                <div className="d-flex justify-content-center align-items-center mb-4 masoul-user-avatar-container">
                  <label htmlFor="input-test" className="input-testt">
                    <img
                      src={
                        avatar
                          ? URL.createObjectURL(avatar)
                          : getAvatar(item?.avatar)
                      }
                      alt={"avatar"}
                      className="pp-profil-user-visiteur"
                    />
                    <div className="masoul-user-avatar-edit-hover">
                      <BsCameraFill className="edit-avatar-camera-icon" />
                    </div>
                  </label>
                  <input
                    type="file"
                    className="input-add-avatar"
                    id="input-test"
                    accept="image/*"
                    onChange={handleChangeAvatar}
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <Input
                  type="text"
                  label="Nom"
                  id="__first_name"
                  placeholder="Nom"
                  required
                  {...register("nom")}
                  error={errors?.nom}
                />
              </div>
              <div className="col-md-6 mb-3">
                <Input
                  type="text"
                  label="Prénom"
                  id="__last_name"
                  required
                  placeholder="Prénom"
                  {...register("prenom")}
                  error={errors?.prenom}
                />
              </div>
              <div className="col-md-6 mb-3">
                <Input
                  type="email"
                  label="Email"
                  id="__email"
                  placeholder="Email"
                  required
                  {...register("email")}
                  error={errors?.email}
                />
              </div>
              <div className="col-md-6 mb-3">
                {/* <Input
                  type="tel"
                  label="Téléphone"
                  id="__phone"
                  placeholder="Téléphone"
                /> */}
                <div className="mb-3 text-start">
                  <label
                    htmlFor="phone"
                    className="form-label form-label-modal-custom"
                    aria-labelledby="phone"
                  >
                    Numéro de téléphone
                    <span className="text-danger" style={{ fontSize: 15 }}>
                      *
                    </span>
                  </label>
                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    containerClass="form-control form-control-modal-custom mb-3 p-0"
                    inputClass="form-control form-control-modal-custom-input-tel custom-padding"
                    country={"sn"}
                    placeholder=""
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: true,
                    }}
                    countryCodeEditable={false}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phone}
                    onChange={(phone, country: any) => {
                      handleChangePhone(phone, country);
                    }}
                    inputStyle={{
                      width: "100%",
                      paddingBottom: "10px",
                      borderRadius: "50px",
                      paddingLeft: "40px",
                      height: "40px",
                      border: "none",
                      background: "#f2f3f3",
                    }}
                  />
                  <FormError error={errors?.phone?.message} />
                </div>
              </div>
            </div>
            <div className="flex-r btn__wrapper text-end pt-5">
              <button
                type="button"
                className="btn custom_close__btn"
                disabled={isLoading}
                onClick={() => handleClose()}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn custom_submit__btn"
                disabled={isLoading}
              >
                {!isLoading ? (
                  "Enregistrer"
                ) : (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>En cours...</span>
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default AddUserModal;

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "55%",
    borderRadius: "15px",
    zIndex: 9999,
  },
};
