/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
  Project,
  ProjectFormData,
} from "../../../../utils/api/project/project.type";
import {
  useAddOrEditProjectMutation,
  useDeleteProjectMutation,
} from "../../../../utils/api/project/project.api";
import { useNavigate } from "react-router-dom";
import { useGetListCategoryProjectQuery } from "../../../../utils/api/category/category.api";

import { cleannerError } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChangeEvent, useEffect, useState } from "react";

function UseCrudProjectForm(project?: Project) {
  const validationSchema = yup.object().shape({
    hasEdit: yup.boolean(),
    nom: yup.string().required().label("Le nom"),
    description: yup
      .string()
      .transform((val: string) => (val === "<p><br></p>" ? "" : val))
      .required()
      .label("La description"),
    nom_arb: yup.string().nullable().label("Le nom en arabe"),
    description_arb: yup
      .string()
      .transform((val: string) => (val === "<p><br></p>" ? "" : val))
      .nullable()
      .label("La description en arabe"),
    nom_fr: yup.string().nullable().label("Le nom en français"),
    description_fr: yup
      .string()
      .transform((val: string) => (val === "<p><br></p>" ? "" : val))
      .nullable()
      .label("La description en français"),
    montant: yup.string().required().label("Le montant"),
    categorie: yup.string().required().label("La catégorie").nullable(),
    file: yup
      .mixed()
      .when("hasEdit", {
        is: false,
        then: () => yup.mixed().required().label("L'image").nullable(),
      })
      .label("L'image"),
    video: yup.string().url().label("Le lien vidéo").nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
  } = useForm<ProjectFormData>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditProjectMutation();
  const navigate = useNavigate();
  const { data = { results: [] } } = useGetListCategoryProjectQuery({
    limit: 1000,
  });
  const [description, setDescription] = useState("");
  const [descriptionArb, setDescriptionArb] = useState("");
  const [descriptionFr, setDescriptionFr] = useState("");
  const [image, setImage] = useState<any>(null);
  const [video, setVideo] = useState<any>(null);
  const [images, setImages] = useState<any>([]);
  const [option, setOption] = useState<any>(null);
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    if (data?.results?.length) {
      setOptions(
        data?.results?.map((item) => {
          return {
            label: item?.nom,
            value: item?.id,
          };
        })
      );
    }
  }, [data]);

  const onChangeDescription = (value) => {
    setValue("description", value);
    setDescription(value);
  };

  const onChangeDescriptionArb = (value) => {
    setValue("description_arb", value);
    setDescriptionArb(value);
  };

  const onChangeDescriptionFr = (value) => {
    setValue("description_fr", value);
    setDescriptionFr(value);
  };

  const handleSelected = (item) => {
    setValue("categorie", item?.value);
    setOption(item);
  };

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    let file =
      e?.target?.files && e?.target?.files?.length ? e?.target?.files[0] : null;
    if (file) {
      setValue(`file`, file);
      setImage(file);
    }
  };

  const onChangeVideo = (e: ChangeEvent<HTMLInputElement>) => {
    let file = e?.target?.value;
    if (file) {
      setValue(`video`, file);
      setVideo(file);
    }
  };
  useEffect(() => {
    if (project?.id) {
      setValue("hasEdit", true);
      const fields: (keyof ProjectFormData)[] = [
        "nom",
        "categorie",
        "description",
        "montant",
        "video",
        "nom_arb",
        "description_arb",
        "nom_fr",
        "description_fr",
      ];
      for (let field of fields) {
        register(field);
        if (field !== "categorie") {
          if (project[field]) setValue(field, project[field]);
        } else {
          setValue(field, project[field]?.id);
        }
      }

      if (project?.description) {
        setDescription(project?.description);
      }

      if (project?.description_arb) {
        setDescriptionArb(project?.description_arb);
      }

      if (project?.description_fr) {
        setDescriptionFr(project?.description_fr);
      }

      if (project?.categorie) {
        setOption({
          value: project?.categorie?.id,
          label: project?.categorie?.nom,
        });
      }
      if (project?.video) {
        setVideo(project?.video);
      }
      if (project?.file) {
        setImage(project?.file);
      } else {
        setValue("hasEdit", false);
      }
    } else {
      setValue("hasEdit", false);
    }
  }, [project]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: project
          ? "Le projet a été modifié avec succès !"
          : "Le projet a été ajouté avec succès !",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        navigate("/admin/projets");
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: ProjectFormData) => {
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "file") {
        if (data[key]) {
          fd.append(key, data[key] as any);
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    // console.log("data", data);
    await sendData({ slug: project?.slug, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    images,
    option,
    options,
    handleSelected,
    control,
    Controller,
    setImages,
    description,
    onChangeDescription,
    onChangeFile,
    onChangeVideo,
    video,
    image,
    descriptionArb,
    onChangeDescriptionArb,
    descriptionFr,
    onChangeDescriptionFr,
  };
}

export default UseCrudProjectForm;

export function UseToggleProject(item: Project) {
  const [sendData] = useAddOrEditProjectMutation();
  const onToggle = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.is_active ? "désactiver" : "activer"
      } ce projet?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData({
          slug: item?.slug,
          data: {
            is_active: !item?.is_active,
          },
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le projet a été ${
              item?.is_active ? "désactivé" : "activé"
            } avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onToggle;
}

export function UseDeleteProject(slug: string) {
  const [deleteData] = useDeleteProjectMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce projet ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le projet a été supprimé avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
