interface Props {
  haveMinCharacter: boolean;
  haveMinNumber: boolean;
  haveMinLowercase: boolean;
  haveMinSpecialCharacter: boolean;
  haveMinUppercase: boolean;
}

export function PasswordRules(props: Props) {
  const {
    haveMinCharacter,
    haveMinNumber,
    haveMinLowercase,
    haveMinSpecialCharacter,
    haveMinUppercase,
  } = props;
  return (
    <div className="col-md-12">
      <div className="input-group my-2 password-validation-terms-row">
        <div className="password-validation-terms-container">
          <h4 className="password-validation-terms-title">
            Votre mot de passe doit contenir :{" "}
          </h4>
          <ul className="password-validation-terms-ul p-l-14 text-start">
            <li
              className={
                "password-rule-item " + (haveMinCharacter && "text-success")
              }
            >
              Au minimum 8 caractères
            </li>
            <li
              className={
                "password-rule-item " + (haveMinUppercase && "text-success")
              }
            >
              Au minimum 1 caractère en majuscule
            </li>
            <li
              className={
                "password-rule-item " + (haveMinLowercase && "text-success")
              }
            >
              Au minimum 1 caractère en minuscule
            </li>
            <li
              className={
                "password-rule-item " + (haveMinNumber && "text-success")
              }
            >
              Au minimum 1 nombre
            </li>
            <li
              className={
                "password-rule-item " +
                (haveMinSpecialCharacter && "text-success")
              }
            >
              Au minimum 1 caractère spéciale
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
