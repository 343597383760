import React from "react";
import { Condition } from "../../../../utils/api/condition/condition.type";
import { BtnAnnuler, BtnCloseModalV2, BtnSubmit } from "../../../common/Button";
import ReactQuill from "react-quill";
import { FormError } from "../../../common/Input";
import UseCrudConditionForm from "../form/UseCrudConditionForm";

function AddOrEditCGUModal({
  modalId,
  type,
  item,
}: {
  modalId: string;
  type: Condition["type"];
  item?: Condition;
}) {
  const {
    description,
    handleChange,
    handleReset,
    onSubmit,
    errors,
    isLoading,
    handleChangeArb,
    descriptionArb,
    descriptionFr,
    handleChangeFr,
  } = UseCrudConditionForm(modalId, type, item);
  return (
    <div
      className="modal fade"
      id={modalId}
      aria-labelledby="UpdatePolitiqueLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content text-start">
          <div className="modal-header custom-modal-header px-2 py-1">
            <h5 className="modal-title" id="AddTagModalLabel">
              {item ? "Modification" : "Ajout"} du contenu
            </h5>
            <div className="workcares-btn-close-modal-container">
              <BtnCloseModalV2 label="" onClick={() => handleReset()} />
            </div>
          </div>
          <div className="modal-body">
            <div className="container-form">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="text_fr"
                        className="form-label form-label-modal-custom"
                        aria-labelledby={"text"}
                      >
                        Contenu en anglais
                        <span className="text-danger" style={{ fontSize: 24 }}>
                          *
                        </span>
                      </label>
                      <ReactQuill
                        className="editor-cgu custom-editor-politique-confidentialite"
                        value={description}
                        onChange={handleChange}
                      />

                      <FormError error={errors.text?.message} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="text_fr"
                        className="form-label form-label-modal-custom"
                        aria-labelledby={"text"}
                      >
                        Contenu en français
                      </label>
                      <ReactQuill
                        className="editor-cgu custom-editor-politique-confidentialite"
                        value={descriptionFr}
                        onChange={handleChangeFr}
                      />

                      <FormError error={errors.text_fr?.message} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="ctn"
                        className="form-label form-label-modal-custom"
                        aria-labelledby={"text"}
                      >
                        Contenu en arabe
                      </label>
                      <ReactQuill
                        className="editor-cgu custom-editor-politique-confidentialite aribic-input"
                        value={descriptionArb}
                        onChange={handleChangeArb}
                      />

                      <FormError error={errors.text_arb?.message} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3 flex-r gap-3">
                      <BtnAnnuler
                        label={"Annuler"}
                        isLoading={false}
                        onClick={() => handleReset()}
                      />
                      <BtnSubmit
                        label={item ? "Modifier" : "Ajouter"}
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrEditCGUModal;
