import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import GoBackWrapper from "../../shared/GoBackWrapper";
import "./CommandAdmin.css";
import CommandDetailsProductTable from "../Tables/CommandDetailsProductTable";
import { useOrderFromLocation } from "../../../utils/api/order/order.api";
import moment from "moment";
import {
  formatCurrency,
  getDeliveryMode,
  getName,
  getStatutClassName,
  getStatutLabel,
} from "../../../utils/Utils";

function CommandDetails() {
  const [order, isLoading] = useOrderFromLocation();
  // let item = order?.cart?.items[0]
  return (
    <>
      <GoBackWrapper link="/admin/commandes" />
      <WrapperComponent>
        <div className="card_content pt-2">
          <div className="custom_command_details__header">
            <div className="row header_row justify-content-between">
              <div className="col-md-6 custom_wrapper">
                <h2 className="m-0 command_number"> {order?.code_commande} </h2>
                <div className="pt-4 d-flex align-items-center gap-4 command_date_and_deliver_status__infos">
                  <div className="date__infos">
                    <span className="sm_title reduce_size">Date :</span>&nbsp;
                    <span className="sm_value">
                      {moment(order?.created_at).format("DD MMMM YYYY")}
                    </span>
                  </div>
                  <div className="separator"></div>
                  <div className="deliver_status__infos">
                    <span className="sm_title reduce_size">
                      Statut global :
                    </span>
                    &nbsp;
                    <span
                      className={`sm_value ${getStatutClassName(
                        order?.statut
                      )}`}
                    >
                      {getStatutLabel(order?.statut)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4 custom_wrapper seller_about">
                {/* <div className="seller_informations">
                  <p className="m-0 pb-2 sm_title">Vendeur</p>
                  <p className="m-0 sm_value">{getName(item?.produit?.vendeur)}</p>
                </div> */}
              </div>
            </div>
          </div>
          <div className="product_table__wrapper pt-5">
            {order?.parents?.length ? (
              order?.parents?.map((item) => (
                <CommandDetailsProductTable
                  data={item?.produits}
                  isLoading={isLoading}
                  item={item}
                  key={item?.slug}
                />
              ))
            ) : order?.cart?.items?.length ? (
              <CommandDetailsProductTable
                data={order?.cart?.items}
                isLoading={isLoading}
                item={order}
              />
            ) : null}
          </div>
          <div className="other_infos_about_command pt-5 pb-2">
            <div className="row">
              <div className="col-md-6">
                <div className="pb-4 buyer__content">
                  <p className="m-0 pb-1 sm_title">Acheteur</p>
                  <p className="m-0 sm_value">{getName(order?.user)}</p>
                </div>
                <div className="pb-4 payment_mode__content">
                  <p className="m-0 pb-1 sm_title">Mode de livraison</p>
                  <p className="m-0 sm_value">{getDeliveryMode(order)}</p>
                </div>
                <div className="pb-4 phone__content">
                  <p className="m-0 pb-1 sm_title">Téléphone</p>
                  <p className="m-0 sm_value">{order?.user?.phone}</p>
                </div>
                <div className="address__content">
                  <p className="m-0 pb-1 sm_title">Adresse</p>
                  <p className="m-0 sm_value">{order?.user?.adress}</p>
                </div>
              </div>
              <div className="col-md-6 flex-r">
                <div className="amount-container">
                  <div className="pb-4 buyer__content d-flex gap-5 align-items-center">
                    <p className="m-0 pb-1 sm_value">Sous total</p>
                    <p className="m-0 sm_value">
                      {formatCurrency(order?.total) +
                        " " +
                        "(-" +
                        order?.total_points +
                        "pts)"}
                    </p>
                  </div>

                  <div className="pb-4 phone__content d-flex gap-5 align-items-center">
                    <p className="m-0 pb-1 sm_title">Livraison</p>
                    <p className="m-0 sm_value">{formatCurrency(0)}</p>
                  </div>
                  <div className="address__content d-flex gap-5 align-items-center">
                    <p className="m-0 pb-1 sm_title">Total</p>
                    <p className="m-0 sm_title">
                      {formatCurrency(order?.total) +
                        " " +
                        "(-" +
                        order?.total_points +
                        "pts)"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </WrapperComponent>
    </>
  );
}

export default CommandDetails;
