/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
  Condition,
  ConditionFormData,
} from "../../../../utils/api/condition/condition.type";
import { useAddOrEditConditionMutation } from "../../../../utils/api/condition/condition.api";
import { cleannerError, closeModal } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";

function UseCrudConditionForm(
  modalId: string,
  type: Condition["type"],
  condition?: Condition
) {
  const validationSchema = yup.object().shape({
    text: yup
      .string()
      .required()
      .transform((val) => (val === "<p><br></p>" ? "" : val))
      .label("Le contenu")
      .nullable(),
    text_arb: yup
      .string()
      .transform((val) => (val === "<p><br></p>" ? "" : val))
      .label("Le contenu en arabe")
      .nullable(),
    text_fr: yup
      .string()
      .transform((val) => (val === "<p><br></p>" ? "" : val))
      .label("Le contenu en français")
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    resetField,
  } = useForm<ConditionFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [addOrUpdateCondition, { isLoading, isSuccess, error, isError }] =
    useAddOrEditConditionMutation();
  const [description, setDescription] = React.useState<string>("");
  const [descriptionArb, setDescriptionArb] = React.useState<string>("");
  const [descriptionFr, setDescriptionFr] = React.useState<string>("");


  useEffect(() => {
    setValue("type", type);
  }, [type]);

  const handleChange = (value: string) => {
    setDescription(value);
    setValue("text", value);
  };

  const handleChangeArb = (value: string) => {
    setDescriptionArb(value);
    setValue("text_arb", value);
  };

  const handleChangeFr = (value: string) => {
    setDescriptionFr(value);
    setValue("text_fr", value);
  };

  const handleReset = () => {
    if (!condition) {
      setDescription("");
      setDescriptionArb("");
      setDescriptionFr("");
      resetField("text");
      resetField("text_arb")
      resetField("text_fr")
    }
    closeModal(modalId);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Le texte a été ${
          condition ? "modifié" : "ajouté"
        } avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        handleReset();
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (condition?.id) {
      const fields: (keyof ConditionFormData)[] = ["slug", "text","text_arb", "text_fr"];
      for (let field of fields) {
        register(field);
        if (condition[field]) setValue(field, condition[field]);
      }
      setDescription(condition?.text);
      setDescriptionArb(condition?.text_arb || "")
      setDescriptionFr(condition?.text_fr || "");
    }
  }, [condition]);

  const onSubmit = (data: ConditionFormData) => {
    console.log("data", data);
    addOrUpdateCondition(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
    description,
    setDescription,
    handleChange,
    handleChangeArb,
    descriptionArb,
    descriptionFr,
    handleChangeFr
  };
}

export default UseCrudConditionForm;
