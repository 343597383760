import React from 'react'
import { BtnAnnulerModal, BtnCloseModal, BtnSubmit } from '../../../common/Button';
import { Input } from '../../../common/Input';
import { SelectInput } from '../../../common/SelectInput';

const ActiviteTypeOption = [
    {
      value: "document",
      label: "Document",
    },
    {
      value: "faq",
      label: "FAQ",
    },
    {
      value: "qsn",
      label: "Qui sommes nous",
    },
  ];

function AddFieldModal() {
  return (
    <div className="modal-dialog modal-md">
      <div className="modal-content">
        <div className="modal-header custom-modal-header px-2 py-1">
          <h5 className="modal-title" id="AddTagModalLabel">
          Ajouter un champ
          </h5>
          <div className="workcares-btn-close-modal-container">
            <BtnCloseModal label="" />
          </div>
        </div>
        <div className="modal-body">
          <div className="container-form">
            <form>
              <div className="row">
                <div className="col-md-12">
                    <SelectInput
                      label="Sélectionner le type d'activité"
                      options={ActiviteTypeOption}
                    />
                </div>
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Titre"
                    id="prenom"
                    placeholder="Titre"
                  />
                </div>
                <div className="col-md-12">
                  <div className="mb-3 flex-r gap-3">
                    <BtnAnnulerModal label={"Annuler"} isLoading={false} />
                    <BtnSubmit label="Ajouter" isLoading={false} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddFieldModal