import React, { useState } from "react";
import { BtnSubmit } from "../../../common/Button";
import UseFormChangePassword from "../form/UseFormChangePassword";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { FormError } from "../../../common/Input";
import { PasswordRules } from "../../../auth/PasswordRules";

function PasswordTab() {
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
  } = UseFormChangePassword();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const props = {
    haveMinCharacter,
    haveMinNumber,
    haveMinLowercase,
    haveMinSpecialCharacter,
    haveMinUppercase,
  };
  return (
    <div className="masoul-dash-admin-page-content-container mb-3">
      <div className="masoul-dash-admin-page-container">
        <div className="page-content-container mt-4">
          <div className="parent-table-container">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-4 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label masoul-form-label"
                    >
                      Ancien mot de passe
                    </label>
                    <div className="form-login-content mb-4 position-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control form-control-login px-4"
                        placeholder="************"
                        {...register("old_password")}
                      />
                      <span
                        className="show-hide-password"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                      </span>
                      <FormError error={errors?.old_password} />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label masoul-form-label"
                    >
                      Nouveau mot de passe
                    </label>
                    <div className="form-login-content mb-4 position-relative">
                      <input
                        type={showNewPassword ? "text" : "password"}
                        className="form-control form-control-login px-4"
                        placeholder="************"
                        onChange={passwordHandleChange}
                      />
                      <span
                        className="show-hide-password"
                        onClick={() => {
                          setShowNewPassword(!showNewPassword);
                        }}
                      >
                        {showNewPassword ? (
                          <AiFillEyeInvisible />
                        ) : (
                          <AiFillEye />
                        )}
                      </span>
                      <FormError error={errors?.new_password} />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label masoul-form-label"
                    >
                      Confirmer mot de passe
                    </label>
                    <div className="form-login-content mb-4 position-relative">
                      <input
                        type={"password"}
                        className="form-control form-control-login px-4"
                        placeholder="************"
                        {...register("confirm_password")}
                      />
                      <FormError error={errors?.confirm_password} />
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="mb-3 flex-r gap-3">
                    <BtnSubmit label="Modifier" isLoading={isLoading} />
                  </div>
                </div>
                <PasswordRules {...props} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordTab;
