/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Claim, ClaimFormData } from "../../../../utils/api/claim/claim.type";
import { useAddOrEditClaimMutation } from "../../../../utils/api/claim/claim.api";
import { Color } from "../../../../utils/theme";
import { cleannerError, closeModal } from "../../../../utils/Utils";

function UseEditStatusClaim(item: Claim, modalId: string) {
  const validationSchema = yup.object().shape({
    statut: yup.string().required().label("Le statut"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<ClaimFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditClaimMutation();

  useEffect(() => {
    if (item?.id) {
      const fields: (keyof ClaimFormData)[] = ["slug", "statut"];
      for (let field of fields) {
        register(field);
        if (field !== "user" && field !== "order") {
          setValue(field, item[field]);
        }
      }
    }
  }, [item]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: "Le statut de la réclamation a été modifié avec succès !",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        closeModal(modalId);
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: ClaimFormData) => {
    console.log("data", data);
    await sendData(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
  };
}

export default UseEditStatusClaim;
