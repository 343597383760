/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import "./DashboardAdmin.css";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import TabsDataToday from "./Tabs/TabsDataToday";
import DatePicker, { registerLocale } from "react-datepicker";
import { fr } from "date-fns/locale";
import { getStartOfWeek } from "../../../utils/Utils";

registerLocale("fr", fr);

const steps = [
  { id: "week", Component: TabsDataToday },
  { id: "month", Component: TabsDataToday },
  { id: "year", Component: TabsDataToday },
  { id: "all", Component: TabsDataToday },
  { id: "interval", Component: TabsDataToday },
];

const tabs = [
  "Cette semaine",
  "Ce mois-ci",
  "Cette année",
  "Global",
  "Interval",
];

const DashboardAdmin = () => {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    let start: any = new Date();
    let end: any = new Date();
    var today = new Date();
    if (step?.id === "week") {
      // start = moment(new Date()).startOf("week").format("DD-MM-YYYY");
      end = new Date(today.setDate(today.getDate() - today.getDay() + 7));
      setStartDate(getStartOfWeek(today));
      setEndDate(end);
    }

    if (step?.id === "month") {
      start = new Date(today.getFullYear(), today.getMonth(), 1);
      end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      setStartDate(start);
      setEndDate(end);
    }

    if (step?.id === "year") {
      start = new Date(today.getFullYear(), 0, 1);
      end = new Date(today.getFullYear(), 11, 31);
      setStartDate(start);
      setEndDate(end);
    }
  }, [step?.id]);

  const props = {
    startDate,
    endDate,
    filter: step?.id,
  };

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        {step?.id === "interval" && (
          <div className="container-form-date-filter">
            <div className="row row-date-filter pb-4">
              <div className="col-lg-3 col-lg-date-filter mb-3">
                <div className="content-col-lg-date-filter">
                  <label
                    htmlFor="du"
                    className="form-label form-label-date-filter"
                  >
                    Du
                  </label>
                  <DatePicker
                    selected={startDate}
                    locale="fr"
                    dateFormat="dd/MMMM/yyyy"
                    onChange={(date) => setStartDate(date)}
                    className="form-control form-control-date-filter"
                  />
                </div>
              </div>
              <div className="col-lg-3 col-lg-date-filter mb-3">
                <div className="content-col-lg-date-filter">
                  <label
                    htmlFor="au"
                    className="form-label form-label-date-filter"
                  >
                    Au
                  </label>
                  <DatePicker
                    selected={endDate}
                    locale="fr"
                    dateFormat="dd/MMMM/yyyy"
                    onChange={(date) => setEndDate(date)}
                    className="form-control form-control-date-filter"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="container-tab-dashoard-data">
          <ul className="tabs-title-content-dashboard-data">
            {tabs.map((tab: string, key: number) => (
              <li
                className={`tab-item-dashboard  ${
                  index === key && "tab-item-dashboard-active"
                }`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>
        <div className="custom-tabs-content mt-5">
          <Component {...props} />
        </div>
      </div>
    </div>
  );
};

export default DashboardAdmin;
