/* eslint-disable @typescript-eslint/no-explicit-any */
import { PhoneNumberUtil } from "google-libphonenumber";
import * as Yup from "yup";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { IUser } from "./api/user/user.type";
import Avatar from "../assets/appImages/default.png";
import Image from "../assets/appImages/img-bg.png";
import { ApiBaseUrl } from "./http";
import $ from "jquery";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import moment from "moment";
import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { useAppSelector } from "../redux/hook";
import { Order } from "./api/order/order.type";
import { Claim } from "./api/claim/claim.type";

const phoneUtil = PhoneNumberUtil.getInstance();

export const useUser = () => {
  const { user } = useAppSelector((s) => s?.user);

  return user;
};

export function validateNumber(phone?: string, country?: string) {
  if (!phone || !country) return true;
  try {
    const number = phoneUtil.parse(phone, country);
    if (phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number)) {
      return true;
    }
  } catch (ex) {
    console.log("error phone", ex);
  }
  return false;
}

export function validatePhone(yup: typeof Yup, country: string) {
  return yup
    .string()
    .test("passwords-match", "Numéro téléphone invalide", (val) =>
      validateNumber(val, country)
    )
    .label("Le téléphone")
    .nullable();
}

export function validatePassword(Yup: any) {
  return Yup.string()
    .required("Le mot de passe est obligatoire ")
    .matches(/[a-z]+/, "Le mot de passe doit avoir au moins une minuscule.")
    .matches(/[A-Z]+/, "Le mot de passe doit avoir au moins une majuscule.")
    .matches(
      /[!@#$%^&*(),;-_+*/.?":{}|<>]+/,
      "Le mot de passe doit avoir au moins un caractère spécial"
    )
    .matches(/\d+/, "Le mot de passe doit avoir au moins un chiffre.")
    .min(8, "Le mot de passe doit être supérieur à 5 caractére");
  //.label("Le mot de passe");
}

export function useLocationState<T>(
  defaultValue: T | any,
  redirectToWhenNull: string | null = null
): T {
  const state = useLocation().state as T;
  const navigate = useNavigate();
  useEffect(() => {
    if (!state && redirectToWhenNull) {
      navigate(redirectToWhenNull);
    }
  }, [state]);
  return state || defaultValue;
}
export function createMarkup(
  text: string | any,
  showDots?: boolean,
  numberSlice?: number
) {
  return {
    __html: `${numberSlice ? text.slice(0, numberSlice) : text}${
      showDots === true ? "..." : ""
    }`,
  };
}
export function readMarkup(
  text: string | any,
  showDots?: boolean,
  numberSlice?: number
) {
  let content = text ? text.replace(/<.+?>/g, "") : text;
  return {
    __html: `${numberSlice ? content.slice(0, numberSlice) : content}${
      showDots === true ? "..." : ""
    }`,
  };
}

export function cleannerError(
  errors: any,
  cleanner: any,
  timeOut: number = 3000
) {
  if (errors) {
    setTimeout(
      () => Object.entries(errors).map(([key]: any) => cleanner(key)),
      timeOut
    );
  }
}

export function isRH(user: any) {
  if (!user) return false;
  return ["rh"].includes(user.user_type);
}

// export function isGestionnaire(user: any) {
//   if (!user) return false;
//   return ["gestionnaire"].includes(user.user_type);
// }

export function QueryUrl(baseUrl: string, additionalSearchQuery: any) {
  if (additionalSearchQuery) {
    for (let key of Object.keys(additionalSearchQuery)) {
      let val = additionalSearchQuery[key];

      if (Array.isArray(val)) {
        if (val.length > 0) {
          const string = val.map((v) => `${key}=${v}`).join("&");
          if (baseUrl.includes("?")) {
            baseUrl += `&${string}`;
          } else {
            baseUrl += `?${string}`;
          }
        }
      } else if (typeof val === "boolean") {
        if (baseUrl.includes("?")) {
          baseUrl += `&${key}=${val}`;
        } else {
          baseUrl += `?${key}=${val}`;
        }
      } else {
        if (val) {
          if (baseUrl.includes("?")) {
            baseUrl += `&${key}=${val}`;
          } else {
            baseUrl += `?${key}=${val}`;
          }
        }
      }
    }
  }
  return baseUrl;
}

export const getAvatar = (avatar: string | any) => {
  if (avatar && !avatar?.includes("default.png")) {
    if (avatar.indexOf("http") === 0) return avatar;
    return `${ApiBaseUrl}/${avatar}`;
  }
  return Avatar;
};

export const getImage = (image: string | any) => {
  if (image && !image?.includes("default.png")) {
    if (image.indexOf("http") === 0) return image;
    return `${ApiBaseUrl}/${image}`;
  }
  return Image;
};

export function getName(user: IUser) {
  // console.log(user,"teste")
  let name = user?.prenom + " " + user?.nom;

  return name;
}

// export function getType(user: IUser) {
//   let type = "Admin";

//   return type;
// }

export const onHide = (modalId) => {
  // console.log("modalId",modalId)
  document.getElementById(modalId)?.classList.remove("show");
  $(`#${modalId}`).hide();
  // $(`#${modalId}`).click();
  $("body").removeClass("modal-open");
  $(".modal-backdrop").remove();
  $(`body`).css({ overflowY: "scroll" });
};

export const onShow = (modalId: string) => {
  document.getElementById(modalId)?.classList.add("show");
  $(`#${modalId}`).show();
};

export const countUppercase = (str: any) => {
  if (str.replace(/[^A-Z]/g, "")?.length >= 1) {
    return true;
  }
  return false;
};

export const countLowercase = (str: any) => {
  if (str.replace(/[^a-z]/g, "")?.length >= 1) {
    return true;
  }
  return false;
};

export const countNumber = (str: any) => {
  if (str.replace(/[^0-9]/g, "")?.length >= 1) {
    return true;
  }
  return false;
};

export const countCharacters = (str: any) => {
  if (str?.length >= 8) {
    return true;
  }
  return false;
};

export const countSpecial = (str: any) => {
  const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
  let count = 0;
  for (let i = 0; i < str?.length; i++) {
    if (!punct.includes(str[i])) {
      continue;
    }
    count++;
  }
  // return count;
  if (count >= 1) {
    return true;
  }
  return false;
};

const CURRENCY_FORMATTER = new Intl.NumberFormat(undefined, {
  currency: "QAR",
  style: "currency",
});

export function formatCurrency(number: number | any) {
  return CURRENCY_FORMATTER.format(number);
}

export const getAddress = (user: IUser) => {
  return user?.adress;
};

export const formatDateHour = (date) => {
  return moment(date).format("DD MMM, YYYY à HH:mm");
};

export const formatDate = (date) => {
  if (!date) return "";
  return moment(date).format("DD/MM/YYYY");
};

export const formatHour = (hour) => {
  if (!hour) return "";
  return moment(hour, "HH:mm").format("HH[h]mm");
};

export const sortByAlphabetical = (arr) => {
  return [...arr]?.sort(function (a, b) {
    if (a?.name < b?.name) {
      return -1;
    }
    if (a?.name > b?.name) {
      return 1;
    }
    return 0;
  });
};

export const truncate = (str) => {
  return str != null && str.length > 10 ? str.substring(0, 19) + "..." : str;
};

export const showModal = (id: string) => {
  // console.log("id", id);
  const element = document.getElementById(id);

  const myModal = new Modal(element);
  myModal.show();
};

export const closeModal = (id: string) => {
  const element = document.getElementById(id);
  const myModal = Modal.getInstance(element);
  $(".modal-backdrop").remove();
  $("body").removeClass("modal-open");
  $("body").css("padding-right", "");
  $("body").css("overflow", "scroll");
  myModal?.hide();
  $("body").css("overflow", "auto");
};

export const getAge = (date: string) => {
  if (!date) {
    return "";
  }
  var birthyear = moment(date, "YYYY-MM-DD");
  var visitdate = moment(new Date());
  return visitdate.diff(birthyear, "y") + "ans";
};

export const checkDate = (date: string) => {
  let isValid = moment(date, "YYYY-MM-DD")?.isSame(new Date(), "day");
  // console.log("date",date)
  return isValid;
};

export const fileName = (uri) => {
  var filename = uri?.split("/")?.pop();

  return filename || "";
};

export function get_url_extension(uri: any) {
  if (uri?.type) {
    return uri?.type?.toLowerCase()?.split("/")[1];
  }
  return uri?.toLowerCase()?.split(/[#?]/)[0]?.split(".")?.pop()?.trim();
}

export const getFile = (file: string | any) => {
  if (file && !file.includes("default.png")) {
    if (file.indexOf("http") === 0) return file;
    return ApiBaseUrl + file;
  }
  return "";
};

export const getMsgDate = (date: string) => {
  let value = formatDate(date);
  var yesterday = moment().subtract(1, "d");

  if (moment(date).isSame(new Date(), "d")) {
    value = moment(date).format("HH[h]mm");
  } else if (moment(date).isSame(new Date(), "week")) {
    if (moment(date).isSame(yesterday, "d")) {
      value = "Hier";
    } else {
      value = moment(date).format("DDDD");
    }
  }

  return value;
};

export const getUrlBase64ToBlob = (url) => {
  if (!url) return null;

  return dataURLtoBlob(url);
};

export const useOutsideClick = (callback) => {
  const ref = React.useRef<any>(null);

  React.useEffect(() => {
    const handleClick = () => {
      callback();
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return ref;
};

export const getStatutLabel = (statut: Order["statut"]) => {
  let status = "En cours";
  if (statut === "annulee") {
    status = "Annulée";
  }

  if (statut === "confirmee") {
    status = "Confirmée";
  }

  if (statut === "livree") {
    status = "Livrée";
  }

  if (statut === "claim") {
    status = "Reclamée";
  }

  return status;
};

export const getStatutClassName = (statut: Order["statut"]) => {
  let className = "inprogress";
  if (statut === "annulee") {
    className = "cancel";
  }

  if (statut === "confirmee") {
    className = "confirmed";
  }

  if (statut === "livree") {
    className = "delivered";
  }

  if (statut === "claim") {
    className = "claimed";
  }

  return className;
};

export const getStatutClaimLabel = (statut: Claim["statut"]) => {
  let status = "En cours";
  if (statut === "annulee") {
    status = "Annulée";
  }

  if (statut === "rembourser") {
    status = "Remboursée";
  }

  if (statut === "rejeter") {
    status = "Rejetée";
  }

  return status;
};

export const getStatutClaimClassName = (statut: Claim["statut"]) => {
  let className = "inprogress";
  if (statut === "annulee") {
    className = "cancel";
  }

  if (statut === "rembourser") {
    className = "confirmed";
  }

  if (statut === "rejeter") {
    className = "cancel";
  }

  return className;
};

export function getStartOfWeek(d) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export const getDeliveryMode = (item: Order) => {
  let mode = "Livraison à domicile";

  if (item?.mode_livraison === "click_and_collect") {
    mode = "Click and collect";
  }

  return mode;
};
