import React from "react";
// import { MdOutlineFileDownload } from "react-icons/md";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Dashboard } from "../../../../utils/api/dashboard/dashboard.type";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function LineChart({ graph }: { graph: Dashboard["graph"] }) {
  // const [labels, setLabels] = useState([])
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
    },
  };

  const labels: string[] = graph?.map((el) => (el?.date));

  const data = {
    labels,
    datasets: [
      {
        label: "Utilisateurs",
        data: graph?.map((el) => el?.users),
        borderColor: "#2A71D5",
        backgroundColor: "#3350a414",
        fill: true,
        lineTension: 0.3,
      },
    ],
  };

  return (
    <div className="chart-item-container">
      <div className="content-chart-title mb-4">
        <h2 className="chart-item-admin-title mb-0">Utilisateurs</h2>
        <div className="content-select-date-graph">
          {/* <button className="btn btn-export-data-dashboard">
            <span className="pe-2">Extraire en csv</span>
            <span>
              <MdOutlineFileDownload />
            </span>
          </button> */}
        </div>
      </div>
      <Line options={options} data={data} />
    </div>
  );
}

export default LineChart;
