import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Claim, ClaimFormData } from "./claim.type";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ClaimApi = createApi({
  reducerPath: "claimApi",
  tagTypes: ["claim", "claimList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListClaim: builder.query<PaginationResults<Claim>, TypeQuery>({
      providesTags: ["claimList"],
      query: (query) => QueryUrl("claims/", query),
    }),
    addOrEditClaim: builder.mutation<Claim, ClaimFormData>({
      invalidatesTags: ["claimList"],
      query: ({ slug, ...data }) => {
        if (slug) {
          return {
            url: `claims/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `claims/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteClaim: builder.mutation<Claim, string>({
      query: (slug) => ({
        url: `claims/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["claimList"],
    }),
    claimBySlug: builder.query<Claim, string>({
      query: (slug) => `claims/${slug}/`,
      providesTags: ["claim"],
    }),
  }),
});

export const {
  useAddOrEditClaimMutation,
  useGetListClaimQuery,
  useDeleteClaimMutation,
  useLazyClaimBySlugQuery,
} = ClaimApi;

export function useClaimFromLocation(): [
  Claim,
  boolean,
  string,
  (slug: string) => void
] {
  const itemState = useLocationState<Claim | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findById, { data, isError, isLoading,error }] = useLazyClaimBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findById(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError) {
      console.log("err",error)
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return [item as Claim, isLoading, slug as string, findById];
}
