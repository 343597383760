import React from "react";
import UseEditStatusClaim from "../form/UseEditStatusClaim";
import { Claim } from "../../../../utils/api/claim/claim.type";
import { getName } from "../../../../utils/Utils";
import { FormError } from "../../../common/Input";

export const orderType = [
  {
    label: "En cours",
    value: "en_cours",
  },
  {
    label: "Rembourser",
    value: "rembourser",
  },
  {
    value: "annulee",
    label: "Annuler",
  },
  {
    value: "rejeter",
    label: "Rejeter",
  },
];

function ChangeStatutModal({ item }: { item: Claim }) {
  const { register, errors, onSubmit, isLoading } = UseEditStatusClaim(
    item,
    `changeStatusClaim${item?.slug}`
  );
  return (
    <div
      className="modal fade"
      id={`changeStatusClaim${item?.slug}`}
      tabIndex={-1}
      aria-labelledby={`changeStatusClaim${item?.slug}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id={`changeStatusClaim${item?.slug}Label`}
            >
              Réclamation de {getName(item?.user)}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="pt-3">
              <form onSubmit={onSubmit}>
                <div className="row py-3 px-2">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="nombre_stock"
                        className="form-label form-label-modal-custom"
                        aria-labelledby={"status"}
                      >
                        Statut
                      </label>
                      <select
                        id="status"
                        className="form-select form-select-modal-custom mb-2"
                        {...register("statut")}
                      >
                        <option value="" disabled>Statut</option>
                        {orderType?.map((option) => (
                          <option value={option.value} key={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>

                      {errors?.statut && (
                        <FormError error={errors?.statut?.message} />
                      )}
                    </div>
                  </div>
                  <div className="flex-r">
                    <button
                      className="btn btn-cancelled"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      className="btn btn-theme"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                            role="status"
                          ></span>
                          <span>En cours...</span>
                        </>
                      ) : (
                        "Modifier"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeStatutModal;
