/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { AppStorage } from "../../storage";
import { QueryUrl, useLocationState } from "../../Utils";
import { ChangePasswordData } from "../auth/auth.type";
import {
  AuthState,
  IUser,
  PaginationResults,
  Share,
  TypeQuery,
  UserFormData,
} from "./user.type";

export const prepareHeaders = (headers: Headers, { getState }) => {
  const token =
    (getState() as { user: AuthState }).user.token ??
    AppStorage.getItem<AuthState>("user")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const UserApi = createApi({
  reducerPath: "userApi",
  tagTypes: [
    "user",
    "userList",
    "adminsList",
    "sellerList",
    "userById",
    "seller",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    me: builder.query<IUser, void>({
      providesTags: ["user"],
      // transformResponse: ({ data }) => data,
      query: () => {
        return "auth/me/";
      },
    }),
    addOrEditUser: builder.mutation<
      IUser,
      { slug?: string; data: UserFormData | FormData }
    >({
      invalidatesTags: ["user", "adminsList", "userList", "sellerList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `users/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `visiteur/create/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrEditAdmin: builder.mutation<
      IUser,
      { slug?: string; data: UserFormData | FormData }
    >({
      invalidatesTags: ["user", "adminsList", "userList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `user/admins/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `user/admins/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrEditSeller: builder.mutation<
      IUser,
      { slug?: string; data: UserFormData | FormData }
    >({
      invalidatesTags: ["seller", "sellerList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `users/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `vendeur/create/`,
          method: "POST",
          body: data,
        };
      },
    }),
    changePassword: builder.mutation<any, ChangePasswordData>({
      query: (data) => ({
        url: `auth/change-password/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    getListUser: builder.query<PaginationResults<IUser>, TypeQuery>({
      providesTags: ["userList"],
      query: (query) => QueryUrl("visiteurs", query),
    }),
    getSellerList: builder.query<PaginationResults<IUser>, TypeQuery>({
      providesTags: ["sellerList"],
      query: (query) => QueryUrl("vendeurs", query),
    }),
    getAllSeller: builder.query<IUser[], void>({
      providesTags: ["sellerList"],
      query: () => "vendeurs_mobile",
    }),
    getListAdminUser: builder.query<PaginationResults<IUser>, TypeQuery>({
      providesTags: ["adminsList"],
      query: (query) => QueryUrl("user/admins", query),
    }),
    sellerById: builder.query<IUser | any, string>({
      query: (slug) => `vendeurs/${slug}/`,
      providesTags: ["seller"],
    }),
    userById: builder.query<IUser | any, string>({
      query: (slug) => `users/${slug}/`,
      providesTags: ["userById"],
    }),
    adminBySlug: builder.query<IUser | any, string>({
      query: (slug) => `user/admins/${slug}/`,
    }),
    archiveUser: builder.mutation<IUser | any, string>({
      query: (slug) => ({
        url: `users/${slug}/`,
        method: "GET",
      }),
      invalidatesTags: ["user", "adminsList", "userList"],
    }),
    unArchiveUser: builder.mutation<IUser | any, string>({
      query: (slug) => ({
        url: `reactive_user/${slug}/`,
        method: "GET",
      }),
      invalidatesTags: ["user", "adminsList", "userList"],
    }),
    deleteUser: builder.mutation<UserFormData, string>({
      query: (slug) => ({
        url: `users/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["user", "userList", "adminsList", "sellerList"],
    }),
    disabledUser: builder.mutation<IUser | any, string>({
      query: (id) => ({
        url: `block_user/${id}/`,
        method: "GET",
      }),
      invalidatesTags: ["user", "adminsList", "userList", "sellerList"],
    }),
    enabledUser: builder.mutation<IUser | any, string>({
      query: (id) => ({
        url: `deblock_user/${id}/`,
        method: "GET",
      }),
      invalidatesTags: ["user", "adminsList", "userList", "sellerList"],
    }),
    sharePoind: builder.mutation<IUser, Share>({
      query: (data) => ({
        url: `sharings/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["userList"],
    }),
  }),
});

export const {
  useMeQuery,
  useAddOrEditUserMutation,
  useChangePasswordMutation,
  useGetListUserQuery,
  useAddOrEditAdminMutation,
  useGetListAdminUserQuery,
  useLazyUserByIdQuery,
  useArchiveUserMutation,
  useUnArchiveUserMutation,
  useDeleteUserMutation,
  useLazyAdminBySlugQuery,
  useAddOrEditSellerMutation,
  useGetSellerListQuery,
  useLazySellerByIdQuery,
  useDisabledUserMutation,
  useEnabledUserMutation,
  useGetAllSellerQuery,
  useSharePoindMutation
} = UserApi;

export function useUserFromLocation(): [
  IUser,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<IUser | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findById, { data, isError, isLoading }] = useLazyUserByIdQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findById(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as IUser, isLoading, slug as string, findById];
}


export function useAdminFromLocation(): [
  IUser,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<IUser | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] = useLazyAdminBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      // console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as IUser, isLoading, slug as string, findBySlug];
}
