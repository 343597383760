/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/Utils";
import { Voucher, VoucherFormData } from "../../../../utils/api/vouchers/vouchers.type";
import { useAddOrEditVoucherMutation, useDeleteVoucherMutation } from "../../../../utils/api/vouchers/vouchers.api";

function UseCrudVoucherForm(fn: () => void, item?: Voucher) {
  const validationSchema = yup.object().shape({
    prix: yup
      .number()
      .transform((val: any) => (isNaN(val) ? null : val))
      .required()
      .label("Le prix")
      .nullable(),
    points: yup
      .number()
      .transform((val: any) => (isNaN(val) ? null : val))
      .required()
      .label("Le nombre point")
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
  } = useForm<VoucherFormData>({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit"
  });
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditVoucherMutation();

  useEffect(() => {
    if (item?.id) {
      const fields: (keyof VoucherFormData)[] = ["slug", "points","prix"];
      for (let field of fields) {
        register(field);
        setValue(field, item[field]);
      }
    }
  }, [item]);
  const handleReset = () => {
    if (!item) {
      reset();
    }
    fn();
  };
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: item
          ? "Le voucher a été modifié avec succès !"
          : "Le voucher a été ajouté avec succès !",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        handleReset();
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: VoucherFormData) => {
    console.log("data", data);
    await sendData(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
  };
}

export default UseCrudVoucherForm;
export function UseDeleteVoucher(slug: string) {
  const [sendData] = useDeleteVoucherMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce voucher?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le voucher a été supprimé avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}