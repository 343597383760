import React from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { NavLink } from "react-router-dom";

function GoBackWrapper({link}: {link: string}) {
    return (
        <div className="p-3 mb-4 go_back_wrapper bg_colgate">
            <NavLink
                role="button"
                to={`${link}`}
                className={`d-flex align-items-center`} 
            >
                <span><MdKeyboardArrowLeft size={25} /></span>
                Retour
            </NavLink>
        </div>
    )
}

export default GoBackWrapper;