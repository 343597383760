/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
  ConfigPoint,
  ConfigPointFormData,
} from "../../../../utils/api/config/config.type";
import { useAddOrEditConfigPointMutation } from "../../../../utils/api/config/config.api";
import { cleannerError } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";

function UseCrudConfigPointForm(config?: ConfigPoint) {
  const validationSchema = yup.object().shape({
    points: yup
      .number()
      .transform((val: any) => (isNaN(val) ? null : val))
      .required()
      .label("Le nombre point")
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<ConfigPointFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [addOrUpdateConfigPoint, { isLoading, isSuccess, error, isError }] =
    useAddOrEditConfigPointMutation();
  const [prix, setPrix] = React.useState<any>(1);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `La valeur a été ${
          config ? "modifiée" : "ajoutée"
        } avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (config?.id) {
      const fields: (keyof ConfigPointFormData)[] = [
        "slug",
        "points",
        "prix",
        "is_active",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, config[field]);
      }
      setPrix(config?.prix);
    }
  }, [config]);

  const onSubmit = async (data: ConfigPointFormData) => {
    if (!config) {
      data["prix"] = prix;
      data["is_active"] = true;
    }
    console.log("data", data);
    await addOrUpdateConfigPoint(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    prix,
  };
}

export default UseCrudConfigPointForm;
