/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Modal from "react-modal";
import { CloseModalBtnV2 } from "../../../shared/CloseModalBtn";
import { Input } from "../../../common/Input";
import UseSharePointForm from "../form/UseSharePointForm";
import { IUser } from "../../../../utils/api/user/user.type";

const SharePointModal = ({
  modalSharePoint,
  setModalSharePoint,
  item,
}: {
  modalSharePoint: boolean;
  setModalSharePoint: any;
  item: IUser;
}) => {
  function closeModal() {
    setModalSharePoint(false);
  }
  const { register, errors, onSubmit, isLoading, handleReset } =
    UseSharePointForm(item, closeModal);

  return (
    <Modal
      isOpen={modalSharePoint}
      onRequestClose={handleReset}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-header pe-2 py-1">
        <h5 className="modal-title" id="CustomAddModalLabel">
          Partage de point
        </h5>
        <CloseModalBtnV2
          onClick={() => {
            handleReset();
          }}
        />
      </div>
      <div>
        <div className="row mt-4">
          <form onSubmit={onSubmit}>
            <div className="row row-add-user my-3">
              <div className="col-md-12 mb-3">
                <Input
                  type="number"
                  label="Nombre de points à partager"
                  id="__share_points"
                  min={1}
                  required
                  {...register("points")}
                  error={errors?.points}
                  placeholder="Nombre de points à partager"
                />
              </div>
            </div>
            <div className="flex-r btn__wrapper text-end pt-5">
              <button
                type="button"
                className="btn custom_close__btn"
                disabled={isLoading}
                onClick={() => handleReset()}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn custom_submit__btn"
                disabled={isLoading}
              >
                {!isLoading ? (
                  "Envoyer"
                ) : (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>Envoie...</span>
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default SharePointModal;

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    borderRadius: "15px",
    zIndex: 9999,
  },
};
