import React, { useEffect } from "react";
import { InputPoints } from "../../../common/Input";
import { BtnSubmit } from "../../../common/Button";
import { useGetConfigPointQuery } from "../../../../utils/api/config/config.api";
import Skeleton from "react-loading-skeleton";
import UseCrudConfigPointForm from "../form/UseCrudConfigPointForm";
import { formatCurrency } from "../../../../utils/Utils";

function Points() {
  const { data = [], isLoading: loading, refetch } = useGetConfigPointQuery();
  const { register, errors, onSubmit, isLoading, prix } =
    UseCrudConfigPointForm(data[0]);

  useEffect(() => {
    if (!loading) refetch();
  }, []);
  return (
    <div className="masoul-parametre-admin-cgu">
      <form onSubmit={onSubmit}>
        <div className="row py-3">
          {!!loading && (
            <div className="col-lg-12 mb-3">
              <div className="d-flex align-items-center gap-3">
                <Skeleton width={105} height={30} />
                <Skeleton
                  width={200}
                  className="form-control form-control-modal-custom"
                />
                <Skeleton
                  className={`btn auth-submit-btn mb-0 mt-3`}
                  width={200}
                />
              </div>
            </div>
          )}
          {!loading && (
            <div className="col-lg-12 mb-3">
              <div className="d-flex align-items-center gap-3">
                <span>{formatCurrency(prix)} =</span>

                <InputPoints
                  type="number"
                  id="point"
                  min={1}
                  placeholder="Nombre de points"
                  customClassContainer={"mb-0"}
                  {...register("points")}
                  error={errors?.points}
                />

                <BtnSubmit label="Enregistrer" isLoading={isLoading} />
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default Points;
