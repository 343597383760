import React, { useEffect, useState } from "react";
import rocket from "../../../../../assets/icons/rocket.png";
import receipt from "../../../../../assets/icons/receipt.png";
import box from "../../../../../assets/icons/package.png";
import SellersCommandTable, {
  PropsType,
} from "../../../Tables/SellersCommandTable";
import {
  useGetOrderListSellerQuery,
  useGetStatSellerQuery,
} from "../../../../../utils/api/order/order.api";
import { IUser } from "../../../../../utils/api/user/user.type";
import { formatCurrency } from "../../../../../utils/Utils";
import Skeleton from "react-loading-skeleton";

const SkeletonData = [
  {
    id: 1,
    bg: "#EAF6FE",
  },
  {
    id: 2,
    bg: "#FFF3EB",
  },
  {
    id: 3,
    bg: "#EAF7E9",
  },
];

const CardSkeleton = () => {
  return (
    <>
      {SkeletonData?.map((el) => (
        <div className="col-md-4 mb-3 custom__wrapper" key={el?.id}>
          <div className="activity_card" style={{ backgroundColor: el?.bg }}>
            <div className="d-flex align-items-center gap-3 custom__wrapper">
              <div className="icon__wrapper">
                <Skeleton width={32} height={32} />
              </div>
              <div className="label_and_counter">
                <Skeleton className="m-0 pb-1 counter" width={50} />
                <Skeleton className="m-0 label" width={150} height={15} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const ActivityCard = ({
  icon,
  label,
  counter,
  bg,
}: {
  icon: string;
  label: string;
  counter: number | string;
  bg: string;
}) => {
  return (
    <div className="activity_card" style={{ backgroundColor: bg }}>
      <div className="d-flex align-items-center gap-3 custom__wrapper">
        <div className="icon__wrapper">
          <img src={icon} alt="icon" />
        </div>
        <div className="label_and_counter">
          <p className="m-0 pb-1 counter">{counter}</p>
          <p className="m-0 label">{label}</p>
        </div>
      </div>
    </div>
  );
};

const ActivitiesItems = ({ item }: { item: IUser }) => {
  const {
    data = { nbre_orders: 0, nbre_produits: 0, total_mtt: 0 },
    isLoading,
    refetch,
  } = useGetStatSellerQuery(item?.slug);

  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
  }, []);
  return (
    <div className="row activity_row">
      {!!isLoading && <CardSkeleton />}
      {!isLoading && (
        <>
          <div className="col-md-4 mb-3 custom__wrapper">
            <ActivityCard
              label="Nombre de commandes"
              counter={data?.nbre_orders}
              icon={rocket}
              bg="#EAF6FE"
            />
          </div>
          <div className="col-md-4 mb-3 custom__wrapper">
            <ActivityCard
              label="Nombre de produits"
              counter={data?.nbre_produits}
              icon={receipt}
              bg="#FFF3EB"
            />
          </div>
          <div className="col-md-4 mb-3 custom__wrapper">
            <ActivityCard
              label="Montant gains"
              counter={formatCurrency(data?.total_mtt)}
              icon={box}
              bg="#EAF7E9"
            />
          </div>
        </>
      )}
    </div>
  );
};

function Activities({ go, item,setOrder }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const {
    data = { results: [], count: 0 },
    isLoading,
    refetch,
  } = useGetOrderListSellerQuery({
    slug: item?.slug,
    page,
    limit,
  });
  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
  }, []);
  const props: PropsType = {
    data,
    page,
    limit,
    setPage,
    setLimit,
    isLoading,
    go,
    setOrder,
  };
  return (
    <div className="custom_step__content">
      <div className="activities mb-1">
        <ActivitiesItems item={item} />
      </div>
      <div className="commands_history">
        <div className="title__box">
          <h4 className="m-0 custom_title">Historique des commandes</h4>
        </div>
        <div className="seller_command_table__wrapper pt-3">
          <SellersCommandTable {...props} />
        </div>
      </div>
    </div>
  );
}

export default Activities;
