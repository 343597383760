import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Product, ProductFormData } from "./product.type";

export const ProductApi = createApi({
  reducerPath: "productApi",
  tagTypes: ["product", "productList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getProductList: builder.query<PaginationResults<Product>, TypeQuery>({
      providesTags: ["productList"],
      query: (query) => QueryUrl(`produits/`, query),
    }),

    addOrEditProduct: builder.mutation<
      Product,
      { slug?: string; data: ProductFormData | FormData }
    >({
      invalidatesTags: ["productList", "product"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `produits/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `produits/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteProduct: builder.mutation<Product, string>({
      query: (id) => ({
        url: `produits/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["product", "productList"],
    }),
    productById: builder.query<Product, string>({
      query: (id) => `produits/${id}/`,
      providesTags: ["product"],
    }),
    deleteImageProduct: builder.mutation<Product, string>({
      query: (id) => ({
        url: `images/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["product", "productList"],
    }),
  }),
});

export const {
  useAddOrEditProductMutation,
  useDeleteProductMutation,
  useGetProductListQuery,
  useLazyProductByIdQuery,
  useDeleteImageProductMutation
} = ProductApi;

export function useProductFromLocation(): [
  Product,
  boolean,
  string,
  (slug: string) => void
] {
  const itemState = useLocationState<Product | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findById, { data, isError, isLoading }] = useLazyProductByIdQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findById(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return [item as Product, isLoading, slug as string, findById];
}
