/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAllCategoryListQuery } from "../../../../utils/api/category/category.api";
import {
  useAddOrEditProductMutation,
  useDeleteImageProductMutation,
  useDeleteProductMutation,
} from "../../../../utils/api/product/product.api";
import {
  Product,
  ProductFormData,
} from "../../../../utils/api/product/product.type";
import { useGetAllSellerQuery } from "../../../../utils/api/user/user.api";
import { Color } from "../../../../utils/theme";
import { cleannerError, getName } from "../../../../utils/Utils";

function UseCrudProductForm(product?: Product) {
  const validationSchema = yup.object().shape({
    hasEdit: yup.boolean(),
    nom: yup.string().required().label("Le nom"),
    description: yup.string().required().label("La description"),
    nom_arb: yup.string().label("Le nom en arabe"),
    description_arb: yup.string().label("La description en arabe"),
    nom_fr: yup.string().label("Le nom en français"),
    description_fr: yup.string().label("La description en français"),
    prix: yup.string().required().label("Le prix"),
    // quantite: yup
    //   .number()
    //   .transform((val: any) => (isNaN(val) ? null : val))
    //   .required()
    //   .label("La quantité")
    //   .nullable(),
    categorie: yup.string().required().label("La catégorie").nullable(),
    vendeur: yup.string().required().label("Le vendeur").nullable(),
    images: yup.mixed().when("hasEdit", {
      is: false,
      then: () =>
        yup
          .mixed()
          .required("Veuillez uploader au moins une image")
          .label("Image")
          .nullable(),
    }),
    type: yup.string().required().label("Le type de stock").default("quantite"),
    max_price: yup
      .number()
      .when("type", {
        is: (val: string) => val === "max_price",
        then: () =>
          yup
            .number()
            .transform((val: any) => (isNaN(val) ? null : val))
            .required()
            .nonNullable()
            .label("Le montant maximum offert"),
      })
      .transform((val: any) => (isNaN(val) ? null : val))
      .label("Le montant maximum offert")
      .nullable(),
    quantite: yup
      .number()
      .when("type", {
        is: (val: string) => val === "quantite",
        then: () =>
          yup
            .number()
            .transform((val: any) => (isNaN(val) ? null : val))
            .required()
            .nonNullable()
            .label("La quantité"),
      })
      .transform((val: any) => (isNaN(val) ? null : val))
      .label("La quantité")
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    resetField,
    setError,
  } = useForm<ProductFormData>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditProductMutation();
  const navigate = useNavigate();
  const { data: sellers = [] } = useGetAllSellerQuery();
  const { data } = useAllCategoryListQuery();
  const [description, setDescription] = useState("");
  const [type, setType] = useState<"quantite" | "max_price">("quantite");
  const [images, setImages] = useState<any>([]);
  const [option, setOption] = useState<any>(null);
  const [options, setOptions] = useState<any>([]);
  const [optionSeller, setOptionSeller] = useState<any>(null);
  const [optionsSeller, setOptionsSeller] = useState<any>([]);

  useEffect(() => {
    if (sellers?.length) {
      setOptionsSeller(
        sellers?.map((item) => {
          return {
            label: getName(item),
            value: item?.id,
          };
        })
      );
    }
  }, [sellers]);

  useEffect(() => {
    if (images?.length) {
      setValue(
        "images",
        images?.filter((s) => !s?.id)
      );
    } else {
      resetField("images");
    }
  }, [images]);

  useEffect(() => {
    if (data?.length) {
      setOptions(
        data?.map((item) => {
          return {
            label: item?.nom,
            value: item?.id,
          };
        })
      );
    }
  }, [data]);

  const onChangeDescription = (value) => {
    setValue("description", value);
    setDescription(value);
  };
  const handleSelected = (item) => {
    setValue("categorie", item?.value);
    setOption(item);
  };

  const handleSelectedSeller = (item) => {
    setValue("vendeur", item?.value);
    setOptionSeller(item);
  };

  const handleChangeType = (e: ChangeEvent<HTMLSelectElement>) => {
    let value: any = e.target.value;
    setValue("type", value);
    setType(value);
  };

  useEffect(() => {
    if (product?.id) {
      setValue("hasEdit", true);
      const fields: (keyof ProductFormData)[] = [
        "nom",
        "categorie",
        "description",
        "prix",
        "vendeur",
        "quantite",
        "discount",
        "points",
        "nom_arb",
        "description_arb",
        "nom_fr",
        "description_fr",
        "max_price",
        "type",
      ];
      for (let field of fields) {
        register(field);
        if (field !== "categorie" && field !== "vendeur") {
          if (product[field]) setValue(field, product[field]);
        } else {
          setValue(field, product[field]?.id);
        }
      }

      if (product?.type === "discount") {
        setType("max_price");
      } else {
        setType("quantite");
      }

      if (product?.description) {
        setDescription(product?.description);
      }

      if (product?.categorie) {
        setOption({
          value: product?.categorie?.id,
          label: product?.categorie?.nom,
        });
      }
      if (product?.vendeur) {
        setOptionSeller({
          value: product?.vendeur?.id,
          label: getName(product?.vendeur),
        });
      }
      if (product?.images?.length) {
        setImages(product?.images);
      } else {
        setValue("hasEdit", false);
        setImages([]);
      }
    } else {
      setValue("hasEdit", false);
    }
  }, [product]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: product
          ? "Le produit a été modifié avec succès !"
          : "Le produit a été ajouté avec succès !",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        navigate("/admin/produits");
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, isLoading]);

  useEffect(() => {
    // console.log("errors", errors);
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: any) => {
    if (data?.type === "max_price") {
      if (data?.prix && data?.max_price) {
        if (parseInt(data?.max_price) >= parseInt(data?.prix)) {
          data["quantite"] = Math.floor(
            parseInt(data?.max_price) / parseInt(data?.prix)
          );
          data["type"] = "discount";
        } else {
          return setError("max_price", {
            message:
              "Le montant maximum offert doit être supérieur ou égale au prix de l'offre",
          });
        }
      }
    } else {
      data["type"] = "stock";
    }
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "images") {
        if (data[key] && data[key]?.length) {
          data[key]?.forEach((item) => {
            fd.append(key, item);
          });
        }
      } else {
        if (data[key]) {
          fd.append(key, data[key]);
        }
      }
    }
    // console.log("data", data);
    await sendData({ slug: product?.slug, data: fd });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    images,
    option,
    options,
    handleSelected,
    control,
    Controller,
    setImages,
    description,
    onChangeDescription,
    handleSelectedSeller,
    optionSeller,
    optionsSeller,
    handleChangeType,
    type,
  };
}

export default UseCrudProductForm;

export function UseDeleteProduct(slug: string) {
  const [deleteData] = useDeleteProductMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet offre ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      // console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `L'offre a été supprimée avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}

export function UseToggleProduct(item: Product) {
  const [sendData] = useAddOrEditProductMutation();
  const onToggle = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.status === "published" ? "désactiver" : "activer"
      } cet offre?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData({
          slug: item?.slug,
          data: {
            status: item?.status === "published" ? "drafted" : "published",
          },
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `L'offre a été ${
              item?.status === "published" ? "désactivée" : "activée"
            } avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onToggle;
}

export function UseDeleteImageProduct() {
  const [deleteData] = useDeleteImageProductMutation();
  const onDelete = async (slug: string) => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet image ?`,
      text: "Cet action supprime l'image définitivement.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `L'image a été supprimée avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
