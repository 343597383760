import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Condition, ConditionFormData } from "./condition.type";

export const ConditionApi = createApi({
  reducerPath: "conditionApi",
  tagTypes: ["condition", "conditionList", "cgu", "pc", "accueil"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListCondition: builder.query<PaginationResults<Condition>, TypeQuery>({
      providesTags: ["conditionList"],
      query: (query) => QueryUrl("conditions/", query),
    }),
    getCGU: builder.query<Condition[], void>({
      providesTags: ["cgu"],
      query: () => "cgu/",
    }),
    getPC: builder.query<Condition[], void>({
      providesTags: ["pc"],
      query: () => "pc/",
    }),
    getAccueil: builder.query<Condition[], void>({
      providesTags: ["accueil"],
      query: () => "accueil/",
    }),
    addOrEditCondition: builder.mutation<Condition, ConditionFormData>({
      invalidatesTags: ["conditionList", "cgu", "pc", "accueil"],
      query: ({ slug, ...data }) => {
        if (slug) {
          return {
            url: `conditions/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `conditions/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteCondition: builder.mutation<Condition, string>({
      query: (slug) => ({
        url: `conditions/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["conditionList", "cgu", "pc", "accueil"],
    }),
  }),
});

export const {
  useAddOrEditConditionMutation,
  useDeleteConditionMutation,
  useGetCGUQuery,
  useGetListConditionQuery,
  useGetAccueilQuery,
  useGetPCQuery
} = ConditionApi;
