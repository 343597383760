import React from "react";
import { useGetAccueilQuery } from "../../../../utils/api/condition/condition.api";
import { BtnAction, ConditionSkeleton } from "./ConditionsUtilisation";
import { AlertInfo } from "../../../common/Alert";
import { createMarkup } from "../../../../utils/Utils";

function MessageAccueil() {
  const { data = [], isLoading } = useGetAccueilQuery();
  return (
    <div className="masoul-parametre-admin-cgu">
      {!isLoading && !data?.length ? (
        <div className="d-flex justify-content-end mb-3">
          <BtnAction item={data[0]} type="ACCUEIL" />
        </div>
      ) : null}
      {!!isLoading && <ConditionSkeleton />}
      {!isLoading &&
        (!data?.length ? (
          <div>
            <AlertInfo message="Aucun contenu trouvé" />
          </div>
        ) : (
          <>
            <p
              className="mb-3"
              dangerouslySetInnerHTML={createMarkup(data[0]?.text)}
            />
            <p
              dangerouslySetInnerHTML={createMarkup(data[0]?.text_arb || "")}
              style={{ direction: "rtl" }}
              className="mb-3"
            />
            <p dangerouslySetInnerHTML={createMarkup(data[0]?.text_fr || "")} />
          </>
        ))}

      {!isLoading && data?.length ? (
        <div className="d-flex justify-content-end py-5">
          <BtnAction item={data[0]} type="ACCUEIL" />
        </div>
      ) : null}
    </div>
  );
}

export default MessageAccueil;
