import React from "react";
import { Navigate } from "react-router-dom";
import DashboardAdmin from "../components/admin/DashboardAdmin/DashboardAdmin";
import Login from "../components/auth/Login";
import ForgetPassword from "../components/auth/ForgetPassword";
import ResetPassword from "../components/auth/ResetPassword";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import PagesError404 from "../components/shared/PagesError/PagesError404";
import ListUsers from "../components/admin/ListUsers/ListUsers";
import ProfilAdmin from "../components/admin/ProfilAdmin/ProfilAdmin";
import Parametres from "../components/admin/Parametres/Parametres";
import SellersAdmin from "../components/admin/SellersAdmin/SellersAdmin";
import SellerDetails from "../components/admin/SellersAdmin/SellerDetails/SellerDetails";
import ProjetsAdmin from "../components/admin/ProjetsAdmin/ProjetsAdmin";
import VouchersAdmin from "../components/admin/Vouchers/VouchersAdmin";
import CommandAdmin from "../components/admin/CommandAdmin/CommandAdmin";
import CommandDetails from "../components/admin/CommandAdmin/CommandDetails";
import ProductAdmin from "../components/admin/ProductAdmin/ProductAdmin";
import ProductDetails from "../components/admin/ProductAdmin/ProductDetails";
import AddOrUpdateProduct from "../components/admin/ProductAdmin/AddOrUpdateProduct";
import UserDetails from "../components/admin/ListUsers/UserDetails/UserDetails";
import { IsAdminAllow, RedirectAuthRoute } from "./routerUtils";
import DetailProjectAdmin from "../components/admin/ProjetsAdmin/DetailProjectAdmin";
import AddOrEditProject from "../components/admin/ProjetsAdmin/AddOrEditProject";
import Cgu from "../components/use/Cgu";
import Policy from "../components/use/Policy";
import ReclamationAdmin from "../components/admin/Reclamation/ReclamationAdmin";
import ReclamationAdminDetails from "../components/admin/Reclamation/ReclamationAdminDetails";

// UserRoutes
export const AppRoutes = [
  {
    path: "/",
    element: (
      <RedirectAuthRoute>
        <Login />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/mot-de-passe-oublie",
    element: (
      <RedirectAuthRoute>
        <ForgetPassword />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/reinitialiser-mot-de-passe",
    element: (
      <RedirectAuthRoute>
        <ResetPassword />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/conditions-generales-d-utilisation",
    element: <Cgu />,
  },
  {
    path: "/politique-de-confidentialite",
    element: <Policy />,
  },
  {
    path: "*",
    element: <PagesError404 />,
  },
  {
    path: "admin/",
    element: (
      <IsAdminAllow>
        <AdminLayout />
      </IsAdminAllow>
    ),
    children: [
      { element: <Navigate to="/admin/dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: <DashboardAdmin />,
      },
      {
        path: "utilisateurs",
        element: <ListUsers />,
      },
      {
        path: "profil",
        element: <ProfilAdmin />,
      },
      {
        path: "parametres",
        element: <Parametres />,
      },
      {
        path: "utilisateurs/detail/:slug",
        element: <UserDetails />,
      },
      {
        path: "vendeurs",
        element: <SellersAdmin />,
      },
      {
        path: "vendeurs/detail/:slug",
        element: <SellerDetails />,
      },
      {
        path: "projets",
        element: <ProjetsAdmin />,
      },
      {
        path: "projets/details/:slug",
        element: <DetailProjectAdmin />,
      },
      {
        path: "projets/ajouter",
        element: <AddOrEditProject />,
      },
      {
        path: "projets/modifier/:slug",
        element: <AddOrEditProject />,
      },
      {
        path: "vouchers",
        element: <VouchersAdmin />,
      },
      {
        path: "commandes",
        element: <CommandAdmin />,
      },
      {
        path: "commandes/detail/:slug",
        element: <CommandDetails />,
      },
      {
        path: "réclamations",
        element: <ReclamationAdmin />
      },
      {
        path: "réclamations/:slug",
        element: <ReclamationAdminDetails />
      },
      {
        path: "offres",
        element: <ProductAdmin />,
      },
      {
        path: "offres/detail/:slug",
        element: <ProductDetails />,
      },
      {
        path: "offres/ajouter",
        element: <AddOrUpdateProduct />,
      },
      {
        path: "offres/modifier/:slug",
        element: <AddOrUpdateProduct />,
      },
    ],
  },
];
