import { Navigate } from "react-router-dom";
import { IUser } from "../utils/api/user/user.type";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { ReactElement } from "react";
import { onlogout } from "../redux/slice/User.slice";

export const isSeller = (user: IUser) => {
  return user?.user_type === "vendeur";
};

export const isUser = (user: IUser) => {
  return user?.user_type === "user";
};

export const isVisitor = (user: IUser) => {
  return user?.user_type === "visiteur";
};

export const isSuperAdmin = (user: IUser) => {
  return user?.user_type === "superadmin";
};

export const isAdmin = (user: IUser) => {
  return user?.user_type === "admin" || user?.user_type === "superadmin";
};

export const IsAdminAllow = ({ children }: { children: ReactElement }) => {
  const { user, token } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  let path: string = "/";
  if (token) {
    if (!isAdmin(user)) {
      dispatch(onlogout());
    }
  }
  return !token || !isAdmin(user) ? <Navigate to={path} replace /> : children;
};

export const RedirectAuthRoute = ({ children }: { children: ReactElement }) => {
  const { token, user } = useAppSelector((state) => state.user);
  let path: string = "/";
  if (isAdmin(user)) {
    path = "/admin/dashboard";
  }

  return !token && !user?.id ? children : <Navigate to={path} replace />;
};

