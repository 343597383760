/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal, { SweetAlertResult } from "sweetalert2";
import { IUser, UserFormData } from "../../../../utils/api/user/user.type";
import {
  useAddOrEditSellerMutation,
  useDeleteUserMutation,
  useDisabledUserMutation,
  useEnabledUserMutation,
} from "../../../../utils/api/user/user.api";
import { Color } from "../../../../utils/theme";
import { cleannerError, validatePhone } from "../../../../utils/Utils";

function UseCrudSellerForm(fn: () => void, seller?: IUser) {
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string | null>(null);
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Le nom"),
    prenom: yup.string().required().label("Le prénom"),
    phone: validatePhone(yup, code).required(),
    email: yup.string().email().required().label("L'email"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [avatar, setAvatar] = useState<File | null>(null);
  const [addOrUpdateUser, { isLoading, isSuccess, error, isError }] =
    useAddOrEditSellerMutation();

  const handleChangePhone = (item: any, country: any) => {
    setPhone(item);
    setCode(country?.countryCode);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("phone", item);
    } else {
      setValue("phone", "");
    }
  };

  const handleChangeAvatar = (e) => {
    let file = e?.target?.files[0];
    setAvatar(file);
    setValue("avatar", file);
  };

  const handleClose = () => {
    if (!seller) {
      setPhone("+221");
      reset();
    }
    setAvatar(null);
    fn();
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: !seller
          ? `Le vendeur a été ajouté avec succès!`
          : `Les informations du vendeur ont été modifiées avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        handleClose();
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet email exite déjà!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Erreur de statut ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (seller?.id) {
      // console.log("user", user);
      let fields: (keyof UserFormData)[] = ["nom", "prenom", "phone", "email"];

      if (seller?.phone) {
        setPhone(seller.phone);
      }

      for (let field of fields) {
        register(field);

        setValue(field, seller[field]);
      }
    }
  }, [seller]);

  const onSubmit = async (data: UserFormData) => {
    const fd = new FormData();

    for (let key of Object.keys(data)) {
      if (key === "avatar") {
        if (data[key]) {
          fd.append(key, data[key] as Blob);
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    await addOrUpdateUser({
      slug: seller?.slug,
      data: fd,
    });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangePhone,
    Controller,
    phone,
    handleClose,
    handleChangeAvatar,
    avatar,
  };
}

export default UseCrudSellerForm;
export function UseToggleUser(item: IUser) {
  const [sendData] = useAddOrEditSellerMutation();
  const onToggle = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.is_archive ? "désarchiver" : "archiver"
      } ce compte?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData({
          slug: item?.slug,
          data: { is_archive: !item?.is_archive },
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: SweetAlertResult) => {
      // console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le compte a été ${
              item?.is_archive ? "désarchivé" : "archivé"
            } avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onToggle;
}

export function UseBlockUser(item: IUser) {
  const [blockUser] = useDisabledUserMutation();
  const [deblockUser] = useEnabledUserMutation();
  const onBlock = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.bloquer ? "débloquer" : "bloquer"
      } ce compte?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return !item?.bloquer ? blockUser(item?.slug) : deblockUser(item?.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: SweetAlertResult) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le compte a été ${
              item?.bloquer ? "débloqué" : "bloqué"
            } avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onBlock;
}

export function UseDeleteUser(slug: string) {
  const [sendData] = useDeleteUserMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce compte?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: SweetAlertResult) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le compte a été supprimé avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
