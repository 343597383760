/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { IUser, Share } from "../../../../utils/api/user/user.type";
import {
  useSharePoindMutation,
} from "../../../../utils/api/user/user.api";
import { Color } from "../../../../utils/theme";
import { cleannerError, useUser } from "../../../../utils/Utils";

function UseSharePointForm(item: IUser, fn: () => void) {
  const user = useUser();

  const validationSchema = yup.object().shape({
    points: yup
      .number()
      .transform((val: any) => (isNaN(val) ? null : val))
      .required()
      .label("Le nombre point")
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    resetField,
  } = useForm<Share>({
    resolver: yupResolver(validationSchema),
  });
  const [addOrUpdateConfigPoint, { isLoading, isSuccess, error, isError }] =
    useSharePoindMutation();

  const handleReset = () => {
    resetField("points");
    fn();
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Le partage a été effectué avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => handleReset());
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: Share) => {
    data["sender"] = user?.id;
    data["receiver"] = item?.id;
    console.log("data", data);
    await addOrUpdateConfigPoint(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
  };
}

export default UseSharePointForm;
