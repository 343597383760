import React, { useState } from 'react';
import ProductsTable from '../../Tables/ProductsTable';
import { IconSearch } from '../../../shared/Icons';

function ProductTab() {
  const [query, setQuery] = useState("")
  return (
    <div className="products_tab__content">
      <div className="search-user-box position-relative mt-4">
        <span className="icon-span-search-svg">
          <IconSearch/>
        </span>
        <input
          type="search"
          name="search_command"
          id="__search_command"
          placeholder="Recherchez par vendeur, offre, etc..."
          onChange={(e) => setQuery(e?.target?.value)}
        />
      </div>

      <div className="custom-table mt-5">
        <ProductsTable q={query} />
      </div>
    </div>
  );
}

export default ProductTab