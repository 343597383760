/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import { useStepType } from "../../../utils/type";
import { useStep } from "react-hooks-helper";
import "./ProfilAdmin.css";
import InfosPersonnellesTab from "./Tabs/InfosPersonnellesTab";
import PasswordTab from "./Tabs/PasswordTab";
import { BsCameraFill } from "react-icons/bs";
import { getAvatar, getName, showModal, useUser } from "../../../utils/Utils";
import { useAddOrEditUserMutation } from "../../../utils/api/user/user.api";
import Swal from "sweetalert2";

const steps = [
  { id: "infos-personnelles", Component: InfosPersonnellesTab },
  { id: "mot-de-passe", Component: PasswordTab },
];

const tabs = ["Informations personnelles", "Changer de mot de passe"];

function ProfilAdmin() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [avatar, setAvatar] = useState<File | null>(null);
  const [sendData, { isLoading, isSuccess, isError, error }] =
    useAddOrEditUserMutation();
  // const [load, setLoad] = useState(false);
  const user = useUser();

  const handleChangeAvatar = (e) => {
    let file = e?.target?.files[0];
    setAvatar(file);

    let fd = new FormData();
    fd.append("avatar", file);
    sendData({ slug: user?.slug, data: fd });
  };

  useEffect(() => {
    if (isSuccess) {
      setAvatar(null);
    }

    if (isError) {
      const err = error as any;
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isSuccess, isError]);
  return (
    <>
      <WrapperComponent>
        <div className="container-title-dash d-flex align-items-center gap-4">
          <div className="custom-sm-top">
            <h2 className="custom-sm-top-title mb-0">Profil</h2>
          </div>
        </div>
      </WrapperComponent>
      <WrapperComponent customClass={"pb-md-0"}>
        {index === 0 ? (
          <div className="d-flex justify-content-end">
            <button
              className="btn admin-masoul-btn-edit"
              onClick={() => showModal("changeInfosPerso")}
            >
              Modifier
            </button>
          </div>
        ) : null}
        <div className="content-pp-profil-visiteur text-center mb-3">
          <div className="d-flex justify-content-center align-items-center mb-4 masoul-user-avatar-container">
            <label htmlFor="input-test" className="input-testt">
              <img
                src={
                  avatar ? URL.createObjectURL(avatar) : getAvatar(user?.avatar)
                }
                alt={getName(user)}
                className="pp-profil-user-visiteur"
              />
              <div className="masoul-user-avatar-edit-hover">
                {isLoading ? (
                  <span
                    className="spinner-border spinner-border-sm custom-spinner text-dark me-1 d-inline-block"
                    role="status"
                  ></span>
                ) : (
                  <BsCameraFill className="edit-avatar-camera-icon" />
                )}
              </div>
            </label>
            <input
              type="file"
              className="input-add-avatar"
              id="input-test"
              accept="image/*"
              onChange={handleChangeAvatar}
            />
          </div>
          <div className="content-text-name-user">
            <h2 className="custom-name-user-admin">{getName(user)}</h2>
            <span className="custom-role-user-admin">Admin</span>
          </div>
        </div>
        <div className="masoul-tabs-container py-3 pb-md-0">
          <ul className="tabs-container custom-tabs-container pb-md-0 mb-md-0">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>
      </WrapperComponent>
      <WrapperComponent>
        <div className="tabs-component-container">
          <Component />
        </div>
      </WrapperComponent>
    </>
  );
}

export default ProfilAdmin;
