import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Project, ProjectFormData } from "./project.type";

export const ProjectApi = createApi({
  reducerPath: "projectApi",
  tagTypes: ["project", "projectList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getProjectList: builder.query<PaginationResults<Project>, TypeQuery>({
      providesTags: ["projectList"],
      query: (query) => QueryUrl(`projets/`, query),
    }),

    addOrEditProject: builder.mutation<
      Project,
      { slug?: string; data: ProjectFormData | FormData }
    >({
      invalidatesTags: ["projectList", "project"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `projets/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `projets/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteProject: builder.mutation<Project, string>({
      query: (id) => ({
        url: `projets/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["project", "projectList"],
    }),
    projectById: builder.query<Project, string>({
      query: (id) => `projets/${id}/`,
      providesTags: ["project"],
    }),
    
  }),
});

export const {
  useAddOrEditProjectMutation,
  useDeleteProjectMutation,
  useGetProjectListQuery,
  useLazyProjectByIdQuery,
} = ProjectApi;

export function useProjectFromLocation(): [
  Project,
  boolean,
  string,
  (slug: string) => void
] {
  const itemState = useLocationState<Project | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findById, { data, isError, isLoading }] = useLazyProjectByIdQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findById(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return [item as Project, isLoading, slug as string, findById];
}
