import React, { useState } from "react";
import Commands from "./Commands";
import UserCommandDetails from "./UserCommandDetails";
import { useStepType } from "../../../../../utils/type";
import { useStep } from "react-hooks-helper";
import WrapperComponent from "../../../../shared/WrapperComponent";
import { Order } from "../../../../../utils/api/order/order.type";

const steps = [
  { id: "user-all-commands", Component: Commands },
  { id: "user-command-details", Component: UserCommandDetails },
];

function UserCommands({ item }) {
  const {
    index,
    navigation: { go },
    step,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [order, setOrder] = useState<Order | undefined>()
  const props = {
    go,
    item,
    order,
    setOrder,
  };
  
  return (
    <>
      {index === 0 && (
        <WrapperComponent>
          <div className="custom_step__content">
            <Component {...props} />
          </div>
        </WrapperComponent>
      )}
      {index === 1 && (
        <div className="custom_step__content">
          <Component {...props} />
        </div>
      )}
    </>
  );
}

export default UserCommands;
