import React, { Dispatch, SetStateAction } from "react";
import Modal from "react-modal";
import { Input } from "../../../common/Input";
import { CloseModalBtnV2 } from "../../../shared/CloseModalBtn";
import UseCrudCategoryForm from "../form/UseCrudCategoryForm";
import { Category } from "../../../../utils/api/category/category.type";
import { getImage } from "../../../../utils/Utils";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    borderRadius: "20px",
    zIndex: 9999,
  },
};

const AddOrUpdateCategoryModal = ({
  modalAddCategory,
  setModalAddCategory,
  item,
}: {
  modalAddCategory: boolean;
  setModalAddCategory: Dispatch<SetStateAction<boolean>>;
  item?: Category;
}) => {
  function closeModal() {
    setModalAddCategory(false);
  }
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    handleReset,
    handleChangeImage,
    image,
  } = UseCrudCategoryForm(closeModal, item);

  return (
    <Modal
      isOpen={modalAddCategory}
      onRequestClose={handleReset}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-header pe-2 py-1">
        <h5 className="modal-title" id="CustomAddModalLabel">
          {!item ? "Ajouter" : "Modifier"} une catégorie
        </h5>
        <CloseModalBtnV2 onClick={() => handleReset()} />
      </div>
      <div>
        <div className="row mt-4">
          <form onSubmit={onSubmit}>
            <div className="row row-add-Category my-3">
              <div className="col-md-12 mb-3">
                <Input
                  type="text"
                  label="Libellé de la catégorie en anglais"
                  id="__category_name"
                  placeholder="Catégorie en anglais"
                  {...register("nom")}
                  error={errors?.nom}
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <Input
                  type="text"
                  label="Libellé de la catégorie en français"
                  id="__category_name_fr"
                  placeholder="Catégorie en français"
                  {...register("nom_fr")}
                  error={errors?.nom_fr}
                  
                />
              </div>
              <div className="col-md-6 mb-3">
                <Input
                  type="text"
                  label="Libellé en arabe"
                  id="__category_name_ar"
                  placeholder="الرجاء إدخال النص هنا..."
                  style={{ direction: "rtl" }}
                  {...register("nom_arb")}
                  error={errors?.nom_arb}
                  dir="rtl"
                />
              </div>
              <div className="col-md-12 mb-3 img-preview-container">
                <Input
                  type="file"
                  accept="image/*"
                  label="Image"
                  id="file-ip-1"
                  placeholder="Image"
                  onChange={(e) => handleChangeImage(e)}
                  error={errors?.image}
                />
                <div className="preview" id="file-preview">
                  {item || image ? (
                    <img
                      id="file-ip-1-preview"
                      src={
                        image
                          ? URL.createObjectURL(image)
                          : getImage(item?.image)
                      }
                      alt="category-image"
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex-r btn__wrapper text-end pt-5">
              <button
                type="button"
                className="btn custom_close__btn"
                disabled={isLoading}
                onClick={() => handleReset()}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="btn custom_submit__btn"
                disabled={isLoading}
              >
                {!isLoading ? (
                  "Enregistrer"
                ) : (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>En cours...</span>
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default AddOrUpdateCategoryModal;
