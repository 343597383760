import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { prepareHeaders } from "../user/user.api";
import { Notification } from "./notification.type";
import { QueryUrl } from "../../Utils";

import { NotificationFormData } from "./notification.type";

export const NotificationApi = createApi({
  reducerPath: "notification",
  tagTypes: ["notificationsList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    notificationsList: builder.query<
      PaginationResults<Notification>,
      TypeQuery
    >({
      query: ({slug,...query}) => QueryUrl(`admin/${slug}/notifications/`, query),
      providesTags: ["notificationsList"],
    }),
    updateNotificationRead: builder.mutation<
      Notification,
      NotificationFormData
    >({
      query: ({ slug, ...data }) => ({
        url: `notifications/${slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["notificationsList"],
    }),
  }),
});

export const { useUpdateNotificationReadMutation, useNotificationsListQuery } =
  NotificationApi;
