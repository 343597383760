/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import LineChart from "../LineChat/LineChart";
import {
  IconBag1,
  IconBag2,
  IconUser1,
  IconUser2,
  IconVoucher,
  IconVoucher1,
  IconWallet1,
  IconWallet2,
} from "../../../shared/Icons";
import { useGetDashboardQuery } from "../../../../utils/api/dashboard/dashboard.api";
import { Dashboard } from "../../../../utils/api/dashboard/dashboard.type";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { formatCurrency } from "../../../../utils/Utils";

const CartSkeleton = () => {
  return (
    <>
      {[...Array(9)]?.map((el, i) => (
        <div className="col-lg-4 col-md-6 mb-3 dis-flex" key={`${el}${i}`}>
          <div className="item-stat-dashboad-view bl-color0">
            <div className="content-stat-dashboard-view">
              <div className="content-text-item-stat-dashboad-view">
                <div className="icon-item-stat-dashboad-view">
                  <Skeleton className="titre-item-stat-dashboad-view mb-3" />
                  <Skeleton
                    className="chiffre-item-stat-dashboad-view mb-1"
                    width={`50%`}
                  />
                </div>
              </div>
              <div className="content-item-icon-stat-dashboard-view">
                <span>
                  <Skeleton width={60} height={60} />
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const CartItems = ({
  data,
  isLoading,
}: {
  data: Dashboard;
  isLoading: boolean;
}) => {
  return (
    <div className="row mb-4">
      {!!isLoading && <CartSkeleton />}
      {!isLoading && (
        <>
          <div className="col-lg-4 col-md-6 mb-3 dis-flex">
            <div className="item-stat-dashboad-view bl-color1">
              <div className="content-stat-dashboard-view">
                <div className="content-text-item-stat-dashboad-view">
                  <div className="icon-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view mb-3">
                      Nombre d’utilisateurs
                    </p>
                    <p className="chiffre-item-stat-dashboad-view mb-1">
                      {" "}
                      {data?.nbre_users}{" "}
                    </p>
                  </div>
                </div>
                <div className="content-item-icon-stat-dashboard-view">
                  <span>
                    <IconUser1 />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-3 dis-flex">
            <div className="item-stat-dashboad-view bl-color2">
              <div className="content-stat-dashboard-view">
                <div className="content-text-item-stat-dashboad-view">
                  <div className="icon-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view mb-3">
                      Nombre de vendeurs
                    </p>
                    <p className="chiffre-item-stat-dashboad-view mb-1">
                      {data?.nbre_vendeurs}
                    </p>
                  </div>
                </div>
                <div className="content-item-icon-stat-dashboard-view">
                  <span>
                    <IconUser2 />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-3 dis-flex">
            <div className="item-stat-dashboad-view bl-color3">
              <div className="content-stat-dashboard-view">
                <div className="content-text-item-stat-dashboad-view">
                  <div className="icon-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view mb-3">
                      Nombre d'offres
                    </p>
                    <p className="chiffre-item-stat-dashboad-view mb-1">
                      {data?.nbre_produits}
                    </p>
                  </div>
                </div>
                <div className="content-item-icon-stat-dashboard-view">
                  <span>
                    <IconBag1 />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-3 dis-flex4">
            <div className="item-stat-dashboad-view bl-color4">
              <div className="content-stat-dashboard-view">
                <div className="content-text-item-stat-dashboad-view">
                  <div className="icon-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view mb-3">
                      Nombre total de projets{" "}
                    </p>
                    <p className="chiffre-item-stat-dashboad-view mb-1">
                      {data?.nbre_projets}
                    </p>
                  </div>
                </div>
                <div className="content-item-icon-stat-dashboard-view">
                  <span>
                    <IconWallet1 />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-3 dis-flex">
            <div className="item-stat-dashboad-view bl-color5">
              <div className="content-stat-dashboard-view">
                <div className="content-text-item-stat-dashboad-view">
                  <div className="icon-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view mb-3">
                      Somme totale allouée aux projets
                    </p>
                    <p className="chiffre-item-stat-dashboad-view mb-1 fs-25">
                      {formatCurrency(data?.sum_projets)}
                    </p>
                  </div>
                </div>
                <div className="content-item-icon-stat-dashboard-view">
                  <span>
                    <IconWallet2 />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-3 dis-flex">
            <div className="item-stat-dashboad-view bl-color6">
              <div className="content-stat-dashboard-view">
                <div className="content-text-item-stat-dashboad-view">
                  <div className="icon-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view mb-3">
                      Nombre de commandes
                    </p>
                    <p className="chiffre-item-stat-dashboad-view mb-1">
                      {data?.nbre_orders}
                    </p>
                  </div>
                </div>
                <div className="content-item-icon-stat-dashboard-view">
                  <span>
                    <IconBag2 />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-3 dis-flex">
            <div className="item-stat-dashboad-view bl-color6">
              <div className="content-stat-dashboard-view">
                <div className="content-text-item-stat-dashboad-view">
                  <div className="icon-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view mb-3">
                      Somme commandes
                    </p>
                    <p className="chiffre-item-stat-dashboad-view mb-1 fs-25">
                      {formatCurrency(data?.sum_order)}
                    </p>
                  </div>
                </div>
                <div className="content-item-icon-stat-dashboard-view">
                  <span>
                    <IconBag2 />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-3 dis-flex">
            <div className="item-stat-dashboad-view bl-color3">
              <div className="content-stat-dashboard-view">
                <div className="content-text-item-stat-dashboad-view">
                  <div className="icon-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view mb-3">
                      Total vouchers vendus
                    </p>
                    <p className="chiffre-item-stat-dashboad-view mb-1">
                      {data?.nbre_vouchers}
                    </p>
                  </div>
                </div>
                <div className="content-item-icon-stat-dashboard-view">
                  <span>
                    <IconVoucher />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-3 dis-flex">
            <div className="item-stat-dashboad-view bl-color4">
              <div className="content-stat-dashboard-view">
                <div className="content-text-item-stat-dashboad-view">
                  <div className="icon-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view mb-3">
                      Somme totale de vouchers vendue
                    </p>
                    <p className="chiffre-item-stat-dashboad-view mb-1 fs-25">
                      {formatCurrency(data?.sum_vouvher)}
                    </p>
                  </div>
                </div>
                <div className="content-item-icon-stat-dashboard-view">
                  <span>
                    <IconVoucher1 />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
function TabsDataToday({ filter, startDate, endDate }) {
  const [query, setQuery] = useState<any>({
    q_date: "",
    date_debut: "",
    date_fin: "",
  });
  const {
    data = {
      graph: [],
      nbre_orders: 0,
      nbre_produits: 0,
      nbre_projets: 0,
      nbre_users: 0,
      nbre_vendeurs: 0,
      nbre_vouchers: 0,
      sum_order: 0,
      sum_projets: 0,
      sum_vouvher: 0,
    },
    isLoading,
    refetch,
    isFetching,
  } = useGetDashboardQuery(query);

  useEffect(() => {
    if (filter !== "all") {
      console.log("debut", moment(startDate).format("YYYY-MM-DD"));
      console.log("fin", moment(endDate).format("YYYY-MM-DD"));
      setQuery({
        q_date: filter,
        date_debut: moment(startDate).format("YYYY-MM-DD"),
        date_fin: moment(endDate).format("YYYY-MM-DD"),
      });
    } else {
      setQuery({ q_date: filter });
    }
  }, [filter, endDate, startDate]);
  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
  }, []);
  
  return (
    <>
      <CartItems data={data} isLoading={isFetching} />
      <div className="content-graph-admin mt-5 mb-3">
        {!!isFetching && <Skeleton width={`100%`} height={`70vh`} />}
        {!isFetching && <LineChart graph={data?.graph} />}
      </div>
    </>
  );
}

export default TabsDataToday;
