import React from "react";
import GoBackWrapper from "../../shared/GoBackWrapper";
// import "../ProductAdmin/ProductAdmin.css";
import { FormError, Input, animatedComponents } from "../../common/Input";
// import MyDropzone, { ImageGrid } from "../../common/MyDropzone";
import Select from "react-select";
import UseCrudProjectForm from "./form/UseCrudProjectForm";
import { useProjectFromLocation } from "../../../utils/api/project/project.api";
import { ImageItem } from "../../common/MyDropzone";
import ReactQuill from "react-quill";

function AddOrEditProject() {
  const [item] = useProjectFromLocation();
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    options,
    option,
    control,
    Controller,
    handleSelected,
    onChangeDescription,
    onChangeFile,
    description,
    image,
    descriptionArb,
    onChangeDescriptionArb,
    descriptionFr,
    onChangeDescriptionFr
  } = UseCrudProjectForm(item);

  return (
    <>
      <GoBackWrapper link="/admin/projets" />
      <div className="form_container add_product__form">
        <form onSubmit={onSubmit}>
          <div className="row form_row">
            <div className="col-md-12 custom_wrapper">
              <Input
                type="text"
                label="Nom du projet en anglais"
                id="__project_name"
                placeholder="Nom projet en anglais"
                {...register("nom")}
                error={errors?.nom}
                required
              />
            </div>
            <div className="col-md-6 custom_wrapper">
              <Input
                type="text"
                label="Nom du projet en français"
                id="__project_name_fr"
                placeholder="Nom projet en français"
                {...register("nom_fr")}
                error={errors?.nom_fr}
              />
            </div>
            <div className="col-md-6 custom_wrapper">
              <Input
                type="text"
                label="Nom du projet en arabe"
                id="__proj_name"
                {...register("nom_arb")}
                error={errors?.nom_arb}
                placeholder="الرجاء إدخال النص هنا..."
                style={{ direction: "rtl" }}
                dir="rtl"
              />
            </div>
            <div className="col-md-6 custom_wrapper">
              <Input
                type="file"
                label="Image"
                id="image"
                accept="image/*"
                error={errors?.file}
                required
                onChange={onChangeFile}
              />
              <div className="row">
                <ImageItem image={image} />
              </div>
            </div>
            <div className="col-md-6 custom_wrapper">
              <Input
                type="url"
                label="Vidéo"
                placeholder="Lien de la video"
                id="video"
                error={errors?.video}
                {...register("video")}
              />
            </div>

            <div className="col-md-6 mb-3 custom_wrapper">
              <label
                htmlFor="category"
                className="form-label form-label-modal-custom"
              >
                Catégorie
                <span className="text-danger" style={{ fontSize: 15 }}>
                  *
                </span>
              </label>

              <Controller
                name="categorie"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      placeholder="Sélectionner une catégorie"
                      options={options}
                      classNamePrefix="react-select-custom"
                      onChange={handleSelected}
                      value={option}
                    />
                  );
                }}
              />
              {<FormError error={errors?.categorie?.message} />}
            </div>

            <div className="col-md-6 custom_wrapper">
              <Input
                type="number"
                label="Montant"
                id="__product_price"
                placeholder="Montant"
                min={0}
                step={"any"}
                {...register("montant")}
                error={errors?.montant}
                required
              />
            </div>

            <div className="col-md-12 mb-3 custom_wrapper">
              <label
                htmlFor="description"
                className="form-label form-label-modal-custom"
              >
                Description en anglais
                <span className="text-danger" style={{ fontSize: 15 }}>
                  *
                </span>
              </label>
              <ReactQuill
                value={description}
                className="product-ql-description"
                onChange={(value) => onChangeDescription(value)}
              />
              <FormError error={errors?.description?.message} />
            </div>
            <div className="col-md-12 mb-3 custom_wrapper">
              <label
                htmlFor="description_fr"
                className="form-label form-label-modal-custom"
              >
                Description en français
              </label>
              <ReactQuill
                value={descriptionFr}
                className="product-ql-description"
                onChange={(value) => onChangeDescriptionFr(value)}
              />
              <FormError error={errors?.description_fr?.message} />
            </div>
            <div className="col-md-12 mb-3 custom_wrapper">
              <label
                htmlFor="description"
                className="form-label form-label-modal-custom"
              >
                Description en arabe
              </label>
              <ReactQuill
                value={descriptionArb}
                placehokder="test"
                className="product-ql-description aribic-input"
                onChange={(value) => onChangeDescriptionArb(value)}
              />
              <FormError error={errors?.description_arb?.message} />
            </div>
          </div>
          <div className="flex-r btn__wrapper text-end pt-5">
            <button
              type="button"
              className="btn custom_close__btn"
              disabled={isLoading}
            >
              Annuler
            </button>
            <button
              type="submit"
              className="btn custom_submit__btn"
              disabled={isLoading}
            >
              {!isLoading ? (
                "Enregistrer"
              ) : (
                <>
                  <span
                    className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                    role="status"
                  ></span>
                  <span>En cours...</span>
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddOrEditProject;
