import React from "react";
import GoBackWrapper from "../../shared/GoBackWrapper";
import "./ProductAdmin.css";
import WrapperComponent from "../../shared/WrapperComponent";
import { useProductFromLocation } from "../../../utils/api/product/product.api";
import { createMarkup, formatCurrency, getImage, getName } from "../../../utils/Utils";

function ProductDetails() {
  const [item] = useProductFromLocation();
  return (
    <>
      <GoBackWrapper link="/admin/offres" />
      <WrapperComponent>
        <div className="row product_image_visual__container">
          {!!item?.images?.length &&
            item?.images?.map((el, i) => (
              <div className="col-md-3 mb-3 custom_wrapper colgate__wrapper">
                <div className="product_image__wrapper">
                  <img src={getImage(el?.picture)} alt={`image${i}`} />
                </div>
              </div>
            ))}
          {!item?.images?.length && (
            <div className="col-md-3 mb-3 custom_wrapper colgate__wrapper">
              <div className="product_image__wrapper">
                <img src={getImage(item?.couverture)} alt="produit" />
              </div>
            </div>
          )}
        </div>
      </WrapperComponent>
      <div className="row product_name_and_category__row">
        <div className="col-md-12 custom_wrapper">
          <WrapperComponent>
            <div className="card_content product_name">
              <p className="m-0 pb-3 sm_title">Nom de l'offre</p>
              <p className="m-0 sm_value mb-1">{item?.nom}</p>
              {!!item?.nom_arb && (
                <p className="m-0 sm_value mb-1" style={{ direction: "rtl" }}>
                  <span className="fw-bold">En arabe:</span> {item?.nom_arb}
                </p>
              )}
              {!!item?.nom_fr && (
                <p className="m-0 sm_value mb-1">
                  <span className="fw-bold">En français:</span> {item?.nom_fr}
                </p>
              )}
            </div>
          </WrapperComponent>
        </div>
        <div className="col-md-12 custom_wrapper">
          <WrapperComponent>
            <div className="card_content product_category">
              <p className="m-0 pb-3 sm_title">Catégorie</p>
              <p className="m-0 sm_value mb-2">{item?.categorie?.nom}</p>
              {!!item?.categorie?.nom_arb && (
                <p className="m-0 sm_value mb-1" style={{ direction: "rtl" }}>
                  <span className="fw-bold">En arabe:</span> {item?.categorie?.nom_arb}
                </p>
              )}
              {!!item?.categorie?.nom_fr && (
                <p className="m-0 sm_value mb-1">
                  <span className="fw-bold">En français:</span> {item?.categorie?.nom_fr}
                </p>
              )}
              
            </div>
          </WrapperComponent>
        </div>
      </div>
      <div className="row product_features__row">
        <div className="col-md-12 custom_wrapper">
          <WrapperComponent>
            <div className="card_content product_features">
              <p className="m-0 pb-3 sm_title">Caractéristique</p>
              <p
                className="m-0 sm_value mb-2"
                dangerouslySetInnerHTML={createMarkup(item?.description)}
              />
              {!!item?.description_arb && (
                <div className="d-flex gap-2 mb-2" style={{ direction: "rtl" }}>
                  <span className="fw-bold">En Arabe:</span>
                  <p
                    className="m-0 sm_value mb-2"
                    dangerouslySetInnerHTML={createMarkup(
                      item?.description_arb || ""
                    )}
                    style={{ direction: "rtl" }}
                  />
                </div>
              )}
              {!!item?.description_fr && (
                <div className="d-flex gap-2">
                  <span className="fw-bold">En français:</span>
                  <p
                    className="m-0 sm_value mb-2"
                    dangerouslySetInnerHTML={createMarkup(
                      item?.description_fr || ""
                    )}
                  />
                </div>
              )}
            </div>
          </WrapperComponent>
        </div>
      </div>
      <div className="row product_price_and_publication_status__row">
        <div className="col-md-6 custom_wrapper">
          <WrapperComponent>
            <div className="card_content product_price">
              <p className="m-0 pb-3 sm_title">Prix</p>
              <p className="m-0 sm_value"> {formatCurrency(item?.prix)} </p>
            </div>
          </WrapperComponent>
        </div>
        <div className="col-md-6 custom_wrapper">
          <WrapperComponent>
            <div className="card_content product_price">
              <p className="m-0 pb-3 sm_title">Prix après remise</p>
              <p className="m-0 sm_value">
                {" "}
                {formatCurrency(item?.discount || 0)}{" "}
              </p>
            </div>
          </WrapperComponent>
        </div>
        <div className="col-md-6 custom_wrapper">
          <WrapperComponent>
            <div className="card_content product_price">
              <p className="m-0 pb-3 sm_title">Stock</p>
              <p className="m-0 sm_value"> {item?.quantite} </p>
            </div>
          </WrapperComponent>
        </div>
        <div className="col-md-6 custom_wrapper">
          <WrapperComponent>
            <div className="card_content product_price">
              <p className="m-0 pb-3 sm_title">Point</p>
              <p className="m-0 sm_value"> {item?.points || 0} </p>
            </div>
          </WrapperComponent>
        </div>
        <div className="col-md-6 custom_wrapper">
          <WrapperComponent>
            <div className="card_content product_price">
              <p className="m-0 pb-3 sm_title">Vendeur</p>
              <p className="m-0 sm_value"> {getName(item?.vendeur)} </p>
            </div>
          </WrapperComponent>
        </div>
        <div className="col-md-6 custom_wrapper">
          <WrapperComponent>
            <div className="card_content product_publication_status">
              <p className="m-0 pb-3 sm_title">Statut</p>
              <p className="m-0 sm_value">
                {" "}
                {item?.status === "published" ? "En ligne" : "Non publié"}{" "}
              </p>
            </div>
          </WrapperComponent>
        </div>
      </div>
    </>
  );
}

export default ProductDetails;
