import React from "react";
import { useStepType } from "../../../utils/type";
import { useStep } from "react-hooks-helper";
import WrapperComponent from "../../shared/WrapperComponent";
import ConditionsUtilisation from "./Tabs/ConditionsUtilisation";
import MessageAccueil from "./Tabs/MessageAccueil";
import Points from "./Tabs/Points";
import Administrateur from "./Tabs/Administrateur";
import "./Parametres.css"
import AddFieldModal from "./Modals/AddFieldModal";
import Policy from "./Tabs/Policy";

const steps = [
  { id: "cu", Component: ConditionsUtilisation },
  { id: "pc", Component: Policy },
  { id: "message-accueil", Component: MessageAccueil },
  { id: "points", Component: Points },
  { id: "administrateur", Component: Administrateur },
];

const tabs = [
  "Conditions d’utilisation",
  "Politique de confidentialité",
  "Message d’accueil",
  "Points",
  "Administrateurs",
];

function Parametres() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  return (
    <>
      <WrapperComponent customClass={"pb-md-0"}>
        <div className="container-title-dash d-flex align-items-center gap-4">
          <div className="custom-sm-top">
            <h2 className="custom-sm-top-title mb-0">Paramètres</h2>
            {/* <button 
                className="btn custom-add-item-btn"
                data-bs-toggle="modal"
                data-bs-target="#AddFielAdmin"
            >
              <span className="add-icon pe-2">
                <MdOutlineAddBox />
              </span>
              <span>Ajouter un champ</span>
            </button> */}
          </div>
        </div>

        <div className="masoul-tabs-container py-3 pb-md-0">
          <ul className="tabs-container custom-tabs-container pb-md-0 mb-md-0">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>
      </WrapperComponent>
      <WrapperComponent>
        <div className="tabs-component-container">
          <Component />
        </div>
      </WrapperComponent>
      <div
        className="modal fade"
        id="AddFielAdmin"
        aria-labelledby="AddFielAdminLabel"
        aria-hidden="true"
      >
        <AddFieldModal />
      </div>
    </>
  );
}

export default Parametres;
