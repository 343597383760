import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Category, CategoryFormData } from "./category.type";

export const CategoryApi = createApi({
  reducerPath: "categoryApi",
  tagTypes: [
    "category",
    "categoryList",
    "categoryByVisitor",
    "categoryById",
    "allCategoryList",
    "categoryProject",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListCategory: builder.query<PaginationResults<Category>, TypeQuery>({
      providesTags: ["categoryList"],
      query: (query) => QueryUrl("categories/", query),
    }),
    allCategoryList: builder.query<Category[], void>({
      providesTags: ["allCategoryList"],
      query: () => "mobile/categories/",
    }),
    addOrEditCategory: builder.mutation<
      Category,
      { slug?: string; data: CategoryFormData | FormData }
    >({
      invalidatesTags: ["categoryList", "allCategoryList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `categories/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `categories/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteCategory: builder.mutation<Category, string>({
      query: (slug) => ({
        url: `categories/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["categoryList", "allCategoryList"],
    }),
    // Categories project
    getListCategoryProject: builder.query<
      PaginationResults<Category>,
      TypeQuery
    >({
      providesTags: ["categoryProject"],
      query: (query) => QueryUrl("categories_projets/", query),
    }),
    addOrEditCategoryProject: builder.mutation<
      Category,
      { slug?: string; data: CategoryFormData | FormData }
    >({
      invalidatesTags: ["categoryProject"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `categories_projets/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `categories_projets/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteCategoryProject: builder.mutation<Category, string>({
      query: (slug) => ({
        url: `categories_projets/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["categoryProject"],
    }),
  }),
});

export const {
  useAddOrEditCategoryMutation,
  useDeleteCategoryMutation,
  useGetListCategoryQuery,
  useAllCategoryListQuery,
  useAddOrEditCategoryProjectMutation,
  useDeleteCategoryProjectMutation,
  useGetListCategoryProjectQuery,
} = CategoryApi;
