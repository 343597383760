import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Order, OrderFormData, StatSeller } from "./order.type";

export const OrderApi = createApi({
  reducerPath: "orderApi",
  tagTypes: ["order", "orderList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getOrderList: builder.query<PaginationResults<Order>, TypeQuery>({
      providesTags: ["orderList"],
      query: (query) => QueryUrl(`orders/`, query),
    }),
    getOrderListUser: builder.query<PaginationResults<Order>, TypeQuery>({
      providesTags: ["orderList"],
      query: ({ slug, ...query }) => QueryUrl(`user/${slug}/orders/`, query),
    }),
    getOrderListSeller: builder.query<PaginationResults<Order>, TypeQuery>({
      providesTags: ["orderList"],
      query: ({ slug, ...query }) =>
        QueryUrl(`vendeur_orders/admin/${slug}/`, query),
    }),
    getStatSeller: builder.query<StatSeller, string>({
      providesTags: ["orderList"],
      query: (slug) => `vendeur/admin/${slug}/`,
    }),
    addOrEditOrder: builder.mutation<
      Order,
      { slug?: string; data: OrderFormData | FormData }
    >({
      invalidatesTags: ["orderList", "order"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `orders/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `orders/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteOrder: builder.mutation<Order, string>({
      query: (id) => ({
        url: `orders/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["order", "orderList"],
    }),
    OrderById: builder.query<Order, string>({
      query: (id) => `order_mobile/${id}/`,
      providesTags: ["order"],
    }),
  }),
});

export const {
  useAddOrEditOrderMutation,
  useDeleteOrderMutation,
  useGetOrderListQuery,
  useLazyOrderByIdQuery,
  useGetOrderListSellerQuery,
  useGetOrderListUserQuery,
  useGetStatSellerQuery
} = OrderApi;

export function useOrderFromLocation(): [
  Order,
  boolean,
  string,
  (slug: string) => void
] {
  const itemState = useLocationState<Order | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findById, { data, isError, isLoading }] = useLazyOrderByIdQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findById(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return [item as Order, isLoading, slug as string, findById];
}
