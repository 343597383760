import React, { useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../common/TableSkeleton";
import { NavLink } from "react-router-dom";
import "./Table.css";
import { CustomPagination } from "../../common/CustomPagination";
import { AiFillEye } from "react-icons/ai";
import { useGetOrderListQuery } from "../../../utils/api/order/order.api";
import { Order } from "../../../utils/api/order/order.type";
import {
  formatCurrency,
  formatDate,
  getImage,
  getName,
  getStatutClassName,
  getStatutLabel,
} from "../../../utils/Utils";
import { IUser } from "../../../utils/api/user/user.type";

function CommandsTable({ q }: { q: string }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } = useGetOrderListQuery({
    page,
    limit,
    q,
  });

  const actionFormatter = (cell: string, row: Order) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/commandes/detail/${cell}`}
              className="btn btn-action-icon-see with-tooltip"
              data-tooltip-content="Voir"
              state={row}
            >
              <AiFillEye color="#158A91" />
            </NavLink>
          </div>
        </div>
      </>
    );
  };
  const productNameFormatter = (cell: Order["parents"]) => {
    let item = cell[0];
    return (
      <div className="d-flex gap-3 align-items-center">
        <div className="command_product_image__wrapper">
          <img
            src={getImage(item?.produits[0]?.produit?.images[0]?.picture)}
            alt={item?.produits[0]?.produit?.nom}
            className="product_image"
          />
        </div>
        <p className="product_name mb-0">{item?.code_commande}</p>
      </div>
    );
  };
  const statusFormatter = (cell: Order["statut"]) => {
    return (
      <div className={`status__box ${getStatutClassName(cell)}`}>
        <span className="m-0 status">{getStatutLabel(cell)}</span>
      </div>
    );
  };

  const priceFormatter = (cell: string, row: Order) => {
    return formatCurrency(cell) + " " + "(-" + row?.total_points + "pts)";
  };

  const columns = [
    {
      dataField: "parents",
      text: `Référence`,
      formatter: productNameFormatter,
      headerStyle: () => {
        return { width: "150px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell: string) => formatDate(cell),
    },
    {
      dataField: "user",
      text: "Acheteur",
      formatter: (cell: IUser) => getName(cell),
    },
    // {
    //   dataField: "seller",
    //   text: "Vendeur",
    // },
    {
      dataField: "total",
      text: "Prix",
      formatter: (cell: string, row: Order) => priceFormatter(cell, row),
    },
    {
      dataField: "statut",
      text: "Statut",
      formatter: statusFormatter,
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: () => {
        return { width: "100px", whiteSpace: "normal" };
      },
    },
  ];

  const rowClasses = (row: Order) => {
    let classes = "custom-table-row-class";

    if (row?.statut === "claim" || row?.parents?.some((p) => p?.statut === "claim")) {
      classes = "custom-table-row-class claim";
    }

    return classes;
  };

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            rowClasses={rowClasses}
            // rowClasses="custom-table-row-class"
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default CommandsTable;
