import React, { useState } from 'react'
import { IconSearch } from '../../../shared/Icons';
import ProjectsAdminTable from '../Tables/ProjectsAdminTable';

function ProjectsTab() {
  const [query, setQuery] = useState("");
  return (
    <div className="products_tab__content">
      <div className="search-user-box position-relative mt-4">
        <span className="icon-span-search-svg">
          <IconSearch />
        </span>
        <input
          type="search"
          name="search_command"
          id="__search_command"
          placeholder="Recherchez par nom du projet"
          onChange={(e) => setQuery(e?.target?.value)}
        />
      </div>

      <div className="custom-table mt-5">
        <ProjectsAdminTable q={query} />
      </div>
    </div>
  );
}

export default ProjectsTab