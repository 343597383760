/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { CloseModalBtnV2 } from "../../../shared/CloseModalBtn";
import { Input } from "../../../common/Input";
import Modal from "react-bootstrap/Modal";
import { BtnAnnuler, BtnSubmit } from "../../../common/Button";
import { Voucher } from "../../../../utils/api/vouchers/vouchers.type";
import UseCrudVoucherForm from "../form/UseCrudVoucherForm";

function AddVoucherModal({
  modalAddVoucher,
  setModalAddVoucher,
  item
}: {
  modalAddVoucher: boolean;
  setModalAddVoucher: any;
  item?:Voucher
}) {
  const closeModal = () => {
    setModalAddVoucher(false);
  }
  const {register,errors,onSubmit,handleReset,isLoading} = UseCrudVoucherForm(closeModal,item)

  return (
    <Modal
      show={modalAddVoucher}
      onHide={handleReset}
      contentLabel="Example Modal"
    >
      <div className="modal-content">
        <div className="modal-header custom-modal-header px-4 py-1">
          <h5 className="modal-title" id="CustomAddModalLabel">
            {(!item ? "Ajouter" : "Modifier")} un voucher
          </h5>
          <CloseModalBtnV2
            onClick={() => {
              handleReset();
            }}
          />
        </div>
        <div className="modal-body px-4">
          <div className="container-form">
            <form onSubmit={onSubmit}>
              <div className="row row-add-user my-3">
                <div className="col-md-12 mb-3">
                  <Input
                    type="number"
                    step={"any"}
                    min={1}
                    label="Somme"
                    id="somme"
                    placeholder="Somme"
                    required
                    {...register("prix")}
                    error={errors?.prix}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <Input
                    type="number"
                    step={"any"}
                    min={1}
                    label="Points"
                    id="points"
                    placeholder="Points"
                    required
                    {...register("points")}
                    error={errors?.points}
                  />
                </div>
              </div>
              <div className="flex-r btn__wrapper text-end pt-5 gap-3">
                <BtnAnnuler label={"Annuler"} onClick={() => handleReset()} />
                <BtnSubmit label="Enregistrer" isLoading={isLoading} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddVoucherModal;