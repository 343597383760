import Select from "react-select";
import { useProductFromLocation } from "../../../utils/api/product/product.api";
import { FormError, Input, animatedComponents } from "../../common/Input";
import MyDropzone, { ImageGrid } from "../../common/MyDropzone";
import { SelectInput } from "../../common/SelectInput";
import GoBackWrapper from "../../shared/GoBackWrapper";
import UseCrudProductForm, {
  UseDeleteImageProduct,
} from "./form/UseCrudProductForm";
import "./ProductAdmin.css";
// import ReactQuill from "react-quill";

function AddOrUpdateProduct() {
  const onDelete = UseDeleteImageProduct();
  const [item] = useProductFromLocation();
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    images,
    setImages,
    options,
    option,
    optionSeller,
    optionsSeller,
    control,
    Controller,
    handleSelected,
    handleSelectedSeller,
    handleChangeType,
    type,
  } = UseCrudProductForm(item);
  const deleteFile = (index, file) => {
    if (file?.picture) {
      onDelete(file?.slug);
      // deleteData({ productId: item?._id, pictureId: file?._id });
    } else {
      const newArr = [...images];
      newArr.splice(index, 1);
      setImages(newArr);
    }
  };
  return (
    <>
      <GoBackWrapper link="/admin/offres" />
      <div className="form_container add_product__form">
        <form onSubmit={onSubmit}>
          <div className="row form_row">
            <div className="col-md-12 custom_wrapper mb-3">
              <label
                htmlFor="images"
                className="form-label form-label-modal-custom"
              >
                Images de l'offre
                <span className="text-danger" style={{ fontSize: 15 }}>
                  *
                </span>
              </label>
              <MyDropzone setImages={setImages} />
              {<FormError error={errors?.images?.message} />}
              <div className="row">
                <ImageGrid images={images} deleteFile={deleteFile} />
              </div>
            </div>
            <div className="col-md-12 custom_wrapper">
              <Input
                type="text"
                label="Nom de l'offre en anglais"
                id="__product_name"
                placeholder="Nom de l'offre en anglais"
                {...register("nom")}
                error={errors?.nom}
                required
              />
            </div>
            <div className="col-md-6 custom_wrapper">
              <Input
                type="text"
                label="Nom de l'offre en français"
                id="__product_name"
                placeholder="Nom de l'offre en français"
                {...register("nom_fr")}
                error={errors?.nom_fr}
              />
            </div>
            <div className="col-md-6 custom_wrapper">
              <Input
                type="text"
                label="Nom de l'offre en arabe"
                id="__product_name"
                {...register("nom_arb")}
                error={errors?.nom_arb}
                placeholder="الرجاء إدخال النص هنا..."
                style={{ direction: "rtl" }}
                dir="rtl"
              />
            </div>

            <div className="col-md-6 mb-3 custom_wrapper">
              <label
                htmlFor="category"
                className="form-label form-label-modal-custom"
              >
                Catégorie
                <span className="text-danger" style={{ fontSize: 15 }}>
                  *
                </span>
              </label>
              {/* <Select /> */}
              <Controller
                name="categorie"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      placeholder="Sélectionner une catégorie"
                      options={options}
                      classNamePrefix="react-select-custom"
                      onChange={handleSelected}
                      value={option}
                    />
                  );
                }}
              />
              {<FormError error={errors?.categorie?.message} />}
            </div>
            <div className="col-md-6 mb-3 custom_wrapper">
              <label
                htmlFor="seller"
                className="form-label form-label-modal-custom"
              >
                Vendeur
                <span className="text-danger" style={{ fontSize: 15 }}>
                  *
                </span>
              </label>
              <Controller
                name="vendeur"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      placeholder="Sélectionner un vendeur"
                      options={optionsSeller}
                      classNamePrefix="react-select-custom"
                      onChange={handleSelectedSeller}
                      value={optionSeller}
                    />
                  );
                }}
              />
              {<FormError error={errors?.categorie?.message} />}
            </div>
            <div className="col-md-6 custom_wrapper">
              <Input
                type="number"
                label="Prix"
                id="__product_price"
                placeholder="Prix"
                min={0}
                step={"any"}
                {...register("prix")}
                error={errors?.prix}
                required
              />
            </div>
            <div className="col-md-6 custom_wrapper">
              <SelectInput
                label="Type de stock"
                value={type}
                options={[
                  {
                    label: "Quantité",
                    value: "quantite",
                  },
                  {
                    label: "Montant maximum offert",
                    value: "max_price",
                  },
                ]}
                onChange={(e) => handleChangeType(e)}
              />
            </div>
            <div className="col-md-6 custom_wrapper">
              <Input
                type="number"
                label={
                  type === "quantite"
                    ? "Stock disponible"
                    : "Montant maximum offert"
                }
                id="__product_stocke"
                placeholder={
                  type === "quantite" ? "Quantité" : "Montant maximum offert"
                }
                min={0}
                {...register(`${type}`)}
                error={errors?.quantite?.message || errors?.max_price?.message}
                required
              />
            </div>
            <div className="col-md-6 custom_wrapper">
              <Input
                type="number"
                label="Prix après remise"
                id="__product_discount"
                placeholder="Prix après remise"
                min={0}
                step={"any"}
                {...register("discount")}
                error={errors?.discount}
              />
            </div>
            <div className="col-md-6 custom_wrapper">
              <Input
                type="number"
                label="Points"
                id="__product_point"
                placeholder="Points"
                min={0}
                step={"any"}
                {...register("points")}
                error={errors?.points}
              />
            </div>
            <div className="col-md-12 mb-3 custom_wrapper">
              <label
                htmlFor="description"
                className="form-label form-label-modal-custom"
              >
                Description en anglais
                <span className="text-danger" style={{ fontSize: 15 }}>
                  *
                </span>
              </label>

              <textarea
                className="form-control form-control-modal-custom"
                id="__product_description"
                placeholder="Description en anglais"
                rows={7}
                {...register("description")}
              />
              <FormError error={errors?.description?.message} />
            </div>
            <div className="col-md-12 mb-3 custom_wrapper">
              <label
                htmlFor="description_fr"
                className="form-label form-label-modal-custom"
              >
                Description en français
              </label>

              <textarea
                className="form-control form-control-modal-custom"
                id="description_fr"
                placeholder="Description en français"
                rows={7}
                {...register("description_fr")}
              />
              <FormError error={errors?.description_fr?.message} />
            </div>
            <div className="col-md-12 mb-3 custom_wrapper">
              <label
                htmlFor="description"
                className="form-label form-label-modal-custom"
              >
                Description en arabe
              </label>

              <textarea
                className="form-control form-control-modal-custom"
                id="__product_description"
                rows={7}
                {...register("description_arb")}
                placeholder="الرجاء إدخال النص هنا..."
                style={{ direction: "rtl" }}
                dir="rtl"
              />
              <FormError error={errors?.description_arb?.message} />
            </div>
          </div>
          <div className="flex-r btn__wrapper text-end pt-5">
            <button
              type="button"
              className="btn custom_close__btn"
              disabled={isLoading}
            >
              Annuler
            </button>
            <button
              type="submit"
              className="btn custom_submit__btn"
              disabled={isLoading}
            >
              {!isLoading ? (
                "Enregistrer"
              ) : (
                <>
                  <span
                    className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                    role="status"
                  ></span>
                  <span>En cours...</span>
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddOrUpdateProduct;
