import React, { useState } from "react";
import SellersCommandTable, {
  PropsType,
} from "../../../Tables/SellersCommandTable";
import { useGetOrderListUserQuery } from "../../../../../utils/api/order/order.api";

function Commands({ go, item,setOrder }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetOrderListUserQuery({
      slug: item?.slug,
      page,
      limit,
    });
  const props: PropsType = {
    data,
    page,
    limit,
    setPage,
    setLimit,
    isLoading,
    go,
    setOrder
  };
  return (
    <div className="commands_history">
      <div className="seller_command_table__wrapper pt-1">
        <SellersCommandTable {...props} />
      </div>
    </div>
  );
}

export default Commands;
