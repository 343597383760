import React from "react";
import "./Cgu.css";
import Logo from "../../assets/appImages/logo.svg";
import { useGetPCQuery } from "../../utils/api/condition/condition.api";
import { AlertInfo } from "../common/Alert";
import { ConditionSkeleton } from "../admin/Parametres/Tabs/ConditionsUtilisation";
import { createMarkup } from "../../utils/Utils";

function Policy() {
  const { data = [], isLoading } = useGetPCQuery();
  return (
    <div className="container-cgu-component">
      <div className="form-login-container-auth w-100">
        <div className="content-cgu-component mt-4">
          <div className="container-logo-app-login pb-4 mx-auto text-center">
            <img src={Logo} alt="Logo" className="img-logo-app-login" />
          </div>
          <h1 className="title-login-page pb-5 text-uppercase">
            Politique de Confidentialité
          </h1>
          <div className="container-form-login cgu-content">
            {!!isLoading && <ConditionSkeleton />}
            {!isLoading &&
              (!data?.length ? (
                <div>
                  <AlertInfo message="Aucun contenu trouvé" />
                </div>
              ) : (
                <>
                  <p
                    className="mb-3"
                    dangerouslySetInnerHTML={createMarkup(data[0]?.text)}
                  />
                  <p
                    dangerouslySetInnerHTML={createMarkup(
                      data[0]?.text_arb || ""
                    )}
                    style={{ direction: "rtl" }}
                    className="mb-3"
                  />
                  <p
                    className="mb-3"
                    dangerouslySetInnerHTML={createMarkup(
                      data[0]?.text_fr || ""
                    )}
                  />
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Policy;
