import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import "./ProductAdmin.css";
import { NavLink } from "react-router-dom";
import { MdOutlineAddBox } from "react-icons/md";
import { useStepType } from "../../../utils/type";
import { useStep } from "react-hooks-helper";
import ProductTab from "./tabs/ProductTab";
import CategoryTab from "./tabs/CategoryTab";
import AddOrUpdateCategoryModal from "./modals/AddOrUpdateCategoryModal";

const tabs = [
  "Offres",
  "Categories"
]
const steps = [
  { id: "all-products", Component: ProductTab },
  { id: "all-categories", Component: CategoryTab },
]

function ProductAdmin() {
  const {
      index,
      navigation: {go},
      step
  }: useStepType = useStep({initialStep: 0, steps});
  const { Component } = step
  const [modalAddCategory, setModalAddCategory] = React.useState(false);
  function openModalAddCategory() {
      setModalAddCategory(true);
  }
  return (
    <WrapperComponent>
      <div className="container-title-dash d-flex align-items-center gap-4">
        <div className="custom-sm-top">
          <h2 className="custom-sm-top-title mb-0">
            <ul className="tabs_container d-flex">
              {tabs.map((tab, key) => (
                <li 
                  className={`
                    tab_item position-relative big__size
                    ${index === key ? 'tab_item__active': ''} 
                  `}
                  onClick={() => go(key)}
                  key={key}
                  role="button"
                >
                  {tab}
                </li>
              ))}
            </ul>
          </h2>
          {index === 0 && (
            <NavLink  
              className="btn custom-add-item-btn"
              to={`/admin/offres/ajouter`}
            >
              <span className="add-icon pe-2">
                <MdOutlineAddBox />
              </span>
              <span>Ajouter une offre</span>
            </NavLink>
          )}
          {index === 1 && (
            <>
              <button  
                className="btn custom-add-item-btn"
                onClick={() => openModalAddCategory()}
              >
                <span className="add-icon pe-2">
                  <MdOutlineAddBox />
                </span>
                <span>Ajouter une catégorie</span>
              </button>
              <AddOrUpdateCategoryModal 
                modalAddCategory={modalAddCategory}
                setModalAddCategory={setModalAddCategory}
              />
            </>
          )}
        </div>
      </div>
      <div className="pt-5 products_and_categories_steps__wrapper">
        <Component />
      </div>
    </WrapperComponent>
  );
}

export default ProductAdmin;
