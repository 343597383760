import { configureStore } from "@reduxjs/toolkit";
import { AuthApi } from "../utils/api/auth/auth.api";
import { UserApi } from "../utils/api/user/user.api";
import { currentEnv, Env } from "../utils/http";
import { UserSlice } from "./slice/User.slice";
import { CategoryApi } from "../utils/api/category/category.api";
import { ProductApi } from "../utils/api/product/product.api";
import { ConditionApi } from "../utils/api/condition/condition.api";
import { VoucherApi } from "../utils/api/vouchers/vouchers.api";
import { ConfigApi } from "../utils/api/config/config.api";
import { OrderApi } from "../utils/api/order/order.api";
import { ProjectApi } from "../utils/api/project/project.api";
import { DashboardApi } from "../utils/api/dashboard/dashboard.api";
import { ClaimApi } from "../utils/api/claim/claim.api";
import { NotificationApi } from "../utils/api/notification/notification.api";

const store = configureStore({
  reducer: {
    [AuthApi.reducerPath]: AuthApi.reducer,
    [UserSlice.name]: UserSlice.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
    [CategoryApi.reducerPath]: CategoryApi.reducer,
    [ProductApi.reducerPath]: ProductApi.reducer,
    [ConditionApi.reducerPath]: ConditionApi.reducer,
    [VoucherApi.reducerPath]: VoucherApi.reducer,
    [ConfigApi.reducerPath]: ConfigApi.reducer,
    [OrderApi.reducerPath]: OrderApi.reducer,
    [ProjectApi.reducerPath]: ProjectApi.reducer,
    [DashboardApi.reducerPath]: DashboardApi.reducer,
    [ClaimApi.reducerPath]: ClaimApi.reducer,
    [NotificationApi.reducerPath]: NotificationApi.reducer
  },
  devTools: Env === currentEnv,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    AuthApi.middleware,
    UserApi.middleware,
    CategoryApi.middleware,
    ProductApi.middleware,
    ConditionApi.middleware,
    VoucherApi.middleware,
    ConfigApi.middleware,
    OrderApi.middleware,
    ProjectApi.middleware,
    DashboardApi.middleware,
    ClaimApi.middleware,
    NotificationApi.middleware
  ],
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;
