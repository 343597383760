import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../common/TableSkeleton";
import { NavLink } from "react-router-dom";
import "./Table.css";
import { CustomPagination } from "../../common/CustomPagination";
import { AiFillEye } from "react-icons/ai";
import { MdModeEditOutline, MdOutlineAdd } from "react-icons/md";
import SharePointModal from "../ListUsers/modals/SharePointModal";
import { IUser } from "../../../utils/api/user/user.type";
import { UseBlockUser } from "../SellersAdmin/form/UseCrudSellerForm";
import { useGetListUserQuery } from "../../../utils/api/user/user.api";
import { BtnDelete } from "./SellersAdminTable";
import AddUserModal from "../ListUsers/modals/AddUserModal";
import { getAvatar, getName } from "../../../utils/Utils";

export function Switch({
  onPress,
  active,
}: {
  onPress: () => void;
  active: boolean;
}) {
  const onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onPress();
  };
  return (
    <div className="switch2">
      <label onClick={onClick} className="switch">
        <input
          type="checkbox"
          checked={!active}
          onChange={(e) => console.log(e?.target?.checked)}
        />
        <span className="slider"></span>
      </label>
    </div>
  );
}

export function ToggleUserActive({ item }: { item: IUser }) {
  const onBlock = UseBlockUser(item);
  return <Switch active={item?.bloquer} onPress={() => onBlock()} />;
}

const EditBtn = ({ row }: { row: IUser }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <button
        className="btn btn-action-icon-edit with-tooltip"
        data-tooltip-content="Modifier"
        onClick={() => setShow(true)}
      >
        <MdModeEditOutline />
      </button>
      <AddUserModal modalAddUser={show} setModalAddUser={setShow} item={row} />
    </>
  );
};

const BtnShareUserPoint = ({ user }: { user: IUser }) => {
  const [modalSharePoint, setModalSharePoint] = React.useState(false);
  function openModalSharePoint() {
    setModalSharePoint(true);
  }
  return (
    <>
      <button
        onClick={() => openModalSharePoint()}
        className="btn btn-action-icon-add-point with-tooltip"
        data-tooltip-content="Points"
      >
        <MdOutlineAdd color="#521A2A" />
      </button>
      <SharePointModal
        modalSharePoint={modalSharePoint}
        setModalSharePoint={setModalSharePoint}
        item={user}
      />
    </>
  );
};

function ListUsersTable({ q }: { q: string }) {
  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(10);
  const {
    data = { results: [], count: 0 },
    isLoading,
    refetch,
  } = useGetListUserQuery({
    page,
    limit,
    q,
  });

  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
  }, []);

  const actionFormatter = (cell: string, row: IUser) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/utilisateurs/detail/${cell}`}
              className="btn btn-action-icon-see with-tooltip"
              data-tooltip-content="Voir"
              state={row}
            >
              <AiFillEye color="#158A91" />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <BtnShareUserPoint user={row} />
          </div>
          <div className="container-btn-action-icon-modal">
            <EditBtn row={row} />
          </div>
          <div className="container-btn-action-icon-modal">
            <BtnDelete slug={cell} />
          </div>
        </div>
      </>
    );
  };
  const nameFormatter = (cell: string, row: IUser) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <div className="content-img-profil-user-table">
          <img
            src={getAvatar(row?.avatar)}
            alt={cell}
            className="img-profil-user-table"
          />
        </div>
        <p className="name-profil-user-table mb-0">{getName(row)}</p>
      </div>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: `Nom`,
      formatter: nameFormatter,
      // headerStyle: () => {
      //   return { width: "200px", whiteSpace: "normal" };
      // },
    },
    {
      dataField: "email",
      text: "Email",
    },
    // {
    //   dataField: "phone",
    //   text: "Téléphone",
    // },
    {
      dataField: "bloquer",
      text: "Bloquer",
      formatter: (cell: string, row: IUser) => <ToggleUserActive item={row} />,
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: () => {
        return { width: "100px", whiteSpace: "normal" };
      },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            rowClasses="custom-table-row-class"
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ListUsersTable;
