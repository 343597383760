import React from "react";
import WrapperComponent from "../../../shared/WrapperComponent";
import GoBackWrapper from "../../../shared/GoBackWrapper";
import "../SellersAdmin.css";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import PersonalInformation from "./tabs/PersonalInformation";
// import UserCommandDetails from "../../ListUsers/UserDetails/tabs/UserCommandDetails";
import { useUserFromLocation } from "../../../../utils/api/user/user.api";
import { getAvatar, getName } from "../../../../utils/Utils";
import ActivitiesTab from "./tabs/ActivitiesTab";

const tabs = [
    "Informations personnelles",
    "Activités"
]
const steps = [
  { id: "personal-infos", Component: PersonalInformation },
  { id: "activities", Component: ActivitiesTab },
//   { id: "user-command-details", Component: UserCommandDetails },
];

function SellerDetails() {
    const {
        index,
        navigation: {go},
        step
    }: useStepType = useStep({initialStep: 0, steps});
    const { Component } = step
    const [item] = useUserFromLocation()
    const props = {
        go,
        item
    }
  return (
    <>
        <GoBackWrapper link="/admin/vendeurs" />
        <WrapperComponent>
            <div className="card_content">
                <div className="profil__grid pb-5">
                    <div className="user_profil">
                        <div className="photo__wrapper">
                          <img src={getAvatar(item?.avatar)} alt="photo" />
                        </div>
                    </div>
                    <div className="user_name_and_points__wrapper">
                        <p className="m-0 pb-2 user_name">
                            {getName(item)}
                        </p>
                    </div>
                </div>
                <div className="sellers-steps">
                    <ul className="d-flex align-items-center tabs_container">
                    {tabs.map((tab, key) => (
                        <li 
                            role="button"
                            className={`
                                tab_item position-relative
                                ${index === key ? 'tab_item__active' : ''} 
                            `}
                            onClick={() => go(key)}
                        >
                            {tab}
                        </li>
                    ))}
                    </ul>
                </div>
            </div>
        </WrapperComponent>
        <WrapperComponent>
            <div className="card_content pb-4">
                <Component {...props} />
            </div>
        </WrapperComponent>
    </>
  );
}

export default SellerDetails;
