/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../common/TableSkeleton";
import "./Table.css";
import { CustomPagination } from "../../common/CustomPagination";
import { FaArrowRight } from "react-icons/fa";
import {
  formatCurrency,
  formatDateHour,
  getStatutClassName,
  getStatutLabel,
} from "../../../utils/Utils";
import { Order } from "../../../utils/api/order/order.type";
import { PaginationResults } from "../../../utils/api/user/user.type";
export type PropsType = {
  go: any;
  data: PaginationResults<Order>;
  isLoading: boolean;
  limit: number;
  setLimit: Dispatch<SetStateAction<number>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  setOrder?: Dispatch<SetStateAction<Order>>
};

function SellersCommandTable({ go, data,isLoading,limit,setLimit,page,setPage,setOrder }: PropsType) {
  

  const statusFormatter = (cell: Order["statut"]) => {
    return (
      <div className="command-status-formatter">
        <p className={`m-0 status ${getStatutClassName(cell)}`}>
          {getStatutLabel(cell)}
        </p>
      </div>
    );
  };
  const actionFormatter = (cell: string, row: Order) => {
    return (
      <p className="m-0 command-action-formatter">
        <button
          // to={`/admin/utilisateurs/detail/:slug`}
          className="custom-btn-see-more btn"
          onClick={() => {
            !!setOrder && setOrder(row);
            go("user-command-details")
          }}
        >
          Voir les details
          <span className="arrow-icon">
            <FaArrowRight />
          </span>
        </button>
      </p>
    );
  };
  const CommandNumberFormatter = (cell: any) => {
    return (
      <p className="m-0 command-number-formatter" style={{ color: "#000" }}>
        <strong>{cell}</strong>
      </p>
    );
  };

  const priceFormatter = (cell: string, row: Order) => {
    return formatCurrency(cell) + " " + "(-" + row?.total_points + "pts)";
  };

  const columns = [
    {
      dataField: "code_commande",
      text: `Commande N°`,
      formatter: CommandNumberFormatter,
      // headerStyle: () => {
      //   return { width: "150px", whiteSpace: "normal" };
      // },
    },
    {
      dataField: "statut",
      text: "Statut",
      formatter: statusFormatter,
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell: string) => formatDateHour(cell),
    },
    {
      dataField: "total",
      text: "Total",
      formatter: (cell: string, row: Order) => priceFormatter(cell, row),
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" };
      },
    },
  ];

  const rowClasses = (row: Order) => {
    let classes = "custom-table-row-class";

    if (row?.statut === "claim" || row?.parents?.some((p) => p?.statut === "claim")) {
      classes = "custom-table-row-class claim";
    }

    return classes;
  };

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            rowClasses={rowClasses}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default SellersCommandTable;
