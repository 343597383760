import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { Dashboard } from "./dashboard.type";
import { TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";

export const DashboardApi = createApi({
  reducerPath: "dashboardApi",
  tagTypes: ["dashboard"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getDashboard: builder.query<Dashboard, TypeQuery>({
      providesTags: ["dashboard"],
      query: (query) => QueryUrl("admin/dashboard/",query),
    }),
  }),
});

export const { useGetDashboardQuery } = DashboardApi;
