import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import ReclamationTable from "../Tables/ReclamationTable";

function ReclamationAdmin() {
  return (
    <WrapperComponent>
      <div className="container-title-dash d-flex align-items-center gap-4">
        <div className="custom-sm-top">
          <h2 className="custom-sm-top-title mb-0">Réclamations</h2>
        </div>
      </div>

      <div className="custom-table mt-5">
        <ReclamationTable />
      </div>
    </WrapperComponent>
  );
}

export default ReclamationAdmin;
