import React, { useEffect, useState } from "react";
import AddOrUpdateCategoryModal from "../modals/AddOrUpdateCategoryModal";
import { FaTrash } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import { useGetListCategoryQuery } from "../../../../utils/api/category/category.api";
import { CustomPagination } from "../../../common/CustomPagination";
import { AlertInfo } from "../../../common/Alert";
import { Category } from "../../../../utils/api/category/category.type";
import Skeleton from "react-loading-skeleton";
import { UseDeleteCategory } from "../form/UseCrudCategoryForm";
import { getImage } from "../../../../utils/Utils";

const CategorySkeleton = () => {
  return (
    <>
      {[...Array(20)]?.map((el, i) => (
        <div className="col-md-6 mb-3" key={i}>
          <div className="d-flex align-items-center gap-2 category_item">
            <Skeleton width={100} />
            <div className="d-flex gap-2 action_btn__wrapper">
              <div className="custom_btn__wrapper">
                <Skeleton circle width={30} height={30} />
              </div>
              <div className="custom_btn__wrapper">
                <Skeleton circle width={30} height={30} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const CategoryItem = ({ category }: { category: Category }) => {
  const onDelete = UseDeleteCategory(category?.slug);
  const [show, setShow] = React.useState(false);
  function openModal() {
    setShow(true);
  }
  return (
    <div className="d-flex align-items-center gap-2 category_item">
      <div className="name-container">
        <img
          src={getImage(category?.image)}
          alt={category?.nom}
          className="img-category"
        />
        <h6 className="m-0 category__name">{category?.nom}</h6>
        <div className="title-content">
          <h6 className="m-0 category__name" style={{ direction: "rtl" }}>
            {!!category?.nom_arb && `(${category?.nom_arb})`}
          </h6>
          <h6 className="m-0 category__name">
            {!!category?.nom_fr && `(${category?.nom_fr})`}
          </h6>
        </div>
      </div>

      <div className="d-flex gap-2 action_btn__wrapper">
        <div className="custom_btn__wrapper">
          <button
            onClick={() => openModal()}
            className="btn btn-action-icon-edit with-tooltip"
            data-tooltip-content="Modifier"
          >
            <MdModeEditOutline color="##476DB6" />
          </button>
          <AddOrUpdateCategoryModal
            modalAddCategory={show}
            setModalAddCategory={setShow}
            item={category}
          />
        </div>
        <div className="custom_btn__wrapper">
          <button
            className="btn with-tooltip btn-action-icon-delete"
            data-tooltip-content="Supprimer"
            style={{ color: "#E53E3E" }}
            onClick={() => onDelete()}
          >
            <FaTrash />
          </button>
        </div>
      </div>
    </div>
  );
};

function CategoryTab() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(16);

  const {
    data = { results: [], count: 0 },
    isLoading,
    refetch,
  } = useGetListCategoryQuery({ page, limit });

  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
  }, []);
  return (
    <div className="py-3 categories_tab__content">
      <div className="row categories_row align-items-center">
        {!!isLoading && <CategorySkeleton />}
        {!isLoading &&
          (data?.results?.length ? (
            data?.results?.map((category) => (
              <div className="col-md-6 mb-3 custom__wrapper" key={category?.id}>
                <CategoryItem category={category} />
              </div>
            ))
          ) : (
            <AlertInfo message="Aucune donnée disponible" />
          ))}
        <div className="col-md-12">
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
              showPerPage={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryTab;
