import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../common/TableSkeleton";
import { NavLink } from "react-router-dom";
import "./Table.css";
import { CustomPagination } from "../../common/CustomPagination";
import { IUser } from "../../../utils/api/user/user.type";
import {
  formatDate,
  getAvatar,
  getImage,
  getName,
  getStatutClaimClassName,
  getStatutClaimLabel,
  showModal,
} from "../../../utils/Utils";
import { useGetListClaimQuery } from "../../../utils/api/claim/claim.api";
import { Order } from "../../../utils/api/order/order.type";
import { isSeller } from "../../../routes/routerUtils";
import { Claim } from "../../../utils/api/claim/claim.type";
import ChangeStatutModal from "../Reclamation/modal/ChangeStatutModal";
import { AiFillEye } from "react-icons/ai";

function ReclamationTable() {
  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(10);
  const {
    data = { results: [], count: 0 },
    isLoading,
    refetch,
  } = useGetListClaimQuery({
    page,
    limit,
  });

  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
  }, []);

  const actionFormatter = (cell: string, row: Claim) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/réclamations/${cell}`}
              className="btn btn-action-icon-see with-tooltip"
              data-tooltip-content="Voir"
              state={row}
            >
              <AiFillEye color="#158A91" />
            </NavLink>
          </div>
        </div>
      </>
    );
  };

  const statusFormatter = (cell: Claim["statut"], row: Claim) => {
    return (
      <>
        <div
          className={`status__box linkable ${getStatutClaimClassName(cell)}`}
          onClick={() => showModal(`changeStatusClaim${row?.slug}`)}
        >
          <span className="m-0 status">{getStatutClaimLabel(cell)}</span>
        </div>
        <ChangeStatutModal item={row} />
      </>
    );
  };

  const orderFormatter = (cell: Order) => {
    let item = cell?.cart?.items[0];
    return (
      <NavLink
        to={`/admin/commandes/detail/${cell?.order?.slug || cell?.slug}`}
        className="d-flex gap-3 align-items-center no-link claim-order"
        state={cell?.order || cell}
      >
        <div className="command_product_image__wrapper">
          <img
            src={getImage(item?.produit?.images[0]?.picture)}
            alt={item?.produit?.nom}
            className="product_image"
          />
        </div>
        <p className="product_name mb-0">{item?.produit?.nom}</p>
      </NavLink>
    );
  };

  const userFormatter = (cell: IUser) => {
    return (
      <NavLink
        to={`/admin/${isSeller(cell) ? "vendeurs" : "utilisateurs"}/detail/${
          cell?.slug
        }`}
        className="d-flex gap-3 align-items-center claim-order no-link"
        state={cell}
      >
        <div className="content-img-profil-user-table">
          <img
            src={getAvatar(cell?.avatar)}
            alt={getName(cell)}
            className="img-profil-user-table"
          />
        </div>
        <p className="name-profil-user-table mb-0">{getName(cell)}</p>
      </NavLink>
    );
  };

  const columns = [
    {
      dataField: "order",
      text: `Commande`,
      formatter: orderFormatter,
    },
    {
      dataField: "order.vendeur",
      text: "Vendeur",
      formatter: userFormatter,
    },
    {
      dataField: "user",
      text: "Client",
      formatter: userFormatter,
    },
    // {
    //   dataField: "motif",
    //   text: "Motif",
    // },
    {
      dataField: "statut",
      text: "Statut",
      formatter: statusFormatter,
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell: string) => formatDate(cell),
      // headerStyle: () => {
      //   return { width: "220px" };
      // },
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
      // headerStyle: () => {
      //   return { width: "100px", whiteSpace: "normal" };
      // },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            rowClasses="custom-table-row-class"
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ReclamationTable;
