/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { AlertInfo } from "../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../common/TableSkeleton";
import "./Table.css";
import { CartItem, Child, Order } from "../../../utils/api/order/order.type";
import { Product } from "../../../utils/api/product/product.type";
import {
  formatCurrency,
  getImage,
  getName,
  getStatutClassName,
  getStatutLabel,
} from "../../../utils/Utils";
import { NavLink } from "react-router-dom";

function CommandDetailsProductTable({
  data = [],
  isLoading = false,
  item,
}: {
  data?: CartItem[];
  isLoading?: boolean;
  item?: Child | Order;
}) {
  const productNameFormatter = (cell: Product) => {
    return (
      <div className="d-flex gap-2 align-items-center justify-content-start">
        <div className="product_image__wrapper">
          <img
            src={getImage(cell?.images[0]?.picture)}
            alt={cell?.nom}
            className="product_image"
          />
        </div>
        <div className="category_and_product_name__content">
          <NavLink
            to={`/admin/offres/detail/${cell?.slug}`}
            state={cell}
            className="mb-0 pb-1 category_name no-link"
          >
            {cell?.nom}
          </NavLink>
          {/* <p className="mb-0 product_name">{cell?.nom}</p> */}
        </div>
      </div>
    );
  };

  const priceFormatter = (cell: string, row: CartItem) => {
    return (
      formatCurrency(
        parseInt(row?.produit?.discount) > 0 ? row?.produit?.discount : cell
      ) +
      " " +
      "(-" +
      row?.produit?.points +
      "pts)"
    );
  };

  const priceItemFormatter = (cell: string, row: CartItem) => {
    return formatCurrency(row?.prix) + " " + "(-" + row?.points + "pts)";
  };

  const columns = [
    {
      dataField: "produit",
      text: `Offre`,
      formatter: productNameFormatter,
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "produit.prix",
      text: "Prix",
      formatter: priceFormatter,
    },
    {
      dataField: "quantite",
      text: "Quantité",
      formatter: (cell: number) => "x" + cell,
      headerStyle: () => {
        return { width: "100px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "prix",
      text: "Sous-total",
      formatter: priceItemFormatter,
    },
    {
      dataField: "slug",
      text: "Total",
      formatter: priceItemFormatter,
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <div className="mb-4 border-bottom">
          <div className="d-flex justify-content-between">
            {!!item?.vendeur?.id && (
              <p className="m-0 pb-2 product__counter">
                <strong>Vendeur</strong> <span>({getName(item?.vendeur)})</span>
              </p>
            )}

            <div className="deliver_status__infos">
              <span className="sm_title reduce_size">Statut:</span>
              &nbsp;
              <span
                className={`sm_value ${
                  !!item && getStatutClassName(item?.statut)
                }`}
              >
                {!!item && getStatutLabel(item?.statut)}
              </span>
            </div>

            {/* <div className="seller_informations">
              <p className="m-0 pb-2 sm_title">Vendeur</p>
              <p className="m-0 sm_value">
                {!!item?.vendeur && getName(item?.vendeur)}
              </p>
            </div> */}
          </div>

          <BootstrapTable
            keyField="id"
            data={data}
            columns={columns}
            condensed
            hover
            rowClasses="custom-table-row-class"
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          {/* <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.length}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div> */}
        </div>
      )}
    </>
  );
}

export default CommandDetailsProductTable;
