import React, { useEffect, useState } from "react";
import { MdModeEditOutline, MdOutlineAddBox } from "react-icons/md";
import "./VouchersAdmin.css";
import { FaTrash } from "react-icons/fa";
import AddVoucherModal from "./modals/AddVouchersModal";
import { useGetListVoucherQuery } from "../../../utils/api/vouchers/vouchers.api";
import { Voucher } from "../../../utils/api/vouchers/vouchers.type";
import { UseDeleteVoucher } from "./form/UseCrudVoucherForm";
import { AlertInfo } from "../../common/Alert";
import { CustomPagination } from "../../common/CustomPagination";
import { formatCurrency } from "../../../utils/Utils";
import Skeleton from "react-loading-skeleton";

const VoucherSkeleton = () => {
  return (
    <>
      {[...Array(15)]?.map((el, i) => (
        <div className="col-md-4 mb-3" key={i}>
          <Skeleton width={`100%`} height={`10rem`} borderRadius={14} />
        </div>
      ))}
    </>
  );
};

const VoucherItem = ({ item }: { item: Voucher }) => {
  const onDelete = UseDeleteVoucher(item?.slug);
  const [show, setShow] = React.useState(false);
  

  return (
    <>
      <div className="card-voucher-admin-wrapper">
        <div className="card-voucher-admin">
          <div className="card-voucher-admin-title mb-4 pb-3">
            Get guaranteed discounts
          </div>
          <div className="card-voucher-admin-content">
            <div className="card-voucher-admin-amount">
              {formatCurrency(item?.prix)} <span>for</span>
            </div>
            <div className="card-voucher-admin-eticket position-relative">
              <div className="card-voucher-admin-custom-right-dot"></div>
              <div className="card-voucher-admin-eticket-beige position-relative">
                <div className="card-voucher-admin-custom-left-dot"></div>
              </div>
              <div className="card-voucher-admin-eticket-white">
                <div className="card-voucher-admin-eticket-white-point">
                  {item?.points}
                </div>
                <div className="card-voucher-admin-eticket-white-point-label">
                  Points
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-voucher-admin-btn-container">
          <div className="d-flex gap-2 justify-content-end p-2">
            <div className="container-btn-action-icon-modal">
              <button
                className="btn btn-action-icon-edit"
                style={{ fontSize: "15px" }}
                onClick={() => setShow(true)}
              >
                <MdModeEditOutline />
              </button>
            </div>
            <div className="container-btn-action-icon-modal">
              <button
                className="btn btn-action-icon-delete"
                style={{ color: "#E53E3E", fontSize: "15px" }}
                onClick={() => onDelete()}
              >
                <FaTrash />
              </button>
            </div>
          </div>
        </div>
      </div>
      <AddVoucherModal
        modalAddVoucher={show}
        setModalAddVoucher={setShow}
        item={item}
      />
    </>
  );
};

function VouchersAdmin() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const {
    data = { results: [], count: 0 },
    isLoading,
    refetch,
  } = useGetListVoucherQuery({ page, limit });
  const [modalAddVoucher, setModalAddVoucher] = React.useState(false);

  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
  }, []);

  function openModalAddVoucher() {
    setModalAddVoucher(true);
  }
  return (
    <div className="p-3">
      <div className="container-title-dash d-flex align-items-center gap-4">
        <div className="custom-sm-top">
          <h2 className="custom-sm-top-title mb-0">Vouchers</h2>
          <button
            className="btn custom-add-item-btn"
            onClick={() => openModalAddVoucher()}
          >
            <span className="add-icon pe-2">
              <MdOutlineAddBox />
            </span>
            <span>Créer un vouchers</span>
          </button>
          <AddVoucherModal
            modalAddVoucher={modalAddVoucher}
            setModalAddVoucher={setModalAddVoucher}
          />
        </div>
      </div>
      <div className="custom-table mt-5">
        <div className="row">
          {!!isLoading && <VoucherSkeleton />}
          {!isLoading &&
            (data?.results?.length ? (
              data?.results?.map((item) => (
                <div className="col-md-4 mb-3" key={item?.slug}>
                  <VoucherItem item={item} />
                </div>
              ))
            ) : (
              <AlertInfo message="Aucune donnée trouvée" />
            ))}

          <div className="col-md-12">
            <div className="custom-pagination-container">
              <CustomPagination
                nbPages={data?.count}
                page={page}
                onChange={(page, perPage) => {
                  setLimit(perPage);
                  setPage(page);
                }}
                perPage={limit}
                showPerPage={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VouchersAdmin;
