import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import { MdOutlineAddBox } from "react-icons/md";
// import AddProjectModal from "./Modals/AddProjectModal";
// import ProjectsAdminTable from './Tables/ProjectsAdminTable';
import ProjectsTab from "./tabs/ProjectsTab";
import CategoriesTab from "./tabs/CategoriesTab";
import { useStepType } from "../../../utils/type";
import { useStep } from "react-hooks-helper";
import AddOrEditCategoryModal from "./Modals/AddOrEditCategoryModal";
import { NavLink } from "react-router-dom";

const tabs = ["Projets", "Catégories"];
const steps = [
  { id: "all-products", Component: ProjectsTab },
  { id: "all-categories", Component: CategoriesTab },
];
function ProjetsAdmin() {
  const {
    index,
    navigation: { go },
    step,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [show, setShow] = React.useState(false);
 
  return (
    <WrapperComponent>
      <div className="container-title-dash d-flex align-items-center gap-4">
        <div className="custom-sm-top">
          {/* <h2 className="custom-sm-top-title mb-0">Projets</h2> */}
          <h2 className="custom-sm-top-title mb-0">
            <ul className="tabs_container d-flex">
              {tabs.map((tab, key) => (
                <li
                  className={`
                    tab_item position-relative big__size
                    ${index === key ? "tab_item__active" : ""} 
                  `}
                  onClick={() => go(key)}
                  key={key}
                  role="button"
                >
                  {tab}
                </li>
              ))}
            </ul>
          </h2>
          {index === 0 && (
            <>
              <NavLink
                className="btn custom-add-item-btn"
                to={`/admin/projets/ajouter`}
              >
                <span className="add-icon pe-2">
                  <MdOutlineAddBox />
                </span>
                <span>Ajouter un projet</span>
              </NavLink>
            </>
          )}

          {index === 1 && (
            <>
              <button
                className="btn custom-add-item-btn"
                onClick={() => setShow(true)}
              >
                <span className="add-icon pe-2">
                  <MdOutlineAddBox />
                </span>
                <span>Ajouter une catégorie</span>
              </button>
              <AddOrEditCategoryModal show={show} setShow={setShow} />
            </>
          )}
        </div>
      </div>

      <div className="pt-5 products_and_categories_steps__wrapper">
        <Component />
      </div>
    </WrapperComponent>
  );
}

export default ProjetsAdmin;
