/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { BtnAnnuler, BtnCloseModalV2, BtnSubmit } from "../../../common/Button";
import { FormError, Input } from "../../../common/Input";
import PhoneInput from "react-phone-input-2";
import { IUser } from "../../../../utils/api/user/user.type";
import UseCrudAdminForm from "../form/UseCrudAdminForm";

function AddOrUpdateAdminModal({
  modalId,
  item,
}: {
  modalId: string;
  item?: IUser;
}) {
  const {
    register,
    errors,
    onSubmit,
    phone,
    handleChangePhone,
    handleClose,
    isLoading,
  } = UseCrudAdminForm(modalId, item);
  return (
    <div
      className="modal fade"
      id={modalId}
      aria-labelledby={`"${modalId}Label`}
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header custom-modal-header px-2 py-1">
            <h5 className="modal-title" id="AddTagModalLabel">
              {!item ? "Ajouter" : "Modifier"} un administrateur
            </h5>
            <div className="workcares-btn-close-modal-container">
              <BtnCloseModalV2 onClick={() => handleClose()} />
            </div>
          </div>
          <div className="modal-body">
            <div className="container-form">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <Input
                      type="text"
                      label="Nom"
                      id="prenom"
                      placeholder="Nom"
                      required
                      {...register("nom")}
                      error={errors?.nom}
                    />
                  </div>
                  <div className="col-md-12">
                    <Input
                      type="text"
                      label="Prénom"
                      id="prenom"
                      placeholder="Prénom"
                      required
                      {...register("prenom")}
                      error={errors?.prenom}
                    />
                  </div>
                  <div className="col-md-12">
                    <Input
                      type="email"
                      label="Adresse mail"
                      id="email"
                      placeholder="Email"
                      required
                      {...register("email")}
                      error={errors?.email}
                    />
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3 text-start">
                      <label
                        htmlFor="phone"
                        className="form-label form-label-modal-custom"
                        aria-labelledby="phone"
                      >
                        Numéro de téléphone
                        <span className="text-danger" style={{ fontSize: 24 }}>
                          *
                        </span>
                      </label>
                      <PhoneInput
                        defaultMask={".. ... .. .."}
                        containerClass="form-control form-control-modal-custom mb-3 p-0"
                        inputClass="form-control form-control-modal-custom-input-tel custom-padding"
                        country={"sn"}
                        placeholder=""
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        countryCodeEditable={false}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        onChange={(phone, country: any) => {
                          handleChangePhone(phone, country);
                        }}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "10px",
                          borderRadius: "50px",
                          paddingLeft: "40px",
                          height: "40px",
                          border: "none",
                        }}
                      />
                      <FormError error={errors?.phone?.message} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3 flex-r gap-3">
                      <BtnAnnuler
                        label={"Annuler"}
                        onClick={() => handleClose()}
                      />
                      <BtnSubmit label="Enregistrer" isLoading={isLoading} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrUpdateAdminModal;
