import React from "react";
import AddOrEditCGUModal from "../Modals/AddOrEditCGUModal";
import { useGetCGUQuery } from "../../../../utils/api/condition/condition.api";
import { AlertInfo } from "../../../common/Alert";
import { createMarkup, showModal } from "../../../../utils/Utils";
import { Condition } from "../../../../utils/api/condition/condition.type";
import Skeleton from "react-loading-skeleton";

export const ConditionSkeleton = () => {
  return (
    <>
      <Skeleton count={100} />
    </>
  );
};

export const BtnAction = ({ item, type }: { item?: Condition; type: Condition["type"] }) => {
  return (
    <>
      <button
        className="btn admin-masoul-btn-edit"
        onClick={() => showModal("addOrEditModalCGU")}
      >
        {" "}
        {item ? "Modifier" : "Ajouter"}{" "}
      </button>
      <AddOrEditCGUModal modalId="addOrEditModalCGU" item={item} type={type} />
    </>
  );
};

function ConditionsUtilisation() {
  const { data = [], isLoading } = useGetCGUQuery();
  return (
    <div className="masoul-parametre-admin-cgu">
      {!isLoading && (
        <div className="d-flex justify-content-end mb-3">
          <BtnAction item={data[0]} type="CGU" />
        </div>
      )}
      {!!isLoading && <ConditionSkeleton />}
      {!isLoading &&
        (!data?.length ? (
          <div>
            <AlertInfo message="Aucun contenu trouvé" />
          </div>
        ) : (
          <>
            <p
              className="mb-3"
              dangerouslySetInnerHTML={createMarkup(data[0]?.text)}
            />
            <p
              dangerouslySetInnerHTML={createMarkup(data[0]?.text_arb || "")}
              style={{ direction: "rtl" }}
              className="mb-3"
            />
            <p
              className="mb-3"
              dangerouslySetInnerHTML={createMarkup(data[0]?.text_fr || "")}
            />
          </>
        ))}

      {!isLoading && data?.length ? (
        <div className="d-flex justify-content-end py-5">
          <BtnAction item={data[0]} type="CGU" />
        </div>
      ) : null}
    </div>
  );
}

export default ConditionsUtilisation;


