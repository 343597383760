/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import CommandDetailsProductTable from "../../../Tables/CommandDetailsProductTable";
import WrapperComponent from "../../../../shared/WrapperComponent";
import { Order } from "../../../../../utils/api/order/order.type";
import moment from "moment";
import { formatCurrency, getDeliveryMode, getName } from "../../../../../utils/Utils";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { IUser } from "../../../../../utils/api/user/user.type";
import { isSeller } from "../../../../../routes/routerUtils";

function UserCommandDetails({
  order,
  go,
  item,
}: {
  order: Order;
  go: any;
  item: IUser;
}) {
  let number = order?.cart?.items?.length;
  return (
    <div className="commands_details__container">
      <div className="mb-3 user__command_details__container">
        <div className="p-3 pb-1 custom_command_details__header">
          <div className="row header_row justify-content-between">
            <div className="col-md-8 mb-3 custom_wrapper">
              <div className="d-flex align-items-center gap-3">
                <button
                  className={`btn-back d-flex align-items-center gap-0`}
                  onClick={() => go(0)}
                >
                  <span>
                    <MdKeyboardArrowLeft size={25} />
                  </span>
                  Retour
                </button>
                <h2 className="m-0 command_number"> {order?.code_commande}</h2>
              </div>

              <div className="pt-4 d-flex align-items-center gap-4 command_number_and_delivery_date__infos">
                <p className="m-0 product_number__infos">
                  {number} produit{number > 1 ? "s" : ""} - Commandé
                  {number > 1 ? "s" : ""} le{" "}
                  {moment(order?.created_at).format("DD MMMM YYYY à HH:mm")}
                </p>
              </div>
            </div>
            <div className="col-md-4 mb-3 custom_wrapper price_about">
              <p className="m-0 pt-3 command_total__price">
                {formatCurrency(order?.total) +
                  " " +
                  "(-" +
                  order?.total_points +
                  "pts)"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <WrapperComponent>
        {!isSeller(item) &&
          order?.parents?.map((el) => (
            <CommandDetailsProductTable
              data={el?.produits}
              item={el}
              key={el?.slug}
            />
          ))}
        {!!isSeller(item) && (
          <CommandDetailsProductTable data={order?.cart?.items} item={order} />
        )}
      </WrapperComponent>
      <WrapperComponent>
        <div className="row">
          <div className="col-md-6">
            <div className="pb-4 buyer__content">
              <p className="m-0 pb-1 sm_title">Acheteur</p>
              <p className="m-0 sm_value">{getName(order?.user)}</p>
            </div>
            <div className="pb-4 payment_mode__content">
              <p className="m-0 pb-1 sm_title">Mode de livraison</p>
              <p className="m-0 sm_value">{getDeliveryMode(order)}</p>
            </div>
            <div className="pb-4 phone__content">
              <p className="m-0 pb-1 sm_title">Téléphone</p>
              <p className="m-0 sm_value">{order?.user?.phone}</p>
            </div>
            <div className="address__content">
              <p className="m-0 pb-1 sm_title">Adresse</p>
              <p className="m-0 sm_value">{order?.user?.adress}</p>
            </div>
          </div>
          <div className="col-md-6 flex-r">
            <div className="amount-container">
              <div className="pb-4 buyer__content d-flex gap-5 align-items-center">
                <p className="m-0 pb-1 sm_value">Sous total</p>
                <p className="m-0 sm_value">
                  {formatCurrency(order?.total) +
                    " " +
                    "(-" +
                    order?.total_points +
                    "pts)"}
                </p>
              </div>

              <div className="pb-4 phone__content d-flex gap-5 align-items-center">
                <p className="m-0 pb-1 sm_title">Livraison</p>
                <p className="m-0 sm_value">{formatCurrency(0)}</p>
              </div>
              <div className="address__content d-flex gap-5 align-items-center">
                <p className="m-0 pb-1 sm_title">Total</p>
                <p className="m-0 sm_title">
                  {formatCurrency(order?.total) +
                    " " +
                    "(-" +
                    order?.total_points +
                    "pts)"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </WrapperComponent>
    </div>
  );
}

export default UserCommandDetails;
