import React from "react";
import WrapperComponent from "../../shared/WrapperComponent";
import GoBackWrapper from "../../shared/GoBackWrapper";
import "./ProjectDetail.css";
import ReactPlayer from "react-player";
import PlayImg from "../../../assets/icons/play-button.png";
import { useProjectFromLocation } from "../../../utils/api/project/project.api";
import { createMarkup, formatCurrency, getImage } from "../../../utils/Utils";

function DetailProjectAdmin() {
  const [item] = useProjectFromLocation();
  return (
    <>
      <GoBackWrapper link="/admin/projets" />
      <WrapperComponent>
        <div className="project_image_visual__container">
          <div className="mb-3 custom_wrapper colgate__wrapper">
            <div className="project_image__wrapper">
              <img
                src={getImage(item?.file)}
                alt={item?.nom}
                className="project-detail-image-wrapper"
              />
            </div>
          </div>
          <div className="card_content product_name">
            <p className="m-0 pb-3 sm_title">{item?.nom}</p>
            {/* <p className="m-0 pb-3 sm_title" style={{ direction: "rtl" }}>
              {item?.nom_arb}
            </p> */}
            {!!item?.nom_arb && (
              <p
                className="m-0 pb-3 sm_title mb-1"
                style={{ direction: "rtl" }}
              >
                <span className="fw-bold">En arabe:</span> {item?.nom_arb}
              </p>
            )}
            {!!item?.nom_fr && (
              <p className="m-0 pb-3 sm_title mb-1">
                <span className="fw-bold">En français:</span> {item?.nom_fr}
              </p>
            )}
          </div>
        </div>
      </WrapperComponent>
      <div className="row product_name_and_category__row">
        <div className="col-md-8 custom_wrapper_project d-flex mb-3">
          <div className="content-graph-admin">
            <div className="card_content product_name content-text-description">
              <p className="m-0 pb-3 sm_title">Description</p>
              <p
                className="m-0 sm_value text-description-ellipsis mb-3"
                dangerouslySetInnerHTML={createMarkup(item?.description)}
              />
              <p
                className="m-0 sm_value text-description-ellipsis mb-3"
                dangerouslySetInnerHTML={createMarkup(
                  item?.description_arb || ""
                )}
                style={{ direction: "rtl" }}
              />
              <p
                className="m-0 sm_value text-description-ellipsis"
                dangerouslySetInnerHTML={createMarkup(
                  item?.description_fr || ""
                )}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4 custom_wrapper_project d-flex mb-3">
          <div className="content-category-project-detail">
            <div className="project_category mb-3">
              <p className="m-0 pb-3 sm_title">Montant</p>
              <p className="m-0 sm_value">{formatCurrency(item?.montant)}</p>
            </div>
            <div className="project_category mb-3">
              <p className="m-0 pb-3 sm_title">Catégorie</p>
              <div className="d-flex justify-content-between">
                <p className="m-0 sm_value">{item?.categorie?.nom}</p>
                {/* <p className="m-0 sm_value" style={{ direction: "rtl" }}>
                {item?.categorie?.nom_arb}
              </p> */}
                <div>
                  {!!item?.categorie?.nom_arb && (
                    <p
                      className="m-0 sm_value mb-1"
                      style={{ direction: "rtl" }}
                    >
                      <span className="fw-normal">En arabe:</span>{" "}
                      {item?.categorie?.nom_arb}
                    </p>
                  )}
                  {!!item?.categorie?.nom_fr && (
                    <p className="m-0 sm_value mb-1">
                      <span className="fw-normal">En français:</span>{" "}
                      {item?.categorie?.nom_fr}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row project_features__row">
        <div className="col-md-12 custom_wrapper">
          {!!item?.video && (
            <WrapperComponent>
              <div className="card_content product_features">
                <p className="m-0 pb-3 sm_title">Vidéo</p>
                <div className="content-video-project-detail pt-3">
                  <ReactPlayer
                    url={item?.video}
                    width="100%"
                    height="80vh"
                    playing
                    playIcon={<img src={PlayImg} />}
                    light={getImage(item?.file)}
                    controls
                    className="project-detail-video-player"
                  />
                </div>
              </div>
            </WrapperComponent>
          )}
        </div>
      </div>
    </>
  );
}

export default DetailProjectAdmin;
