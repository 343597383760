import React from "react";
import WrapperComponent from "../../../shared/WrapperComponent";
import GoBackWrapper from "../../../shared/GoBackWrapper";
import "../ListUsers.css";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import PersonalInformation from "./tabs/PersonalInformation";
import UserCommands from "./tabs/UserCommands";
import { getAvatar, getName } from "../../../../utils/Utils";
import { useUserFromLocation } from "../../../../utils/api/user/user.api";

const tabs = ["Informations personnelles", "Commandes"];
const steps = [
  { id: "personal-infos", Component: PersonalInformation },
  { id: "commands", Component: UserCommands },
];

function UserDetails() {
  const {
    index,
    navigation: { go },
    step,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [item] = useUserFromLocation();
  const props = {
    go,
    item,
  };

  return (
    <>
      <GoBackWrapper link="/admin/utilisateurs" />
      <WrapperComponent>
        <div className="card_content">
          <div className="profil__grid">
            <div className="user_profil">
              <div className="photo__wrapper">
                <img src={getAvatar(item?.avatar)} alt={getName(item)} />
              </div>
            </div>
            <div className="user_name_and_points__wrapper">
              <p className="m-0 pb-2 user_name">{getName(item)}</p>
              <p className="m-0 user_points">({item?.points} points)</p>
            </div>
          </div>
          <div className="users-steps pt-4">
            <ul className="d-flex align-items-center tabs_container">
              {tabs.map((tab, key) => (
                <li
                  role="button"
                  className={`tab_item position-relative ${index === key ? "tab_item__active" : ""}`}
                  onClick={() => go(key)}
                >
                  {tab}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </WrapperComponent>
      {index === 0 && (
        <WrapperComponent>
          <div className="card_content pb-4">
            <Component {...props} />
          </div>
        </WrapperComponent>
      )}
      {index === 1 && (
        <div className="card_content pb-4">
          <Component {...props} />
        </div>
      )}
    </>
  );
}

export default UserDetails;
